<template>
<modal-factory />
  <div>
  
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
    
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item"><router-link to="/produtores"  tag="primary">Produtores</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
              </ol>
            </nav>

          <form @submit.prevent="handleSubmit">  
            <div>
              <h2 class="mb-3">Novo Produtor</h2>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Produtor
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Código</label>
                          <input type="text" class="form-control" v-model="state.codigo.value" readonly />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Nome </label>
                          <input type="text" class="form-control" v-model="state.nome_pessoa.value" />
                          <span v-if="!!state.nome_pessoa.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.nome_pessoa.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Telefone Whats</label>
                          <input type="text" class="form-control" v-model="state.telefone_whats.value" />
                          <span v-if="!!state.telefone_whats.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.telefone_whats.errorMessage }}
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Telefone Fixo</label>
                          <input type="text" class="form-control" v-model="state.telefone_fixo.value" />
                          <span v-if="!!state.telefone_fixo.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.telefone_fixo.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Fazenda
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Nome </label>
                          <input type="text" class="form-control" v-model="state.nome_fazenda.value" />
                          <span v-if="!!state.nome_fazenda.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.nome_fazenda.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Endereço</label>
                          <input type="text" class="form-control" v-model="state.endereco.value" />
                          <span v-if="!!state.endereco.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.endereco.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Complemento </label>
                          <input type="text" class="form-control" v-model="state.complemento.value" />
                          <span v-if="!!state.complemento.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.complemento.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary my-4" id="submit-button" :disabled="state.isLoading" type="submit">Save</button>
            <button class="btn btn-warning my-4" id="submit-button" type="button" @click="cancel()">Cancel</button>
          </form>
          </stats-card>
        </div>
        
      </div>
    </div>
</div>

</template>

<script>
import { onMounted, reactive } from 'vue'
import services from '../services'
import { useField } from "vee-validate";
import { validateEmptyAndLength3, validateEmpty } from '../utils/validators'
import useModal from '../hooks/useModal'
import ModalFactory from '../components/ModalFactory'
import { useRouter } from 'vue-router'

export default {
  
  components: { ModalFactory },

  setup (_, { emit }) {
   
    const router = useRouter()
    const modal = useModal()

    var id_produtor = router.currentRoute._value.params.id
    if(id_produtor === undefined){
      id_produtor = 0;
    }
    function handleCategory(){
      modal.open({
        component: 'ModalCategory'
      })
    }

    function cancel(){
      router.push({ name: 'products' })
    }
    const {
      value: codigoValue,
      errorMessage: codigoErrorMessage
    } = useField('codigo', validateEmptyAndLength3)

    const {
      value: nomePessoaValue,
      errorMessage: nomePessoaErrorMessage
    } = useField('nome_pessoa', validateEmptyAndLength3)

    const { 
      value: telefoneWhatsValue,
      errorMessage: telefoneWhatsErrorMessage
    } = useField('telefone_whats', validateEmpty)

    const { 
      value: telefoneFixoValue,
      errorMessage: telefoneFixoErrorMessage
    } = useField('telefone_fixo', validateEmpty)

    const { 
      value: nomeFazendaValue,
      errorMessage: nomeFazendaErrorMessage
    } = useField('nome_fazenda', validateEmpty)

    const { 
      value: enderecoValue,
      errorMessage: enderecoErrorMessage
    } = useField('endereco', validateEmpty)

    const {
      value: complementoValue,
      errorMessage: complementoErrorMessage
    } = useField('complemento', validateEmpty)


    const state = reactive({
      hasErrors: false,
      isLoading: false,
      codigo: {
        value: codigoValue,
        errorMessage: codigoErrorMessage
      },
      nome_pessoa: {
        value: nomePessoaValue,
        errorMessage: nomePessoaErrorMessage
      },
      telefone_whats: {
        value: telefoneWhatsValue,
        errorMessage: telefoneWhatsErrorMessage
      },
      telefone_fixo: {
        value: telefoneFixoValue,
        errorMessage: telefoneFixoErrorMessage
      },

      nome_fazenda: {
        value: nomeFazendaValue,
        errorMessage: nomeFazendaErrorMessage
      },

      endereco: {
        value: enderecoValue,
        errorMessage: enderecoErrorMessage
      },

      complemento: {
        value: complementoValue,
        errorMessage: complementoErrorMessage
      }
    })

      async function fetchProdutor() {
        try {
          state.isLoading = true
          const { data } = await services.produtor.getById({
            id : id_produtor
          }
          )
          state.codigo.value = data.id
          state.nome_pessoa.value = data.nome_pessoa
          state.telefone_whats.value = data.telefone_whats
          state.telefone_fixo.value = data.telefone_fixo
          state.telefone_whats.value = data.telefone_whats
          state.telefone_fixo.value = data.telefone_fixo     
          state.nome_fazenda.value = data.nome_fazenda
          state.endereco.value = data.endereco
          state.complemento.value = data.complemento
          state.isLoading = false
          
        } catch (error) {
          handleErrors(error)
        }
      }

      function handleErrors (error) {
        console.log(error)
        state.hasError = !!error
      }
      onMounted(() => {
        if(id_produtor != 0){
          fetchProdutor()
        }
        
      })
    async function handleSubmit() {

      try {
        state.isLoading = true
        if(state.codigo.value){
          const { errors } = await services.produtor.update({
            id: state.codigo.value,
            nome_pessoa: state.nome_pessoa.value,
            telefone_whats: state.telefone_whats.value,
            telefone_fixo: state.telefone_fixo.value,
            nome_fazenda: state.nome_fazenda.value,
            endereco: state.endereco.value,
            complemento: state.complemento.value
          })
          if (!errors) {
            router.push({ name: 'produtores' })
            state.isLoading = false
            return
          }
        }else{
           const { errors }= await services.produtor.save({
            nome_pessoa: state.nome_pessoa.value,
            telefone_whats: state.telefone_whats.value,
            telefone_fixo: state.telefone_fixo.value,
            nome_fazenda: state.nome_fazenda.value,
            endereco: state.endereco.value,
            complemento: state.complemento.value
          })
          if (!errors) {
            router.push({ name: 'produtores' })
            state.isLoading = false
            return
          } 
        }
//        if (errors.status === 400) {
        //  toast.error('Ocorreu um erro ao criar a conta')
  //      }
        state.isLoading = false

      } catch (error) {
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      //  toast.error('Ocorreu um erro ao criar a conta')
      }




    }
  return {
    state,
    handleCategory,
    handleSubmit,
    emit,
    cancel
  }
  }
}





</script>

<style scoped>
  textarea {
    resize: none;
  }
</style>