export default httpClient => ({
    getAll: async () => {

      const response = await httpClient.get('/quimicos')
      
      return { data: response.data }
  
    },

    getInseticidas: async () => {

      const response = await httpClient.get('/inseticidas')
      
      return { data: response.data }
  
    },

    getFungicidas: async () => {

      const response = await httpClient.get('/fungicidas')
      
      return { data: response.data }
  
    },
    getById: async ({ id }) => {
      const response = await httpClient.get('/quimico/'+id,{ 
      })
      return { data: response.data }
    },
    save: async ({ nome, ingrediente_ativo_1, ingrediente_ativo_2, ingrediente_ativo_3, categoria, descricao }) => {
      const response = await httpClient.post('/quimico/adicionar',{
        nome,
        ingrediente_ativo_1,
        ingrediente_ativo_2,
        ingrediente_ativo_3,
        categoria,
        descricao
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    update: async ({ id, nome, ingrediente_ativo_1, ingrediente_ativo_2, ingrediente_ativo_3,categoria, descricao }) => {
      const response = await httpClient.post('/quimico/alterar/'+id,{
        nome : nome,
        ingrediente_ativo_1,
        ingrediente_ativo_2,
        ingrediente_ativo_3,
        categoria,
        descricao
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    delete: async ({ id }) => {
      const response = await httpClient.post('/quimico/deletar',{
        id
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    }
})