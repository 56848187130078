<template>
    <tr @click="handleToggle">
        <th scope="row">
            <div class="media align-items-center">
                <div class="media-body">
                <span class="name mb-0 text-sm"></span>
                {{ engenheiro.id }}
                </div>
            </div>
        </th>
         <td>
            {{ engenheiro.nome }}
        </td>
        <td>
            {{ engenheiro.email }}
        </td>
        <td>
            {{ engenheiro.telefone }}
        </td>
        <td class="text-right">
            <div>
                <router-link :to="{ name: 'AlterarEngenheiro', params: { id: engenheiro.id } }">
                    <button class="btn btn-primary" >Ver / Alterar</button>
                </router-link>
                <button class="btn btn-danger" @click="$emit('deletarEngenheiro',engenheiro.id)">Deletar</button>
            </div>
        </td>
    </tr>
</template>

<script>
import { reactive } from 'vue'

export default {
    props: {
        isOpened: { type: Boolean, default: false },
        engenheiro: { type: Object, required: true }
    },
    setup(_, { emit }) {
        const state = reactive({
           // isOpen: props.isOpened,
           // isClosing: !props.isOpened
        })

        async function handleToggle () {
        state.isClosing = true

      //  await wait(250)
        state.isOpen = !state.isOpen
        state.isClosing = false
        
      }
      return {
        state,
        handleToggle,
        emit
      }
    },
    emits:['deletarEngenheiro'],
}
</script>
