import { createRouter, createWebHashHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";

//import Home from "../views/Home.vue";
import Produtores from "../views/Produtores.vue";
import Engenheiros from "../views/Engenheiros.vue";/*
import Pragas from "../views/Pragas.vue";
import Doencas from "../views/Doencas.vue";*/
import Quimicos from "../views/Quimicos.vue";
import RelatoriosCampo from "../views/RelatoriosCampo.vue";
import RelatoriosPlantio from "../views/RelatoriosPlantio.vue";
import RelatoriosGerminacao from "../views/RelatoriosGerminacao.vue";

import AdicionarProdutor from "../views/AdicionarProdutor.vue";
import AdicionarRelatorioGerminacao from "../views/AdicionarRelatorioGerminacao.vue";
import AdicionarEngenheiro from "../views/AdicionarEngenheiro.vue";

import AdicionarRelatorioCampo from "../views/AdicionarRelatorioCampo.vue";
import AdicionarRelatorioPlantio from "../views/AdicionarRelatorioPlantio.vue";
import AvaliacaoMilho from "../views/AvaliacaoMilho.vue";
import AdicionarAvaliacaoMilho from "../views/AdicionarAvaliacaoMilho.vue";
/*
import AdicionarCultivar from "../views/AdicionarCultivar.vue";
import AdicionarPraga from "../views/AdicionarPraga.vue";
import AdicionarDoenca from "../views/AdicionarDoenca.vue";*/
import AdicionarQuimico from "../views/AdicionarQuimico.vue";

//import Icons from "../views/Icons.vue";
import Profile from "../views/UserProfile.vue";
//import Tables from "../views/Tables.vue";

import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

import Guard from "../services/middleware.js"

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
        beforeEnter: Guard.auth
      },
      {
        path: "/",
        name: "home",
        components: { default: Dashboard },
        beforeEnter: Guard.auth
      },
      {
        path: "/relatorios-campo",
        name: "RelatoriosCampo",
        components: { default: RelatoriosCampo },
        beforeEnter: Guard.auth
      },

      {
        path: "/relatorios-plantio",
        name: "RelatoriosPlantio",
        components: { default: RelatoriosPlantio },
        beforeEnter: Guard.auth
      },

      {
        path: "/relatorios-germinacao",
        name: "RelatoriosGerminacao",
        components: { default: RelatoriosGerminacao },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-relatorio-germinacao",
        name: "AdicionarRelagorioGerminacao",
        components: { default: AdicionarRelatorioGerminacao },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-relatorio-plantio",
        name: "AdicionarRelatorioPlantio",
        components: { default: AdicionarRelatorioPlantio },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-relatorio-campo",
        name: "AdicionarRelatorioCampo",
        components: { default: AdicionarRelatorioCampo },
        beforeEnter: Guard.auth
      },
      {
        path: "/avaliacao-milho",
        name: "AvaliacaoMilho",
        components: { default: AvaliacaoMilho },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-avaliacao-milho",
        name: "AdicionarAvaliacaoMilho",
        components: { default: AdicionarAvaliacaoMilho },
        beforeEnter: Guard.auth
      },

      {
        path: "/produtores",
        name: "produtores",
        components: { default: Produtores },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-produtor",
        name: "AdicionarProdutor",
        components: { default: AdicionarProdutor },
        beforeEnter: Guard.auth
      },

      {
        path: '/produtores/alterar/:id',
        name: 'AlterarProdutor',
        components: { default: AdicionarProdutor },
        beforeEnter: Guard.auth
      },

      {
        path: "/engenheiros",
        name: "engenheiros",
        components: { default: Engenheiros },
        beforeEnter: Guard.auth
      },
      {
        path: "/adicionar-engenheiro",
        name: "AdicionarEngenheiro",
        components: { default: AdicionarEngenheiro },
        beforeEnter: Guard.auth
      },

      {
        path: '/engenheiro/alterar/:id',
        name: 'AlterarEngenheiro',
        components: { default: AdicionarEngenheiro },
        beforeEnter: Guard.auth
      },

/*
      {
        path: "/adicionar-doenca",
        name: "AdicionarDoenca",
        components: { default: AdicionarDoenca },
      },

      {
        path: "/cultivares",
        name: "cultivares",
        components: { default: Cultivares },
      },

      {
        path: "/adicionar-cultivar",
        name: "AdicionarCultivar",
        components: { default: AdicionarCultivar },
      },

      {
        path: '/cultivares/alterar/:id',
        name: 'AlterarCultivar',
        components: { default: AdicionarCultivar },
      },

      {
        path: "/pragas",
        name: "pragas",
        components: { default: Pragas },
      },

      {
        path: "/adicionar-praga",
        name: "AdicionarPraga",
        components: { default: AdicionarPraga },
      },

      {
        path: '/pragas/alterar/:id',
        name: 'AlterarPraga',
        components: { default: AdicionarPraga },
      },
      {
        path: "/doencas",
        name: "doencas",
        components: { default: Doencas },
      },

      {
        path: '/doencas/alterar/:id',
        name: 'AlterarDoenca',
        components: { default: AdicionarDoenca },
      },
*/
      {
        path: "/quimicos",
        name: "quimicos",
        components: { default: Quimicos },
        beforeEnter: Guard.auth
      },

      {
        path: '/quimicos/alterar/:id',
        name: 'AlterarQuimico',
        components: { default: AdicionarQuimico },
        beforeEnter: Guard.auth
      },

      {
        path: "/adicionar-quimico",
        name: "AdicionarQuimico",
        components: { default: AdicionarQuimico },
        beforeEnter: Guard.auth
      },
      
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
