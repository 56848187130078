<template>
  <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>
    <div class="container-fluid mt--7">
    
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item"><router-link to="/relatorios-plantio"  tag="primary">Relatorios Plantio</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
              </ol>
            </nav>

          <form @submit.prevent="handleSubmit">  
            <div>
              <h2 class="mb-3">Novo Relatório Plantio</h2>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Informações Principais
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      > 
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Tipo Lavoura</label>
                          <select class="form-control" v-model="state.tipo_lavoura">
                            <option value="MILHO">Milho</option>
                            <option value="SOJA">Soja</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Ano Safra</label>
                          <input type="text" class="form-control" v-model="state.ano_safra.value"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Produtor</label>
                          <select class="form-control" v-model="state.produtor.value">
                            <select-produtores
                              v-for="produtor in state.produtores"
                              :key="produtor.id"
                              :produtor="produtor"
                            />
                          </select>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>

                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Dados Gerais
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      > 
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label>Espaçamento</label>
                          <input type="text" class="form-control" v-model="state.espacamento.value"/>
                        </div>
                      </div>
                    </div>
                    <h2>Trat. Semente</h2>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Quantidade de Produtos</label>
                          <select class="form-control" v-model="state.qtd_produto">
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select>
                          
                          <div v-for="n_qtd in parseInt(state.qtd_produto)" :key="n_qtd">
                            <div class="row">
                              <div class="col-sm-6">
                                <label :for="'produto_'+n_qtd">Produto {{n_qtd}}</label>
                                <input :id="'produto_'+n_qtd" type="text" class="form-control" @change="AdicionarProduto($event,n_qtd,'nome_produto')">
                              </div>
                              <div class="col-sm-6">
                                <label :for="'doses_'+n_qtd">Doses</label>
                                <input :id="'doses_'+n_qtd" type="text" class="form-control" @change="AdicionarProduto($event,n_qtd,'dose')">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>  

                    <h2>Adubação</h2>
                    <label>Adubação Plantio</label>
                    <div class="row">
                      <div class="col-md-4">
                        <label for="formula_adubacao_plantio">Fórmula {{n_qtd}}</label>
                        <input id="formula_adubacao_plantio" type="text" class="form-control" v-model="state.formula_adubacao_plantio" >
                      </div>
                      <div class="col-sm-4">
                        <label for="data_adubacao_plantio">Data</label>
                        <input id="data_adubacao_plantio" type="text" class="form-control" v-model="state.data_adubacao_plantio">
                      </div>
                      <div class="col-sm-4">
                        <label for="qtd_adubacao_plantio">Quantidade</label>
                        <input id="qtd_adubacao_plantio" type="text" class="form-control" v-model="state.qtd_adubacao_plantio">
                      </div> 
                    </div>

                    <label>Adubação Cobertura 1</label>
                    <div class="row">
                      <div class="col-md-4">
                        <label for="">Fórmula {{n_qtd}}</label>
                        <input id="" type="text" class="form-control" v-model="state.formula_adubacao_cobertura_1">
                      </div>
                      <div class="col-sm-4">
                        <label :for="'doses_'+n_qtd" >Data</label>
                        <input :id="'doses_'+n_qtd" type="text" class="form-control" v-model="state.data_adubacao_cobertura_1">
                      </div>
                      <div class="col-sm-4">
                        <label :for="'doses_'+n_qtd" >Quantidade</label>
                        <input :id="'doses_'+n_qtd" type="text" class="form-control" v-model="state.qtd_adubacao_cobertura_1">
                      </div> 
                    </div>

                    <label>Adubação Cobertura 2</label>
                    <div class="row">
                      <div class="col-md-4">
                        <label for="">Fórmula {{n_qtd}}</label>
                        <input id="" type="text" class="form-control" v-model="state.formula_adubacao_cobertura_2">
                      </div>
                      <div class="col-sm-4">
                        <label :for="'doses_'+n_qtd" >Data</label>
                        <input :id="'doses_'+n_qtd" type="text" class="form-control" v-model="state.data_adubacao_cobertura_2">
                      </div>
                      <div class="col-sm-4">
                        <label :for="'doses_'+n_qtd" >Quantidade</label>
                        <input :id="'doses_'+n_qtd" type="text" class="form-control" v-model="state.qtd_adubacao_cobertura_2">
                      </div> 
                    </div>
                    <div class="row" style="margin-bottom:20px">
                      <div class="col-sm-12">
                        <label for="observacao">Observação</label>
                        <textarea id="observacao" class="form-control" v-model="state.observacao" >
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Plantio 
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      > 
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Quantidade de <span v-if="state.tipo_lavoura === 'SOJA'">Cultivar</span > <span v-else>Híbridos</span ></label>
                          <select class="form-control" v-model="state.qtd_hibridos">
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div v-for="n_qtd in parseInt(state.qtd_hibridos)" :key="n_qtd">
                      <h3><span v-if="state.tipo_lavoura === 'SOJA'">Cultivar</span > <span v-else>Híbridos</span > {{n_qtd}}</h3>
                      <div class="row">
                        <div class="col-sm-4">
                          <label :for="'produto_'+n_qtd"><span v-if="state.tipo_lavoura === 'SOJA'">Cultivar</span > <span v-else>Híbridos</span ></label>
                          <input :id="'produto_'+n_qtd" type="text" class="form-control"  @blur="AdicionarHibrido($event,n_qtd,'nome_hibrido')" >
                        </div>
                        <div class="col-sm-4">
                          <label :for="'doses_'+n_qtd" >Qtd STES</label>
                          <input :id="'doses_'+n_qtd" type="text" class="form-control" @blur="AdicionarHibrido($event,n_qtd,'qtd_stes')">
                        </div>                        
                        <div class="col-sm-4">
                          <label :for="'doses_'+n_qtd">Sistema</label>
                          <select class="form-control" @blur="AdicionarHibrido($event,n_qtd,'sistema')">
                            <option>Disco</option>
                            <option>Ar</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4">
                          <label :for="'produto_'+n_qtd">Área Plantio</label>
                          <input :id="'produto_'+n_qtd" type="text" class="form-control" @blur="AdicionarHibrido($event,n_qtd,'area_plantio')">
                        </div>
                        <div class="col-sm-4">
                          <label :for="'doses_'+n_qtd" >Peneira</label>
                          <input :id="'doses_'+n_qtd" type="text" class="form-control" @blur="AdicionarHibrido($event,n_qtd,'peneira')">
                        </div>                        
                        <div class="col-sm-4">
                          <label :for="'doses_'+n_qtd">Veloc. Plantio</label>
                          <input :id="'doses_'+n_qtd" type="text" class="form-control" @blur="AdicionarHibrido($event,n_qtd,'veloc_plantio')">
                        </div>
                      </div>

                      <div class="row" style="margin-bottom:20px">
                        <div class="col-sm-4">
                          <label :for="'produto_'+n_qtd">Data Plantio</label>
                          <input :id="'produto_'+n_qtd" type="text" class="form-control" @blur="AdicionarHibrido($event,n_qtd,'data_plantio')">
                        </div>
                        <div class="col-sm-4">
                          <label :for="'doses_'+n_qtd" >Plantadeira</label>
                          <input :id="'doses_'+n_qtd" type="text" class="form-control" @blur="AdicionarHibrido($event,n_qtd,'plantadeira')">
                        </div>                        
                        <div class="col-sm-4">
                          <label :for="'doses_'+n_qtd">Disco Usado</label>
                          <input :id="'doses_'+n_qtd" type="text" class="form-control" @blur="AdicionarHibrido($event,n_qtd,'disco_usado')">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary my-4" id="submit-button" :disabled="state.isSaving" type="submit">Salvar <img src="@/assets/loading.gif" style="width:20px; margin-left:10px" v-if="state.isSaving"/></button>
            <button class="btn btn-warning my-4" id="submit-button" type="button" @click="cancel()">Cancelar</button>
          </form>
          </stats-card>
        </div>
        
      </div>
    </div>
</div>

</template>

<script>
import { reactive, onMounted } from 'vue'
import services from '../services'
import { useField } from "vee-validate";
import { validateEmpty } from '../utils/validators'
import { useRouter } from 'vue-router'
import SelectProdutores from '../components/ProdutoresSelect.vue';
import Cookie from 'js-cookie'

export default { 
  
  components: { SelectProdutores },

  
  setup () {
    var engenheiro_id;
    const router = useRouter()

    function cancel(){
      router.push({ name: 'RelatoriosPlantio' })
    }

    const {
      value: produtorValue,
      errorMessage: produtorErrorMessage
    } = useField('produtor', validateEmpty)

    const {
      value: anoSafraValue,
      errorMessage: anoSafraErrorMessage
    } = useField('ano_safra', validateEmpty)

    const {
      value: espacamentoValue,
      errorMessage: espacamentoErrorMessage
    } = useField('espacamento', validateEmpty)



    const state = reactive({
      hasErrors: false,
      isLoading: false,
      isSaving: false,
      produtores: [],

      produtor: {
        value: produtorValue,
        errorMessage: produtorErrorMessage
      },
      ano_safra: {
        value: anoSafraValue,
        errorMessage: anoSafraErrorMessage
      },
      espacamento: {
        value: espacamentoValue,
        errorMessage: espacamentoErrorMessage
      },
      formula_adubacao_plantio:"",
      data_adubacao_plantio:"",
      qtd_adubacao_plantio:"",
      formula_adubacao_cobertura_1:"",
      data_adubacao_cobertura_1:"",
      qtd_adubacao_cobertura_1:"",
      formula_adubacao_cobertura_2:"",
      data_adubacao_cobertura_2:"",
      qtd_adubacao_cobertura_2:"",
      qtd_produto:0,
      tipo_lavoura:"",
      qtd_hibridos:0,

      produto_1: {
        nome_produto:'',
        dose:''
      },
      produto_2: {
        nome_produto:'',
        dose:''
      },
      produto_3: {
        nome_produto:'',
        dose:''
      },
      produto_4: {
        nome_produto:'',
        dose:''
      },

      hibrido_1: {
        nome_hibrido:'',
        qtd_stes:'',
        dose:'',
        sistema:'Disco',
        area_plantio:'',
        peneira:'',
        veloc_plantio:'',
        data_plantio:'',
        plantadeira:'',
        disco_usado:''
      },
      hibrido_2: {
        nome_hibrido:'',
        qtd_stes:'',
        dose:'',
        sistema:'Disco',
        area_plantio:'',
        peneira:'',
        veloc_plantio:'',
        data_plantio:'',
        plantadeira:'',
        disco_usado:''
      },
      hibrido_3: {
        nome_hibrido:'',
        qtd_stes:'',
        dose:'',
        sistema:'Disco',
        area_plantio:'',
        peneira:'',
        veloc_plantio:'',
        data_plantio:'',
        plantadeira:'',
        disco_usado:''
      },
      hibrido_4: {
        nome_hibrido:'',
        qtd_stes:'',
        dose:'',
        sistema:'Disco',
        area_plantio:'',
        peneira:'',
        veloc_plantio:'',
        data_plantio:'',
        plantadeira:'',
        disco_usado:''
      },
    })

    onMounted(() => {
      fetchProdutores(),
      dataAtualFormatada()
    //  definirDataAtual()
    })

    function  AdicionarProduto(event,n_produto,campo){

      console.log("Evento: "+event.target.value+" n_produto: "+n_produto+" campo: "+campo);

      if(campo == "nome_produto"){
        switch (n_produto) {
          case 1:
            state.produto_1.nome_produto = event.target.value;
            break;
          case 2:
            state.produto_2.nome_produto = event.target.value;
            break;
          case 3:
            state.produto_3.nome_produto = event.target.value;
            break;
          case 4:
            state.produto_4.nome_produto = event.target.value;
            break;
        }
      }else{
        switch (n_produto) {
          case 1:
            state.produto_1.dose = event.target.value;
            break;
          case 2:
            state.produto_2.dose = event.target.value;
            break;
          case 3:
            state.produto_3.dose = event.target.value;
            break;
          case 4:
            state.produto_4.dose = event.target.value;
            break;
        }
      }
    }

    function  AdicionarHibrido(event,n_hibrido,campo){

      console.log("Evento: "+event.target.value+" n_hibrido: "+n_hibrido+" campo: "+campo);

      if(campo == "nome_hibrido"){
        switch (n_hibrido) {
          case 1:
            state.hibrido_1.nome_hibrido = event.target.value;
            break;
          case 2:
            state.hibrido_2.nome_hibrido = event.target.value;
            break;
          case 3:
            state.hibrido_3.nome_hibrido = event.target.value;
            break;
          case 4:
            state.hibrido_4.nome_hibrido = event.target.value;
            break;
        }
      }else if(campo == "qtd_stes"){
        switch (n_hibrido) {
          case 1:
            state.hibrido_1.qtd_stes = event.target.value;
            break;
          case 2:
            state.hibrido_2.qtd_stes = event.target.value;
            break;
          case 3:
            state.hibrido_3.qtd_stes = event.target.value;
            break;
          case 4:
            state.hibrido_4.qtd_stes = event.target.value;
            break;
        }
      }else if(campo == "sistema"){
        switch (n_hibrido) {
          case 1:
            state.hibrido_1.sistema = event.target.value;
            break;
          case 2:
            state.hibrido_2.sistema = event.target.value;
            break;
          case 3:
            state.hibrido_3.sistema = event.target.value;
            break;
          case 4:
            state.hibrido_4.sistema = event.target.value;
            break;
        }
      }else if(campo == "area_plantio"){
        switch (n_hibrido) {
          case 1:
            state.hibrido_1.area_plantio = event.target.value;
            break;
          case 2:
            state.hibrido_2.area_plantio = event.target.value;
            break;
          case 3:
            state.hibrido_3.area_plantio = event.target.value;
            break;
          case 4:
            state.hibrido_4.area_plantio = event.target.value;
            break;
        }
      }else if(campo == "peneira"){
        switch (n_hibrido) {
          case 1:
            state.hibrido_1.peneira = event.target.value;
            break;
          case 2:
            state.hibrido_2.peneira = event.target.value;
            break;
          case 3:
            state.hibrido_3.peneira = event.target.value;
            break;
          case 4:
            state.hibrido_4.peneira = event.target.value;
            break;
        }
      }else if(campo == "veloc_plantio"){
        switch (n_hibrido) {
          case 1:
            state.hibrido_1.veloc_plantio = event.target.value;
            break;
          case 2:
            state.hibrido_2.veloc_plantio = event.target.value;
            break;
          case 3:
            state.hibrido_3.veloc_plantio = event.target.value;
            break;
          case 4:
            state.hibrido_4.veloc_plantio = event.target.value;
            break;
        }
      }else if(campo == "data_plantio"){
        switch (n_hibrido) {
          case 1:
            state.hibrido_1.data_plantio = event.target.value;
            break;
          case 2:
            state.hibrido_2.data_plantio = event.target.value;
            break;
          case 3:
            state.hibrido_3.data_plantio = event.target.value;
            break;
          case 4:
            state.hibrido_4.data_plantio = event.target.value;
            break;
        }
      }else if(campo == "plantadeira"){
        switch (n_hibrido) {
          case 1:
            state.hibrido_1.plantadeira = event.target.value;
            break;
          case 2:
            state.hibrido_2.plantadeira = event.target.value;
            break;
          case 3:
            state.hibrido_3.plantadeira = event.target.value;
            break;
          case 4:
            state.hibrido_4.plantadeira = event.target.value;
            break;
        }
      }else if(campo == "disco_usado"){
        switch (n_hibrido) {
          case 1:
            state.hibrido_1.disco_usado = event.target.value;
            break;
          case 2:
            state.hibrido_2.disco_usado = event.target.value;
            break;
          case 3:
            state.hibrido_3.disco_usado = event.target.value;
            break;
          case 4:
            state.hibrido_4.disco_usado = event.target.value;
            break;
        }
      }
    }



    function dataAtualFormatada(){
      engenheiro_id = Cookie.get('_engenheiro_id')

      var data = new Date(),
        dia  = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
      return diaF+"/"+mesF+"/"+anoF;
    }  


  ////  function definirDataAtual(){
  //    state.data.value = dataAtualFormatada()
   // }

    async function handleSubmit() {

      try {
        state.isSaving = true

          var retorno = await services.relatorio_plantio.save({
            engenheiro_id: engenheiro_id,
            tipo_lavoura: state.tipo_lavoura,
            ano_safra: state.ano_safra.value,
            produtor_id: state.produtor.value,
            espacamento: state.espacamento.value,
            formula_adubacao_plantio: state.formula_adubacao_plantio,
            data_adubacao_plantio: state.data_adubacao_plantio,
            qtd_adubacao_plantio: state.qtd_adubacao_plantio,
            formula_adubacao_cobertura_1: state.formula_adubacao_cobertura_1,
            data_adubacao_cobertura_1: state.data_adubacao_cobertura_1,
            qtd_adubacao_cobertura_1: state.qtd_adubacao_cobertura_1,
            formula_adubacao_cobertura_2: state.formula_adubacao_cobertura_2,
            data_adubacao_cobertura_2: state.data_adubacao_cobertura_2,
            qtd_adubacao_cobertura_2: state.qtd_adubacao_cobertura_2,
            observacao:state.observacao
          })

          //Plantas Folhas Linha 1
          var qtd_produtos = state.qtd_produto
          
          for (var i = 1; i <= qtd_produtos; i++) {

            switch (i) {
              case 1:
                var retorno_produto_1 = await services.relatorio_plantio.save_produto({
                  relatorio_plantio_id: retorno.data,
                  produto: state.produto_1.nome_produto,
                  doses: state.produto_1.dose
                })
                break;
              case 2:
                var retorno_produto_2 = await services.relatorio_plantio.save_produto({
                  relatorio_plantio_id: retorno.data,
                  produto: state.produto_2.nome_produto,
                  doses: state.produto_2.dose
                })
                break;

              case 3:
                var retorno_produto_3 = await services.relatorio_plantio.save_produto({
                  relatorio_plantio_id: retorno.data,
                  produto: state.produto_3.nome_produto,
                  doses: state.produto_3.dose
                })
                break;

              case 4:
                var retorno_produto_4 = await services.relatorio_plantio.save_produto({
                  relatorio_plantio_id: retorno.data,
                  produto: state.produto_4.nome_produto,
                  doses: state.produto_4.dose
                })
              break;

              default:
                break;
            }
          }

          for (var i1 = 1; i1 <= state.qtd_hibridos; i1++) {

            switch (i1) {
              case 1:
                console.log()
                var retorno_hibrido_1 = await services.relatorio_plantio.save_hibrido({
                  relatorio_plantio_id: retorno.data,
                  nome_hibrido: state.hibrido_1.nome_hibrido,
                  qtd_stes: state.hibrido_1.qtd_stes,
                  sistema: state.hibrido_1.sistema,
                  area_plantio: state.hibrido_1.area_plantio,
                  peneira: state.hibrido_1.peneira,
                  veloc_plantio: state.hibrido_1.veloc_plantio,
                  data_plantio: state.hibrido_1.data_plantio,
                  plantadeira: state.hibrido_1.plantadeira,
                  disco_usado: state.hibrido_1.disco_usado,
                  hibrido: state.hibrido_1.hibrido
                })
                break;
              case 2:
                var retorno_hibrido_2 = await services.relatorio_plantio.save_hibrido({
                  relatorio_plantio_id: retorno.data,
                  nome_hibrido: state.hibrido_2.nome_hibrido,
                  qtd_stes: state.hibrido_2.qtd_stes,
                  sistema: state.hibrido_2.sistema,
                  area_plantio: state.hibrido_2.area_plantio,
                  peneira: state.hibrido_2.peneira,
                  veloc_plantio: state.hibrido_2.veloc_plantio,
                  data_plantio: state.hibrido_2.data_plantio,
                  plantadeira: state.hibrido_2.plantadeira,
                  disco_usado: state.hibrido_2.disco_usado,
                  hibrido: state.hibrido_2.hibrido
                })
                break;

              case 3:
                var retorno_hibrido_3 = await services.relatorio_plantio.save_hibrido({
                  relatorio_plantio_id: retorno.data,
                  nome_hibrido: state.hibrido_3.nome_hibrido,
                  qtd_stes: state.hibrido_3.qtd_stes,
                  sistema: state.hibrido_3.sistema,
                  area_plantio: state.hibrido_3.area_plantio,
                  peneira: state.hibrido_3.peneira,
                  veloc_plantio: state.hibrido_3.veloc_plantio,
                  data_plantio: state.hibrido_3.data_plantio,
                  plantadeira: state.hibrido_3.plantadeira,
                  disco_usado: state.hibrido_3.disco_usado,
                  hibrido: state.hibrido_3.hibrido
                })
                break;

              case 4:
                var retorno_hibrido_4 = await services.relatorio_plantio.save_hibrido({
                  relatorio_plantio_id: retorno.data,
                  nome_hibrido: state.nome_hibrido,
                  qtd_stes: state.hibrido_4.qtd_stes,
                  sistema: state.hibrido_4.sistema,
                  area_plantio: state.hibrido_4.area_plantio,
                  peneira: state.hibrido_4.peneira,
                  veloc_plantio: state.hibrido_4.veloc_plantio,
                  data_plantio: state.hibrido_4.data_plantio,
                  plantadeira: state.hibrido_4.plantadeira,
                  disco_usado: state.hibrido_4.disco_usado,
                  hibrido: state.hibrido_4.hibrido
                })
              break;

              default:
                break;
            }
          }
          console.log(retorno_produto_1);
          console.log(retorno_produto_2);
          console.log(retorno_produto_3);
          console.log(retorno_produto_4);

          console.log(retorno_hibrido_1);
          console.log(retorno_hibrido_2);
          console.log(retorno_hibrido_3);
          console.log(retorno_hibrido_4);

        /*    if (!retorno_plantas_folhas_linha_1) {
              ///  
              //  state.isLoading = false
              console.log(retorno)
              return
            }*/
          router.push({ name: 'RelatoriosPlantio' })
      } catch (error) {
        console.log(error)
        state.isSaving = false
        state.hasErrors = !!error
        alert('Erro ao salvar Relatório.')
      //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }


    async function fetchProdutores () {
      try {
        state.isLoading = true
        const { data } = await services.produtor.getAll({
          type: state.currentClientType
        }
        )
        console.log(data)
        state.produtores = data
        state.isLoading = false
        
      } catch (error) { 
//        handleErrors(error)
      }
    }
    return {
      state,
      cancel,
      AdicionarProduto,
      AdicionarHibrido,
      handleSubmit
    }
  }
}





</script>

<style scoped>
  textarea {
    resize: none;
  }
</style>