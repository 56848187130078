<template>
    <tr @click="handleToggle">
        <td>
            {{ relatorio.id }}
        </td>
        <th>
          {{ relatorio.nome_produtor}}
        </th>
        <td>
          {{ relatorio.nome_fazenda}}
        </td>
        <td>
          {{ relatorio.data}}
        </td>
        <td class="text-right">
          <div>
            <PDFRelatorioGerminacao :relatorio=relatorio />
            <button class="btn btn-danger" @click="$emit('deletarRelatorio',relatorio.id)">Deletar</button>
            </div>
        </td>
    </tr>
</template>

<script>
import { reactive } from 'vue'
import PDFRelatorioGerminacao from '../PDFRelatorioGerminacao.vue'

export default {
    components:{
     PDFRelatorioGerminacao
    },
    props: {
        isOpened: { type: Boolean, default: false },
        relatorio: { type: Object, required: true }
    },
    setup(_, { emit }) {

      const state = reactive({
      //  isOpen: props.isOpened,
      //  isClosing: !props.isOpened
      })

      async function handleToggle () {
        state.isClosing = true

      //  await wait(250)
        state.isOpen = !state.isOpen
        state.isClosing = false
        
      }
      return {
        state,
        handleToggle,
        emit
      }
  }  ,
      emits:['deletarRelatorio'],
}
</script>
