<template>
      <button class="btn btn-primary" @click="downloadPDF"> <div style="float:left">Download PDF </div> <img src="@/assets/loading.gif" style="width:20px; margin-left:10px" v-if="state.isLoading"/></button>
</template>

<script>
import jsPDF from 'jspdf'
import {  reactive  } from 'vue'
import services from '../services'
import 'jspdf-autotable'
import Loading from '../components/Loading'

export default {
    name: 'pfd',
    props: {
      relatorio: Object
    },
    setup(props) {

    var imgdata = "";
    function encodeImage(event){
       var img = event.target.files[0]

        var file = new FileReader()

        file.onloadend = function(){
            imgdata = file.result
            console.log(file.result)
        }
        file.readAsDataURL(img)
    //  state.fileMapa = event.target.files[0]
    }


    function createPdf(){
        var doc =  jsPDF()
        doc.addImage(imgdata,15,15)
        doc.save("output.pdf")
    }



    async function downloadPDF(){
      try {
        state.isLoading = true;
        const plantio = await services.relatorio_plantio.getByID({
          id:props.relatorio.id
        });
        gerarPDF(plantio);
      } catch (error) {
        alert('Erro ao criar Relatório')
      }
    }

    function gerarPDF(plantio){
    //  try{
        (function(API){
          API.myText = function(txt, options, x, y) {
            options = options ||{};
            /* Use the options align property to specify desired text alignment
            * Param x will be ignored if desired text alignment is 'center'.
            * Usage of options can easily extend the function to apply different text 
            * styles and sizes 
            */
            if( options.align == "center" ){
            // Get current font size
              var fontSize = this.internal.getFontSize();
              // Get page width
              var pageWidth = this.internal.pageSize.width;
              // Get the actual text's width
              /* You multiply the unit width of your string by your font size and divide
              * by the internal scale factor. The division is necessary
              * for the case where you use units other than 'pt' in the constructor
              * of jsPDF.
              */ 
              var txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;

              // Calculate text's x coordinate
              x = ( pageWidth - txtWidth ) / 2;
            }

                  // Draw text at x,y
                  this.text(txt,x,y);
          }
        })(jsPDF.API);
        //   var imagedata = new Blob([plantio.data.mapa.data])
        var pdf = jsPDF('p', 'px', 'a4');   
        //  var pageHeight = 842;
        //largura da página para usar abaixo
        //const pageWidthMidle = pdf.internal.pageSize.getWidth() / 2;  
        var y = 10;
        //   var x = 50;
        //   var img_logo_width = 60;
        //  var img_logo_height = 60;      
        //  var posicao_central_logo = pageWidthMidle - (img_logo_width / 2)
        pdf.setFontSize(12);
        pdf.setFontSize(10);

        pdf.myText("PLANILHA DE AVALIAÇÃO DE LAVOURA - "+plantio.data.tipo_lavoura,{align: "center"},20,y);
              
        y = y + 18;

        pdf.myText("Produtor",{align: "left"},20,y);
        pdf.myText(plantio.data.nome_produtor,{align: "left"},80,y);

        pdf.myText("Consultor",{align: "left"},310,y);
        pdf.myText(plantio.data.nome_engenheiro,{align: "left"},350,y);

        y = y + 12;
        pdf.myText("Propriedade",{align: "left"},20,y);
        pdf.myText(plantio.data.nome_fazenda,{align: "left"},80,y);

        pdf.myText("Município",{align: "left"},310,y);
        pdf.myText("",{align: "left"},350,y);

        y = y + 12;
        pdf.myText("Cultura ",{align: "left"},20,y);
        pdf.myText("",{align: "left"},80,y);

        pdf.myText("Safra",{align: "left"},310,y);
        pdf.myText(plantio.data.ano_safra,{align: "left"},350,y);

        y = y + 20;

        pdf.myText("DADOS GERAIS",{align: "center"},20,y);
        y = y + 18;
        pdf.myText("Espaçamento",{align: "left"},20,y);
        pdf.myText(plantio.data.espacamento,{align: "left"},80,y);

        var y2;
        y2 = y+12
        pdf.setFont(undefined,'bold');
        pdf.myText("Trat. Semente",{align: "left"},50,y2);
        pdf.setFont(undefined,'normal');
        y2 = y2+12
        for (let index = 0; index < plantio.data.produtos.data.length; index++) {
          pdf.myText(plantio.data.produtos.data[index].produto,{align: "left"},20,y2);
          pdf.myText(plantio.data.produtos.data[index].doses,{align: "left"},80,y2);
          y2 = y2 + 12;
          //  console.log(plantio.data.produtos.data[index].produto)
        }
        pdf.setFont(undefined,'bold');
        pdf.myText("Adubação Plantio",{align: "left"},170,y);
        pdf.setFont(undefined,'normal');  
        y = y + 12;

        pdf.myText("Fórmula",{align: "left"},170,y);
        pdf.myText(plantio.data.formula_adubacao_plantio,{align: "left"},210,y);

        pdf.myText("Data",{align: "left"},260,y);
        pdf.myText(plantio.data.data_adubacao_plantio,{align: "left"},290,y);

        pdf.myText("Qtde",{align: "left"},340,y);
        pdf.myText(plantio.data.qtd_adubacao_plantio,{align: "left"},370,y);
        y = y + 14;
        pdf.setFont(undefined,'bold');
        pdf.myText("Adubação Cobertura 1",{align: "left"},170,y);
        pdf.setFont(undefined,'normal');  
        pdf.myText("",{align: "left"},80,y);
        y = y + 12;

        pdf.myText("Fórmula",{align: "left"},170,y);
        pdf.myText(plantio.data.formula_adubacao_cobertura_1,{align: "left"},210,y);

        pdf.myText("Data",{align: "left"},260,y);
        pdf.myText(plantio.data.data_adubacao_cobertura_1,{align: "left"},290,y);

        pdf.myText("Qtde",{align: "left"},340,y);
        pdf.myText(plantio.data.qtd_adubacao_cobertura_1,{align: "left"},370,y);

        y = y + 14;

        if(plantio.data.formula_adubacao_cobertura_2 != null){        
          pdf.setFont(undefined,'bold');
          pdf.myText("Adubação Cobertura 2",{align: "left"},170,y);
          pdf.setFont(undefined,'normal');
          y = y + 12;
          pdf.myText("Fórmula",{align: "left"},170,y);
          pdf.myText(plantio.data.formula_adubacao_cobertura_2,{align: "left"},210,y);

          pdf.myText("Data",{align: "left"},260,y);
          pdf.myText(plantio.data.data_adubacao_cobertura_2,{align: "left"},290,y);

          pdf.myText("Qtde",{align: "left"},340,y);
          pdf.myText(plantio.data.qtd_adubacao_cobertura_2,{align: "left"},370,y);
        }
        y = y + 18;

        pdf.myText("INFORMAÇÕES PLANTIO",{align: "center"},20,y);

        var ya = y + 20
        var yb = y + 20
        var yc = y + 20

        for (let index = 0; index < plantio.data.hibridos.data.length; index++) {
          pdf.setFont(undefined,'bold');
          pdf.myText("Material "+index+1+" - "+plantio.data.hibridos.data[index].nome_hibrido,{align: "left"},20,ya);
          pdf.setFont(undefined,'normal');
          ya = ya + 12;

          //Qtde. Stes
          pdf.myText("QTDE. STES.",{align: "left"},20,ya);
          pdf.myText(plantio.data.hibridos.data[index].qtd_stes+" stes/m",{align: "left"},80,ya);
          ya = ya + 12;
          
          //Sistema
          pdf.myText("Sistema",{align: "left"},20,ya);
          pdf.myText(plantio.data.hibridos.data[index].sistema,{align: "left"},80,ya);
          ya = ya + 12;
                
          //Área Plantio
          pdf.myText("Área Plantio",{align: "left"},180,yb);
          pdf.myText(plantio.data.hibridos.data[index].area_plantio+" Ha",{align: "left"},240,yb);
          yb = yb + 12;

          //Peneira
          pdf.myText("Peneira",{align: "left"},180,yb);
          pdf.myText(plantio.data.hibridos.data[index].peneira,{align: "left"},240,yb);
          yb = yb + 12;

          //Veloc. Plantio
          pdf.myText("Veloc. Plantio",{align: "left"},180,yb);
          pdf.myText(plantio.data.hibridos.data[index].veloc_plantio+" km/h",{align: "left"},240,yb);
          yb = yb + 12;

          //Data Plantio
          pdf.myText("Data Plantio",{align: "left"},320,yc);
          pdf.myText(plantio.data.hibridos.data[index].data_plantio,{align: "left"},380,yc);
          yc = yc + 12;

          //Plantadeira
          /*   pdf.myText("Plantadeira",{align: "left"},320,yc);
          pdf.myText(plantio.data.hibridos.data[index].plantadeira,{align: "left"},380,yc);
          yc = yc + 12;

          //Disco Usado
          pdf.myText("Disco Usado",{align: "left"},320,yc);
          pdf.myText(plantio.data.hibridos.data[index].disco_usado+" Ha",{align: "left"},380,yc);
          yc = yc + 12;*/
        }

        state.isLoading = false
        pdf.save('info.pdf');
//      }catch(error){

  //    }
      state.isLoading = false
    }

    const state = reactive({
      isOpen: props.isOpened,
      isClosing: !props.isOpened,
      isLoading: false,
      relatorio_select: null
    })

    async function handleToggle () {
      state.isClosing = true
      state.isOpen = !state.isOpen
      state.isClosing = false    
    }

    return {
      downloadPDF,
      state,
      handleToggle,
      encodeImage,
      createPdf,
      Loading
    }
  }
}
</script>