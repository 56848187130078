<template>
  <div>
    <div  v-for="n in qtdLinhasDistanciaPlantas" :key="n">
      <h2>Linha {{n}}</h2>

      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label>Quantidade de Plantas</label>
             <select class="form-control" v-model="state.qtd_plantas_linhas[n]" @change="$emit('alterarQtdPlantasDistancia',$event,n)">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
                <option value="32">32</option>
                <option value="33">33</option>
                <option value="34">34</option>
                <option value="35">35</option>
                <option value="36">36</option>
                <option value="37">37</option>
                <option value="38">38</option>
                <option value="39">39</option>
                <option value="40">40</option>
            </select>
          </div>
        </div>
      </div>
    
      
       <div class="row" v-if="parseInt(state.qtd_plantas_linhas[n]) > 0">
           <div class="col-sm-8">
               <h4 >Distância: </h4>
           </div>
       </div>
       <div class="row" v-if="parseInt(state.qtd_plantas_linhas[n]) > 0">
            <div  v-for="n_qtd in parseInt(state.qtd_plantas_linhas[n].toString())" :key="n_qtd" class="col-sm-3" >
               <label for="">Planta {{ n_qtd }}</label>
               <input type="text" class="form-control" style="margin-left: 5px" @blur="$emit('alterarDistanciaPlanta',$event,n,n_qtd)">
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
 // import SelectInseticidas from "./InseticidaSelect.vue"


  export default {
    setup () {
        const state = reactive({
        qtd_plantas:0,
        qtd_plantas_linhas:[],

        })
        return  {
            state
        }
    },
    name: "QuantidadeLinhasDistanciaPlantas",
//    components: { SelectInseticidas },
    emits:['alterarQtdPlantas','alterarDistanciaPlanta'],
    props: {
      qtdLinhasDistanciaPlantas: Number
    }
  }
</script>
