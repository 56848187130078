<template>
    <tr @click="handleToggle">

         <td>
            {{ quimico.id }}
        </td>
        <td>
            {{ quimico.nome }}
        </td>
        <td>
            {{ quimico.categoria }}
        </td>
        <td class="text-right">
            <div>
                <router-link :to="{ name: 'AlterarQuimico', params: { id: quimico.id } }">
                    <button class="btn btn-primary" >Ver / Alterar</button>
                </router-link>
                <button class="btn btn-danger" @click="$emit('deletarQuimico',quimico.id)">Deletar</button>
            </div>

        </td>
    </tr>
</template>

<script>
import { reactive } from 'vue'
export default {
    props: {
        isOpened: { type: Boolean, default: false },
        quimico: { type: Object, required: true }
    },
    setup(_, { emit }) {
        const state = reactive({
          //  isOpen: props.isOpened,
          //  isClosing: !props.isOpened
        })

        async function handleToggle () {
        state.isClosing = true

      //  await wait(250)
        state.isOpen = !state.isOpen
        state.isClosing = false
        
      }
      return {
        state,
        handleToggle,
        emit
      }
    },
    emits:['deletarQuimico'],

}
</script>