<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid  align-items-center">
        <div class="row">
          <div class="col-lg-10 col-md-10">
            <h1 class="display-2 text-white">Seja Bem Vindo</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    {{state.fileLogo}}
                    <img :src="state.fileLogo"
                      class="rounded-circle"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >

            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  >
                    <div>
                      <span class="heading">22</span>
                      <span class="description">Relatórios</span>
                    </div>
                    <div>
                      <span class="heading">10</span>
                      <span class="description">Produtores</span>
                    </div>
                    <div>
                      <span class="heading">89</span>
                      <span class="description">Cultivares</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ state.nome.value }}
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>Bucharest, Romania
                </div>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i>{{}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Minha conta</h3>
                  </div>
                </div>
              </div>
            </template>

          <form @submit.prevent="handleSubmit">  
              <h6 class="heading-small text-muted mb-4">Usuário</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Nome"
                      placeholder="Nome"
                      input-classes="form-control-alternative"
                      v-model="state.nome.value"
                    />
                  </div>

                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="E-mail"
                      placeholder=""
                      input-classes="form-control-alternative"
                      v-model="state.email.value"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Usuário"
                      placeholder="E-mail de Login"
                      input-classes="form-control-alternative"
                      v-model="state.username.value"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="Senha"
                      input-classes="form-control-alternative"
                      v-model="state.password.value"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      label="Telefone"
                      placeholder="Telefone"
                      input-classes="form-control-alternative"
                      v-model="state.telefone.value"
                    />
                  </div>
                  <div class="col-lg-8">
                    <base-input
                      alternative=""
                      label="Empresa"
                      input-classes="form-control-alternative"
                      v-model="state.empresa.value"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Logo</label>
                      <input type="file" id="files" name="files" multiple class="form-control" @change="newFileLogo" > 
                    </div>
                  </div>
                </div>
                <div class="row"> 
                  <div class="col-lg-12">
                    <div class="form-group">
                      <base-input alternative="" label="Observação" >
                        <textarea
                        v-model="state.observacao.value"
                          rows="4"
                          class="form-control form-control-alternative"
                          placeholder="Coloque aqui a observação."
                        ></textarea
                        >
                      </base-input>
                    </div>
                  </div>

                  <div class="col text-right">
                    <button class="btn btn-primary my-4" id="submit-button" :disabled="state.isLoading" type="submit">Salvar</button>
                    <button class="btn btn-warning my-4" id="submit-button" type="button" @click="cancel()">Cancelar</button>
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from 'vue'
import { useField } from "vee-validate";
import { validateEmptyAndLength3 } from '../utils/validators'
import { useRouter } from 'vue-router'
import services from '../services'


export default {
  name: "user-profile",
  setup () {
    const router = useRouter()
    const {
      value: codigoValue,
      errorMessage: codigoErrorMessage
    } = useField('codigo', validateEmptyAndLength3)
    const {
      value: nomeValue,
      errorMessage: nomeErrorMessage
    } = useField('nome', validateEmptyAndLength3)

    const {
      value: emailValue,
      errorMessage: emailErrorMessage
    } = useField('email', validateEmptyAndLength3)

    const {
      value: usernameValue,
      errorMessage: usernameErrorMessage
    } = useField('username', validateEmptyAndLength3)

    const {
      value: passwordValue,
      errorMessage: passwordErrorMessage
    } = useField('password', validateEmptyAndLength3)
    
    const {
      value: telefoneValue,
      errorMessage: telefoneErrorMessage
    } = useField('telefone', validateEmptyAndLength3)

    const {
      value: empresaValue,
      errorMessage: empresaErrorMessage
    } = useField('empresa', validateEmptyAndLength3)

    const {
      value: observacaoValue,
      errorMessage: observacaoErrorMessage
    } = useField('empresa', validateEmptyAndLength3)
   

    const state = reactive({
      hasErrors: false,
      isLoading: false, 
      fileLogo: "",
      codigo: {
        value: codigoValue,
        errorMessage: codigoErrorMessage
      },
      nome: {
        value: nomeValue,
        errorMessage: nomeErrorMessage
      },
      email: {
        value: emailValue,
        errorMessage: emailErrorMessage
      },
      username: {
        value: usernameValue,
        errorMessage:usernameErrorMessage
      },
      password: {
        value: passwordValue,
        errorMessage:passwordErrorMessage
      },
      telefone: {
        value: telefoneValue,
        errorMessage:telefoneErrorMessage
      },
      empresa: {
        value: empresaValue,
        errorMessage: empresaErrorMessage
      },
      observacao: {
        value: observacaoValue,
        errorMessage: observacaoErrorMessage
      },

    })

    function handleErrors (error) {
        console.log(error)
        state.hasError = !!error
    }

      async function fetchEngenheiro() {
        try {
          state.isLoading = true
          const { data } = await services.engenheiro.getById({
            id : 1
          }
          )
          state.codigo.value = data.id
          state.nome.value = data.nome
          state.email.value = data.email
          state.username.value = data.username
          state.password.value = data.password
          state.telefone.value = data.telefone
          state.empresa.value = data.empresa
          state.observacao.value = data.observacao
          state.fileLogo = data.base64
          state.isLoading = false
          
          console.log(data)

        } catch (error) {
          handleErrors(error)
        }
      }


      onMounted(() => {
        fetchEngenheiro()
      })
    function newFileLogo(event){
      console.log(event)
      state.fileLogo = event.target.files[0]
    }

    function cancel(){
      router.push({ name: 'dashboard' })
    }

    async function handleSubmit() {
      try {
        state.isLoading = true

        const formData = new FormData();
        formData.append("id", 1);
        formData.append("nome", state.nome.value);
        formData.append("email", state.email.value);
        formData.append("username", state.username.value);
        formData.append("password", state.password.value);
        formData.append("telefone", state.telefone.value);
        formData.append("empresa", state.empresa.value);
        formData.append("observacao", state.observacao.value);
        formData.append("img_logo",state.fileLogo);

        if(!state.codigo.value){
          const { errors } = await services.engenheiro.save(formData)

          if (!errors) {
             //       window.localStorage.setItem('token', data.token)
              router.push({ name: 'products' })
              state.isLoading = false
              return
          }

          if (errors.status === 400) {
          //  toast.error('Ocorreu um erro ao criar a conta')
          }
        }else{
          const { errors } = await services.engenheiro.update(formData)

          if (!errors) {
             //       window.localStorage.setItem('token', data.token)
              router.push({ name: 'products' })
              state.isLoading = false
              return
          }

          if (errors.status === 400) {
          //  toast.error('Ocorreu um erro ao criar a conta')
          }
        }
        
        state.isLoading = false

      } catch (error) {
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }
  return {
    state,
    handleSubmit,
    cancel,
    newFileLogo
  }
  }
};
</script>
<style></style>
