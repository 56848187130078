<template>
    <div class="loading"></div>
</template>

<script>
export default {

}
</script>

<style>

.loading{
    width: 20px;
    height: 20px;
    background: conic-gradient(#0000 10%, #00A859);
    border-radius: 50%;
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 20px),
    #000 0);
    animation: animate 0.9s infinite linear;
    margin-left: auto; 
  margin-right: auto; 
}

@keyframes animate{
    100%{
        transform: rotate(360deg);
    }
}
</style>