<template>
  <div>
  
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
    
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item"><router-link to="/engenheiros"  tag="primary">Engenheiros</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
              </ol>
            </nav>

          <form @submit.prevent="handleSubmit">  
            <div>
              <h2 class="mb-3">Novo Engenheiro</h2>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Engenheiro
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >


                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Terá acesso ao sistema</label>
                          <select class="form-control" v-model="state.login">
                            <option value="NAO">Não</option>
                            <option value="SIM">Sim</option>
                          </select>
                        </div>
                      </div>
                    </div>  

                      
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Código</label>
                          <input type="text" class="form-control" v-model="state.codigo.value" readonly />
                        </div>
                      </div>
                      <div class="col-lg-9">
                        <div class="form-group">
                          <label>Nome</label>
                          <input type="text" class="form-control" v-model="state.nome.value" />
                          <span v-if="!!state.nome.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.nome.errorMessage }}
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-5">
                        <div class="form-group">
                          <label>Telefone</label>
                          <input type="text" class="form-control" v-model="state.telefone.value" />
                          <span v-if="!!state.telefone.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.telefone.errorMessage }}
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-7" v-if="state.login == 'NAO'">
                        <div class="form-group">
                          <label>E-mail</label>
                          <input type="text" class="form-control" v-model="state.email.value" />
                          <span v-if="!!state.email.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.email.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Observação </label>
                          <textarea class="form-control" v-model="state.observacao.value"  rows="4"></textarea>
                          <span v-if="!!state.observacao.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.observacao.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''" v-if="state.login == 'SIM'">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Login
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''" v-if="state.login == 'SIM'">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Terá acesso Administrador?</label>
                          <select class="form-control" v-model="state.admin">
                            <option value="NAO">Não</option>
                            <option value="SIM">Sim</option>
                          </select>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>E-mail</label>
                          <input type="text" class="form-control" v-model="state.email.value" />
                          <span v-if="!!state.email.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.email.errorMessage }}
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Senha</label>
                          <input type="password" class="form-control" v-model="state.password.value" />
                          <span v-if="!!state.password.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.password.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>


                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Empresa
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Nome</label>
                          <input type="text" class="form-control" v-model="state.empresa.value" />
                          <span v-if="!!state.empresa.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.empresa.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Logo</label><br>
                          <input @change="newFileLogo" type="file" class="form-control">
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary my-4" id="submit-button" :disabled="state.isLoading" type="submit">Salvar</button>
            <button class="btn btn-warning my-4" id="submit-button" type="button" @click="cancel()">Cancelar</button>
          </form>
          </stats-card>
        </div>
        
      </div>
    </div>
</div>

</template>

<script>
import { onMounted, reactive } from 'vue'
import services from '../services'
import { useField } from "vee-validate";
import { validateEmptyAndLength3 } from '../utils/validators'
import { useRouter } from 'vue-router'

export default {
  
  setup (_, { emit }) {
    const router = useRouter()

    var id_engenheiro = router.currentRoute._value.params.id
    if(id_engenheiro === undefined){
      id_engenheiro = 0;
    }

    function cancel(){
      router.push({ name: 'engenheiros' })
    }
    const {
      value: codigoValue,
      errorMessage: codigoErrorMessage
    } = useField('codigo', validateEmptyAndLength3)

    const {
      value: nomeValue,
      errorMessage: nomeErrorMessage
    } = useField('nome', validateEmptyAndLength3)

    const {
      value: telefoneValue,
      errorMessage: telefoneErrorMessage
    } = useField('telefone', validateEmptyAndLength3)

    const {
      value: emailValue,
      errorMessage: emailErrorMessage
    } = useField('email', validateEmptyAndLength3)

    const {
      value: passwordValue,
      errorMessage: passwordErrorMessage
    } = useField('password', validateEmptyAndLength3)

    const {
      value: empresaValue,
      errorMessage: empresaErrorMessage
    } = useField('empresa', validateEmptyAndLength3)

    const {
      value: observacaoValue,
      errorMessage: observacaoErrorMessage
    } = useField('observacao', validateEmptyAndLength3)

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      fileLogo:'',
      login:'NAO',
      admin:'NAO',
      codigo: {
        value: codigoValue,
        errorMessage: codigoErrorMessage
      },
      nome: {
        value: nomeValue,
        errorMessage: nomeErrorMessage
      },
      telefone: {
        value: telefoneValue,
        errorMessage: telefoneErrorMessage
      },
      email: {
        value: emailValue,
        errorMessage: emailErrorMessage
      },
      password: {
        value: passwordValue,
        errorMessage: passwordErrorMessage
      },
      empresa: {
        value: empresaValue,
        errorMessage: empresaErrorMessage
      },
      observacao: {
        value: observacaoValue,
        errorMessage: observacaoErrorMessage
      }
    })
    async function fetchEngenheiro() {
      try {
      /*  state.isLoading = true        */
        const { data } = await services.engenheiro.getById({
          id : id_engenheiro
        }
        )

        console.log(data);

        state.codigo.value = data.id
        state.nome.value = data.nome
        state.telefone.value = data.telefone
        state.observacao.value = data.observacao
        state.email.value = data.email
        state.empresa.value = data.empresa
        state.login = data.login
        state.admin = data.admin

      //  state.isLoading = false

      } catch (error) {
        handleErrors(error)
      }
    }

    function handleErrors (error) {
        console.log(error)
        state.hasError = !!error
      }
      onMounted(() => {
        if(id_engenheiro != 0){
          fetchEngenheiro()
        }
      })

    function newFileLogo(event){
      console.log(event)
      state.fileLogo = event.target.files[0]
    }

    async function handleSubmit() {
      try {
        state.isLoading = true
        if(state.codigo.value){

          const formData = new FormData();
          formData.append("id", state.codigo.value);
          formData.append("login", state.login);
          formData.append("admin", state.admin);

          formData.append("nome", state.nome.value);
          formData.append("email", state.email.value);
          formData.append("password", state.password.value);
          formData.append("telefone", state.telefone.value);
          formData.append("empresa", state.empresa.value);
          formData.append("observacao", state.observacao.value);
          formData.append("img_logo", state.fileLogo);

          

          const { errors } = await services.engenheiro.update(formData)
          if (!errors) {
            router.push({ name: 'engenheiros' })
            state.isLoading = false
            return
          }
        }else{
          const formData = new FormData();
          formData.append("nome", state.nome.value);
          formData.append("login", state.login);
          formData.append("admin", state.admin);

          formData.append("email", state.email.value);
          formData.append("password", state.password.value);
          formData.append("telefone", state.telefone.value);
          formData.append("empresa", state.empresa.value);
          formData.append("observacao", state.observacao.value);
          formData.append("img_logo", state.fileLogo);

          const { errors } = await services.engenheiro.save(formData)
          if(errors == true){
            alert("Erro ao cadastrar engenheiro. Verifique os dados.");
          }else{
            router.push({ name: 'engenheiros' })
          }
                 
        }


//        if (errors.status === 400) {
        //  toast.error('Ocorreu um erro ao criar a conta')
  //      }
        state.isLoading = false

      } catch (error) {
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }
  return {
    state,
    handleSubmit,
    emit,
    cancel,
    newFileLogo
  }
  }
}





</script>

<style scoped>
  textarea {
    resize: none;
  }
</style>