<template>
  <div>
    <div  v-for="n in qtdFungicidas" :key="n">
      <h2>Fungicida {{n}}</h2>
      <div class="row">
        <div class="col-lg-4">
            <div class="form-group">
              <label>Quimico</label>
              <select class="form-control" @change="$emit('adicionarFungicida',$event,n,'fungicida')">
                <option value="">Escolha</option>
                <select-fungicida
                v-for="fungicida in fungicidas"
                :key="fungicida.id"
                :fungicida="fungicida"
                />
              </select>
            </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Quantidade</label> 
            <input type="text" class="form-control" @blur="$emit('adicionarFungicida',$event,n,'quantidade')">
          </div>
        </div>
        <div class="col-lg-2">
          <div class="form-group">
            <label>Medida</label>
            <select name="" id="" class="form-control" @change="$emit('adicionarFungicida',$event,n,'medida')">
              <option value="">Escolha</option>
              <option value="KG">Quilos</option>
              <option value="L">Litros</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Por</label>
            <select name="" id="" class="form-control" @change="$emit('adicionarFungicida',$event,n,'espaco')">
              <option value="">Escolha</option>
              <option value="ALQUEIRE">Alqueire</option>
              <option value="HECTARE">Hectare</option>
            </select>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import SelectFungicida from "./FungicidaSelect.vue"


  export default {
    name: "QuantidadeFungicidas",
    components: { SelectFungicida },
    emits:['adicionarFungicida'],
    props: {
      qtdFungicidas: Number,
      fungicidas: Array
    }
  }
</script>
