<template>
<modal-factory />
  <div>
  
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
    
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item"><router-link to="/quimicos"  tag="primary">Quimicos</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
              </ol>
            </nav>
          <form @submit.prevent="handleSubmit">  
            <div>
              <h2 class="mb-3">Novo Químico</h2>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Químico
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Código</label>
                          <input type="text" class="form-control" v-model="state.codigo.value" readonly/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Nome </label>
                          <input type="text" class="form-control" v-model="state.nome.value" />
                          <span v-if="!!state.nome.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.nome.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Categoria</label>
                          <select class="form-control" v-model="state.categoria.value">
                            <option value="TSI">TSI</option>
                            <option value="OLEO">Óleo</option>
                            <option value="INSETICIDA">Inseticida</option>
                            <option value="HERBICIDA">Herbicida</option>
                            <option value="FUNGICIDA">Fungicida</option>
                            <option value="ACARICIDA">Acaricida</option>
                            <option value="OUTRO">Outro</option>
                          </select>
                          <span v-if="!!state.categoria.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.categoria.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Ingredientes Ativo 1 </label>
                          <input type="text" class="form-control" v-model="state.ingrediente_ativo_1.value" />
                          <span v-if="!!state.ingrediente_ativo_1.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.ingrediente_ativo_1.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Ingredientes Ativo 2</label>
                          <input type="text" class="form-control" v-model="state.ingrediente_ativo_2.value" />
                          <span v-if="!!state.ingrediente_ativo_2.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.ingrediente_ativo_1.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Ingredientes Ativo 3</label>
                          <input type="text" class="form-control" v-model="state.ingrediente_ativo_3.value" />
                          <span v-if="!!state.ingrediente_ativo_3.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.ingrediente_ativo_1.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Descrição</label>
                          <textarea v-model="state.descricao.value" rows="5" class="form-control"></textarea>
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary my-4" id="submit-button" :disabled="state.isLoading" type="submit">Save</button>
            <button class="btn btn-warning my-4" id="submit-button" type="button" @click="cancel()">Cancel</button>
          </form>
          </stats-card>
        </div>
        
      </div>
    </div>
</div>

</template>

<script>
import { onMounted, reactive } from 'vue'
import services from '../services'
import { useField } from "vee-validate";
import { validateEmptyAndLength3 } from '../utils/validators'
import useModal from '../hooks/useModal'
import ModalFactory from '../components/ModalFactory'
import { useRouter } from 'vue-router'

export default {

  components: { ModalFactory },
  setup (_, { emit }) {
    const router = useRouter()
    const modal = useModal()

    var id_quimico = router.currentRoute._value.params.id
    if(id_quimico === undefined){
      id_quimico = 0;
    }

    function handleCategory(){
      modal.open({
        component: 'ModalCategory'
      })
    }

    function cancel(){
      router.push({ name: 'quimicos' })
    }
    const {
      value: codigoValue,
      errorMessage: codigoErrorMessage
    } = useField('codigo', validateEmptyAndLength3)

    const {
      value: nomeValue,
      errorMessage: nomeErrorMessage
    } = useField('nome', validateEmptyAndLength3)

    const {
      value: categoriaValue,
      errorMessage: categoriaErrorMessage
    } = useField('categoria', validateEmptyAndLength3)

    const {
      value: ingreditenteAtivo1Value,
      errorMessage: ingreditenteAtivo1ErrorMessage
    } = useField('ingrediente_ativo_1', validateEmptyAndLength3)

    const {
      value: ingreditenteAtivo2Value,
      errorMessage: ingreditenteAtivo2ErrorMessage
    } = useField('ingrediente_ativo_2', validateEmptyAndLength3)

    const {
      value: ingreditenteAtivo3Value,
      errorMessage: ingreditenteAtivo3ErrorMessage
    } = useField('ingrediente_ativo_3', validateEmptyAndLength3)

    const {
      value: descricaoValue,
      errorMessage: descricaoErrorMessage
    } = useField('descricao', validateEmptyAndLength3)


    const state = reactive({
      hasErrors: false,
      isLoading: false,
      codigo: {
        value: codigoValue,
        errorMessage: codigoErrorMessage
      },
      nome: {
        value: nomeValue,
        errorMessage: nomeErrorMessage
      },
      categoria: {
        value: categoriaValue,
        errorMessage: categoriaErrorMessage
      },
      ingrediente_ativo_1: {
        value: ingreditenteAtivo1Value,
        errorMessage: ingreditenteAtivo1ErrorMessage
      },
      ingrediente_ativo_2: {
        value: ingreditenteAtivo2Value,
        errorMessage: ingreditenteAtivo2ErrorMessage
      },
        ingrediente_ativo_3: {
        value: ingreditenteAtivo3Value,
        errorMessage: ingreditenteAtivo3ErrorMessage
      },
      descricao: {
        value: descricaoValue,
        errorMessage: descricaoErrorMessage
      },
    })

    async function fetchQuimico() {
        try {
          state.isLoading = true
          const { data } = await services.quimico.getById({
            id : id_quimico
          }
          )
          state.codigo.value = data.id
          state.nome.value = data.nome
          state.ingrediente_ativo_1.value = data.ingrediente_ativo_1
          state.ingrediente_ativo_2.value = data.ingrediente_ativo_2
          state.ingrediente_ativo_3.value = data.ingrediente_ativo_3
          state.categoria.value = data.categoria
          state.descricao.value = data.descricao
          state.isLoading = false
          
        } catch (error) {
          handleErrors(error)
        }
      }

      function handleErrors (error) {
        console.log(error)
        state.hasError = !!error
      }
      onMounted(() => {
        if(id_quimico != 0){
          fetchQuimico()
        }
        
      })

    async function handleSubmit() {

      try {
        state.isLoading = true
        if(state.codigo.value){
          const { errors } = await services.quimico.update({
            id: state.codigo.value,
            nome: state.nome.value,
            ingrediente_ativo_1: state.ingrediente_ativo_1.value,
            ingrediente_ativo_2: state.ingrediente_ativo_2.value,
            ingrediente_ativo_3: state.ingrediente_ativo_3.value,
            categoria: state.categoria.value,
            descricao: state.descricao.value
          })
          if (!errors) {
            router.push({ name: 'quimicos' })
            state.isLoading = false
            return
          }
        }else{
          console.log('sem codigo');
           const { errors }= await services.quimico.save({
            nome: state.nome.value,
            ingrediente_ativo_1: state.ingrediente_ativo_1.value,
            ingrediente_ativo_2: state.ingrediente_ativo_2.value,
            ingrediente_ativo_3: state.ingrediente_ativo_3.value,
            categoria: state.categoria.value,
            descricao: state.descricao.value
          })
          if (!errors) {
            router.push({ name: 'quimicos' })
            state.isLoading = false
            return
          }          
        }
//        if (errors.status === 400) {
        //  toast.error('Ocorreu um erro ao criar a conta')
  //      }
        state.isLoading = false

      } catch (error) {
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }
  return {
    state,
    handleCategory,
    handleSubmit,
    emit,
    cancel
  }
  }
}





</script>

<style scoped>
  textarea {
    resize: none;
  }
</style>