import axios from 'axios'
import AuthService from './auth'
import CultivarService from './cultivar'
import ProdutorService from './produtor'
import PragaService from './praga'
import DoencasService from './doenca'
import EngenheirosService from './engenheiro'
import QuimicoService from './quimico'
import RelatorioService from './relatorio'
import RelatorioPlantioService from './relatorioPlantio'
import ProfileService from './profile'
import RelatorioGerminacaoService from './relatorioGerminacao'
import AvaliacaoMilhoService from './avaliacao_milho'

const API_ENVS = {
    production: '',
    development: '',
    local: 'http://159.223.114.112:8181/api/',
//local: 'http://localhost:8181/api/',
}

const API_POSTAL = {
    production: 'http://api.postcodes.io/postcodes/',
    development: '',
    local: '',

}

const httpPostal = axios.create({
    baseURL: API_POSTAL.production
})


const httpClient = axios.create({
    baseURL: API_ENVS.local
})


export default {
    devServer: {
        proxy: 'https://localhost:8080/api'
    },

    quimico: QuimicoService(httpClient),
    auth: AuthService(httpClient),
    cultivar: CultivarService(httpClient),
    doenca: DoencasService(httpClient),
    produtor: ProdutorService(httpClient),
    praga: PragaService(httpClient),
    engenheiro: EngenheirosService(httpClient),
    relatorio: RelatorioService(httpClient),
    relatorio_plantio: RelatorioPlantioService(httpClient),
    profile: ProfileService(httpPostal),
    relatorio_germinacao: RelatorioGerminacaoService(httpClient),
    avaliacao_milho: AvaliacaoMilhoService(httpClient)
}