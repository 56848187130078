export default httpClient => ({
    getAll: async () => {

      const response = await httpClient.get('/relatorios-germinacao')
      
      return { data: response.data }
  
    },
    getById: async ({ id }) => {
      const response = await httpClient.get('/relatorio-germinacao/'+id,{ 
      })
      console.log("resposta direta:")
      console.log(response.data.id)

      const nascidas_linha_1 = await httpClient.get('/relatorio-germinacao/nascidas-linha/'+response.data.id+'/1')
      const nascidas_linha_2 = await httpClient.get('/relatorio-germinacao/nascidas-linha/'+response.data.id+'/2')
      const nascidas_linha_3 = await httpClient.get('/relatorio-germinacao/nascidas-linha/'+response.data.id+'/3')

      response.nascidas_linha_1 = nascidas_linha_1.data;
      response.nascidas_linha_2 = nascidas_linha_2.data;
      response.nascidas_linha_3 = nascidas_linha_3.data;

      const distancia_linha_1 = await httpClient.get('/relatorio-germinacao/distancia-linha/'+response.data.id+'/1')
      const distancia_linha_2 = await httpClient.get('/relatorio-germinacao/distancia-linha/'+response.data.id+'/2')
      const distancia_linha_3 = await httpClient.get('/relatorio-germinacao/distancia-linha/'+response.data.id+'/3')

      response.distancia_linha_1 = distancia_linha_1.data;
      response.distancia_linha_2 = distancia_linha_2.data;
      response.distancia_linha_3 = distancia_linha_3.data;
      return { data: response }
    },

    save: async ({ engenheiro_id, data, hibrido, DPP,distancia_linha, observacao, produtor_id }) => {
      const response = await httpClient.post('/relatorio-germinacao/adicionar', {
        engenheiro_id,
        data,
        hibrido,
        DPP,
        distancia_linha,
        observacao,
        produtor_id
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    
    save_plantas_nascidas: async ({ relatorio_id, nascimentos }) => {
      const response = await httpClient.post('/relatorio-germinacao/adicionar-plantas-nascidas', {
        nascimentos,
        relatorio_id
      })
      let errors = null

      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_plantas_distancia: async ({ relatorio_id, distancias }) => {
      const response = await httpClient.post('/relatorio-germinacao/adicionar-plantas-distancia', {
        distancias,
        relatorio_id
      })
      let errors = null
  
      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    delete: async ({ id }) => {
      const response = await httpClient.post('/relatorio-germinacao/deletar',{
        id
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    }
})