<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/avaliacao-milho" tag="primary"
                    >Avaliações Milho</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Adicionar
                </li>
              </ol>
            </nav>

            <form @submit.prevent="handleSubmit">
              <div>
                <h2 class="mb-3">Nova Avaliação Milho</h2>
              </div>

              <div class="row">
                <div class="col-md-12 col-lg-6">
                  <div
                    class="card shadow mb-3"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div class="card-header border-0">
                      <div class="row align-items-center">
                        <div class="col">
                          <h3
                            class="mb-0"
                            :class="type === 'dark' ? 'text-white' : ''"
                          >
                            Dados da Avaliação
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div
                      class="card-header border-0"
                      :class="type === 'dark' ? 'bg-transparent' : ''"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Código</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.codigo.value"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Data</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.data.value"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Produtor</label>
                            <select
                              class="form-control"
                              v-model="state.produtor.value"
                            >
                              <select-produtores
                                v-for="produtor in state.produtores"
                                :key="produtor.id"
                                :produtor="produtor"
                              />
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card shadow mb-3"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div class="card-header border-0">
                      <div class="row align-items-center">
                        <div class="col">
                          <h3
                            class="mb-0"
                            :class="type === 'dark' ? 'text-white' : ''"
                          >
                            Quantidade de Folhas Nascidas
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div
                      class="card-header border-0"
                      :class="type === 'dark' ? 'bg-transparent' : ''"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Quantidade de Linha</label>
                            <select
                              name=""
                              id=""
                              class="form-control"
                              v-model="state.qtd_linha_numero_folhas"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <QuantidadeLinhasFolhas
                        @alterarPlantasFolha="AlterarPlantasFolha"
                        @alterarQtdPlantasFolhas="AlterarQtdPlantasFolhas"
                        :qtdLinhasNumeroFolha="
                          parseInt(state.qtd_linha_numero_folhas)
                        "
                      />
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Comentários</label>
                            <textarea
                              class="form-control"
                              rows="3"
                              v-model="state.comentario_plantas_folhas.value"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="card shadow mb-3"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div class="card-header border-0">
                      <div class="row align-items-center">
                        <div class="col">
                          <h3
                            class="mb-0"
                            :class="type === 'dark' ? 'text-white' : ''"
                          >
                            Altura das Plantas
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div
                      class="card-header border-0"
                      :class="type === 'dark' ? 'bg-transparent' : ''"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Quantidade de Linha</label>
                            <select
                              name=""
                              id=""
                              class="form-control"
                              v-model="state.qtd_linha_altura_plantas"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <QuantidadeLinhasAlturaPlantas
                        @alterarPlantasAltura="AlterarPlantasAltura"
                        @alterarQtdPlantasAltura="AlterarQtdPlantasAltura"
                        :qtdLinhasAlturaPlanta="
                          parseInt(state.qtd_linha_altura_plantas)
                        "
                      />
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Comentários</label>
                            <textarea
                              class="form-control"
                              rows="3"
                              v-model="state.comentario_plantas_altura.value"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6">
                  <div
                    class="card shadow mb-3"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div class="card-header border-0">
                      <div class="row align-items-center">
                        <div class="col">
                          <h3
                            class="mb-0"
                            :class="type === 'dark' ? 'text-white' : ''"
                          >
                            Ataque de Pragas
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div
                      class="card-header border-0"
                      :class="type === 'dark' ? 'bg-transparent' : ''"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Quantidade de Ataque Praga</label>
                            <select
                              name=""
                              id=""
                              class="form-control"
                              v-model="state.qtd_linha_ataque_praga"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <QuantidadeLinhasAtaquePragas
                        @alterarAtaquePraga="AlterarAtaquePraga"
                        @alterarQtdAtaquePraga="AlterarQtdAtaquePraga"
                        :qtdLinhasAtaquePraga="
                          parseInt(state.qtd_linha_ataque_praga)
                        "
                      />
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Comentários</label>
                            <textarea
                              class="form-control"
                              rows="3"
                              v-model="
                                state.comentario_plantas_ataque_pragas.value
                              "
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card shadow mb-3"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div class="card-header border-0">
                      <div class="row align-items-center">
                        <div class="col">
                          <h3
                            class="mb-0"
                            :class="type === 'dark' ? 'text-white' : ''"
                          >
                            Avaliação Doenças
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div
                      class="card-header border-0"
                      :class="type === 'dark' ? 'bg-transparent' : ''"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Quantidade de Linha</label>
                            <select
                              name=""
                              id=""
                              class="form-control"
                              v-model="state.qtd_linha_avaliacao_doencas"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <QuantidadeLinhasAvaliacaoDoencas
                        @alterarAvaliacaoDoencas="AlterarAvaliacaoDoencas"
                        @alterarQtdAvaliacaoDoencas="AlterarQtdAvaliacaoDoencas"
                        :qtdLinhasAvaliacaoDoencas="
                          parseInt(state.qtd_linha_avaliacao_doencas)
                        "
                      />
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Comentários</label>
                            <textarea
                              class="form-control"
                              rows="3"
                              v-model="
                                state.comentario_plantas_avaliacao_doencas.value
                              "
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary my-4"
                id="submit-button"
                :disabled="state.isSaving"
                type="submit"
              >
                Salvar<img
                  src="@/assets/loading.gif"
                  style="width: 20px; margin-left: 10px"
                  v-if="state.isSaving"
                />
              </button>
              <button
                class="btn btn-warning my-4"
                id="submit-button"
                type="button"
                @click="cancel()"
              >
                Cancel
              </button>
            </form>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import { useField } from "vee-validate";
import { validateEmptyAndLength3, validateEmpty } from "../utils/validators";
import { useRouter } from "vue-router";
import SelectProdutores from "../components/ProdutoresSelect.vue";
import Cookie from "js-cookie";

import QuantidadeLinhasFolhas from "../components/QuantidadeLinhasFolhas";
import QuantidadeLinhasAvaliacaoDoencas from "../components/QuantidadeLinhasAvaliacaoDoencas";
import QuantidadeLinhasAtaquePragas from "../components/QuantidadeLinhasAtaquePragas";
import QuantidadeLinhasAlturaPlantas from "../components/QuantidadeLinhasAlturaPlantas";

export default {
  setup(_, { emit }) {
    var engenheiro_id;
    const router = useRouter();
    var id_doenca = router.currentRoute._value.params.id;
    if (id_doenca === undefined) {
      id_doenca = 0;
    }

    function cancel() {
      router.push({ name: "AvaliacaoMilho" });
    }
    const { value: codigoValue, errorMessage: codigoErrorMessage } = useField(
      "codigo",
      validateEmptyAndLength3
    );

    const {
      value: comentarioPlantasFolhasValue,
      errorMessage: comentarioPlantasFolhasErrorMessage,
    } = useField("comentario_plantas_folhas", validateEmptyAndLength3);

    const {
      value: comentarioPlantasAlturaValue,
      errorMessage: comentarioPlantasAlturaErrorMessage,
    } = useField("comentario_plantas_altura", validateEmptyAndLength3);

    const {
      value: comentarioPlantasAvaliacaoDoencasValue,
      errorMessage: comentarioPlantasAvaliacaoDoencasErrorMessage,
    } = useField(
      "comentario_plantas_avaliacao_doencas",
      validateEmptyAndLength3
    );

    const {
      value: comentarioPlantasAtaquePragasValue,
      errorMessage: comentarioPlantasAtaquePragasErrorMessage,
    } = useField("comentario_plantas_ataque_pragas", validateEmptyAndLength3);

    const {
      value: produtorValue,
      errorMessage: produtorErrorMessage,
    } = useField("produtor", validateEmpty);

    const { value: dataValue, errorMessage: dataErrorMessage } = useField(
      "data",
      validateEmptyAndLength3
    );

    const state = reactive({
      isSaving: false,
      qtd_linha_numero_folhas: 0,
      qtd_linha_altura_plantas: 0,
      qtd_linha_ataque_praga: 0,
      qtd_linha_avaliacao_doencas: 0,
      produtores: [],
      plantas_folhas_linha_1: [],
      plantas_folhas_linha_2: [],
      plantas_folhas_linha_3: [],

      plantas_altura_linha_1: [],
      plantas_altura_linha_2: [],
      plantas_altura_linha_3: [],

      plantas_avaliacao_doencas_linha_1: [],
      plantas_avaliacao_doencas_linha_2: [],
      plantas_avaliacao_doencas_linha_3: [],

      plantas_ataque_pragas_linha_1: [],
      plantas_ataque_pragas_linha_2: [],
      plantas_ataque_pragas_linha_3: [],

      hasErrors: false,
      isLoading: false,

      codigo: {
        value: codigoValue,
        errorMessage: codigoErrorMessage,
      },
      data: {
        value: dataValue,
        errorMessage: dataErrorMessage,
      },
      produtor: {
        value: produtorValue,
        errorMessage: produtorErrorMessage,
      },
      comentario_plantas_folhas: {
        value: comentarioPlantasFolhasValue,
        errorMessage: comentarioPlantasFolhasErrorMessage,
      },
      comentario_plantas_altura: {
        value: comentarioPlantasAlturaValue,
        errorMessage: comentarioPlantasAlturaErrorMessage,
      },
      comentario_plantas_avaliacao_doencas: {
        value: comentarioPlantasAvaliacaoDoencasValue,
        errorMessage: comentarioPlantasAvaliacaoDoencasErrorMessage,
      },
      comentario_plantas_ataque_pragas: {
        value: comentarioPlantasAtaquePragasValue,
        errorMessage: comentarioPlantasAtaquePragasErrorMessage,
      },
    });

    async function fetchProdutores() {
      try {
        state.isLoading = true;
        const { data } = await services.produtor.getAll({
          type: state.currentClientType,
        });
        state.produtores = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function AlterarQtdPlantasFolhas(event, linha_nasc) {
      for (var i = 0; i < parseInt(event.target.value); i++) {
        var planta = new Object();
        planta.n_planta = i + 1;
        planta.n_folhas = 0;
        planta.n_linha = linha_nasc;

        switch (linha_nasc) {
          case 1:
            state.plantas_folhas_linha_1.push(planta);
            break;
          case 2:
            state.plantas_folhas_linha_2.push(planta);
            break;
          case 3:
            state.plantas_folhas_linha_3.push(planta);
            break;
          default:
            break;
        }
      }
    }
    function AlterarPlantasFolha(event, linha_nasc, n_planta) {
      console.log(state.plantas_folhas_linha_1);

      switch (linha_nasc) {
        case 1:
          state.plantas_folhas_linha_1[n_planta - 1].n_folhas =
            event.target.value;
          break;
        case 2:
          state.plantas_folhas_linha_2[n_planta - 1].n_folhas =
            event.target.value;
          break;
        case 3:
          state.plantas_folhas_linha_3[n_planta - 1].n_folhas =
            event.target.value;
          break;
        default:
          break;
      }
    }

    function AlterarQtdPlantasAltura(event, linha_nasc) {
      for (var i = 0; i < parseInt(event.target.value); i++) {
        var planta = new Object();
        planta.n_planta = i + 1;
        planta.altura = 0;

        switch (linha_nasc) {
          case 1:
            state.plantas_altura_linha_1.push(planta);
            break;
          case 2:
            state.plantas_altura_linha_2.push(planta);
            break;
          case 3:
            state.plantas_altura_linha_3.push(planta);
            break;
          default:
            break;
        }
      }
    }
    function AlterarPlantasAltura(event, linha_nasc, n_planta) {
      switch (linha_nasc) {
        case 1:
          state.plantas_altura_linha_1[n_planta - 1].altura =
            event.target.value;
          break;
        case 2:
          state.plantas_altura_linha_2[n_planta - 1].altura =
            event.target.value;
          break;
        case 3:
          state.plantas_altura_linha_3[n_planta - 1].altura =
            event.target.value;
          break;
        default:
          break;
      }
    }

    function AlterarQtdAtaquePraga(event, linha_nasc) {
      for (var i = 0; i < parseInt(event.target.value); i++) {
        var planta = new Object();
        planta.n_planta = i + 1;
        planta.praga = "";

        switch (linha_nasc) {
          case 1:
            state.plantas_ataque_pragas_linha_1.push(planta);
            break;
          case 2:
            state.plantas_ataque_pragas_linha_2.push(planta);
            break;
          case 3:
            state.plantas_ataque_pragas_linha_3.push(planta);
            break;
          default:
            break;
        }
      }
    }
    function AlterarAtaquePraga(event, linha_nasc, n_planta) {
      switch (linha_nasc) {
        case 1:
          state.plantas_ataque_pragas_linha_1[n_planta - 1].praga =
            event.target.value;
          break;
        case 2:
          state.plantas_ataque_pragas_linha_2[n_planta - 1].praga =
            event.target.value;
          break;
        case 3:
          state.plantas_ataque_pragas_linha_3[n_planta - 1].praga =
            event.target.value;
          break;
        default:
          break;
      }
      console.log(state.plantas_ataque_pragas_linha_1);
    }

    function AlterarQtdAvaliacaoDoencas(event, linha_nasc) {
      for (var i = 0; i < parseInt(event.target.value); i++) {
        var planta = new Object();
        planta.n_planta = i + 1;
        planta.doenca = "";

        switch (linha_nasc) {
          case 1:
            state.plantas_avaliacao_doencas_linha_1.push(planta);
            break;
          case 2:
            state.plantas_avaliacao_doencas_linha_2.push(planta);
            break;
          case 3:
            state.plantas_avaliacao_doencas_linha_3.push(planta);
            break;
          default:
            break;
        }
      }
      console.log(state.plantas_avaliacao_doencas_linha_1);
    }
    function AlterarAvaliacaoDoencas(event, linha_nasc, n_planta) {
      switch (linha_nasc) {
        case 1:
          state.plantas_avaliacao_doencas_linha_1[n_planta - 1].doenca =
            event.target.value;
          break;
        case 2:
          state.plantas_avaliacao_doencas_linha_2[n_planta - 1].doenca =
            event.target.value;
          break;
        case 3:
          state.plantas_avaliacao_doencas_linha_3[n_planta - 1].doenca =
            event.target.value;
          break;
        default:
          break;
      }

      console.log(state.plantas_avaliacao_doencas_linha_1);
    }

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    function definirDataAtual() {
      state.data.value = dataAtualFormatada();
    }

    onMounted(() => {
      fetchProdutores(), definirDataAtual();
    });

    function dataAtualFormatada() {
      engenheiro_id = Cookie.get("_engenheiro_id");
      var data = new Date(),
        dia = data.getDate().toString(),
        diaF = dia.length == 1 ? "0" + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = mes.length == 1 ? "0" + mes : mes,
        anoF = data.getFullYear();
      return diaF + "/" + mesF + "/" + anoF;
    }

    async function handleSubmit() {
      try {
        state.isSaving = true;

        var retorno = await services.avaliacao_milho.save({
          id: state.codigo.value,
          engenheiro_id: engenheiro_id,
          data: state.data.value,
          produtor_id: state.produtor.value,
          comentario_plantas_folhas: state.comentario_plantas_folhas.value,
          comentario_plantas_altura: state.comentario_plantas_altura.value,
          comentario_plantas_avaliacao_doencas:
            state.comentario_plantas_avaliacao_doencas.value,
          comentario_plantas_ataque_pragas:
            state.comentario_plantas_ataque_pragas.value,
        });

        //Folhas Nascidas
        var array_folhas = {};

        if (state.plantas_folhas_linha_1.length > 0) {
          array_folhas.linha_1 = state.plantas_folhas_linha_1;
        }

        if (state.plantas_folhas_linha_2.length > 0) {
          array_folhas.linha_2 = state.plantas_folhas_linha_2;
        }

        if (state.plantas_folhas_linha_3.length > 0) {
          array_folhas.linha_3 = state.plantas_folhas_linha_3;
        }

        //Enviando Folhas
        var retorno_plantas_folhas = await services.avaliacao_milho.save_plantas_folhas(
          {
            folhas: array_folhas,
            avaliacao_id: retorno.data,
          }
        );
        if (!retorno_plantas_folhas) {
          console.log(retorno);
          return;
        }

        /* ----------------------------------------- */
        //Alturas da planta
        var array_plantas_altura = {};
        if (state.plantas_altura_linha_1.length > 0) {
          array_plantas_altura.linha_1 = state.plantas_altura_linha_1;
        }

        if (state.plantas_altura_linha_2.length > 0) {
          array_plantas_altura.linha_2 = state.plantas_altura_linha_2;
        }

        if (state.plantas_altura_linha_3.length > 0) {
          array_plantas_altura.linha_3 = state.plantas_altura_linha_3;
        }

        //Enviando Altura
        var retorno_altura = await services.avaliacao_milho.save_plantas_altura(
          {
            alturas: array_plantas_altura,
            avaliacao_id: retorno.data,
          }
        );
        if (!retorno_altura) {
          console.log(retorno);
          return;
        }
        /* ----------------------------------------- */
        //Ataque Praga
        var array_plantas_praga = {};
        if (state.plantas_ataque_pragas_linha_1.length > 0) {
          array_plantas_praga.linha_1 = state.plantas_ataque_pragas_linha_1;
        }

        if (state.plantas_ataque_pragas_linha_2.length > 0) {
          array_plantas_praga.linha_2 = state.plantas_ataque_pragas_linha_2;
        }

        if (state.plantas_ataque_pragas_linha_3.length > 0) {
          array_plantas_praga.linha_3 = state.plantas_ataque_pragas_linha_3;
        }

        //Enviando Ataque Praga
        var retorno_plantas_praga = await services.avaliacao_milho.save_plantas_pragas(
          {
            pragas: array_plantas_praga,
            avaliacao_id: retorno.data,
          }
        );
        if (!retorno_plantas_praga) {
          console.log(retorno);
          return;
        }
        /* ----------------------------------------- */
        //Avaliação Doença
        var array_plantas_doenca = {};
        if (state.plantas_avaliacao_doencas_linha_1.length > 0) {
          array_plantas_doenca.linha_1 =
            state.plantas_avaliacao_doencas_linha_1;
        }

        if (state.plantas_avaliacao_doencas_linha_2.length > 0) {
          array_plantas_doenca.linha_2 =
            state.plantas_avaliacao_doencas_linha_2;
        }

        if (state.plantas_avaliacao_doencas_linha_3.length > 0) {
          array_plantas_doenca.linha_3 =
            state.plantas_avaliacao_doencas_linha_3;
        }

        //Enviando Doença
        var retorno_plantas_doenca = await services.avaliacao_milho.save_plantas_doenca(
          {
            doencas: array_plantas_doenca,
            avaliacao_id: retorno.data,
          }
        );
        if (!retorno_plantas_doenca) {
          console.log(retorno);
          return;
        }

        router.push({ name: "AvaliacaoMilho" });

        state.isSaving = false;
      } catch (error) {
        console.log(error);
        state.isSaving = false;
        state.hasErrors = !!error;
        //  toast.error('Ocorreu um erro ao criar a conta')
      }
    }
    return {
      state,
      SelectProdutores,
      handleSubmit,
      QuantidadeLinhasFolhas,
      QuantidadeLinhasAvaliacaoDoencas,
      QuantidadeLinhasAtaquePragas,
      QuantidadeLinhasAlturaPlantas,
      AlterarQtdPlantasFolhas,
      AlterarPlantasFolha,
      AlterarQtdPlantasAltura,
      AlterarPlantasAltura,
      AlterarQtdAvaliacaoDoencas,
      AlterarAvaliacaoDoencas,
      AlterarQtdAtaquePraga,
      AlterarAtaquePraga,
      emit,
      cancel,
    };
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>