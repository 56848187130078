<template>
      <button class="btn btn-primary" @click="downloadPDF"><div style="float:left">Download PDF </div><img src="@/assets/loading.gif" style="width:20px; margin-left:10px" v-if="state.isLoading"/></button>
</template>

<script>
import jsPDF from 'jspdf'
import {  reactive  } from 'vue'
import services from '../services'
import 'jspdf-autotable'

export default {
    name: 'pfd',
    props: {
      relatorio: Object
    },
    setup(props) {

    var imgdata = "";
    function encodeImage(event){
       var img = event.target.files[0]

        var file = new FileReader()

        file.onloadend = function(){
            imgdata = file.result
            console.log(file.result)
        }
        file.readAsDataURL(img)
    //  state.fileMapa = event.target.files[0]
    }


    function createPdf(){
        var doc =  jsPDF()
        doc.addImage(imgdata,15,15)
        doc.save("output.pdf")
    }



      async function downloadPDF(){

        try {
          state.isLoading = true;
          const tessste = await services.relatorio.getByID({
            id:props.relatorio.id
          });
          gerarPDF(tessste); 
          state.isLoading = false;
        } catch (error) {
          alert('Erro ao criar Relatório')
        }
        state.isLoading = false;
      }
        


        function gerarPDF(tessste){
          
            (function(API){


            



            API.myText = function(txt, options, x, y) {
                options = options ||{};
                /* Use the options align property to specify desired text alignment
                * Param x will be ignored if desired text alignment is 'center'.
                * Usage of options can easily extend the function to apply different text 
                * styles and sizes 
                */
                if( options.align == "center" ){
                    // Get current font size
                    var fontSize = this.internal.getFontSize();

                    // Get page width
                    var pageWidth = this.internal.pageSize.width;

                    // Get the actual text's width
                    /* You multiply the unit width of your string by your font size and divide
                    * by the internal scale factor. The division is necessary
                    * for the case where you use units other than 'pt' in the constructor
                    * of jsPDF.
                    */ 
                    var txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;

                    // Calculate text's x coordinate
                    x = ( pageWidth - txtWidth ) / 2;
                }

                // Draw text at x,y
                this.text(txt,x,y);
            }
        })(jsPDF.API);
        //   var imagedata = new Blob([tessste.data.mapa.data])
            var pdf = jsPDF('p', 'px', 'a4');   
          //  var pageHeight = 842;
            //largura da página para usar abaixo
            const pageWidthMidle = pdf.internal.pageSize.getWidth() / 2;
           
          
            var y = 30;
            var x = 50;

            var img_logo_width = 60;
            var img_logo_height = 60;
            
            var posicao_central_logo = pageWidthMidle - (img_logo_width / 2)
            pdf.addImage(tessste.data.logo, 'JPEG', posicao_central_logo, y, img_logo_width, img_logo_height);

           pdf.setFontSize(12);


            y = y +img_logo_height + 20;
           pdf.myText("Relatório de Campo Detalhado – "+tessste.data.data.nome_pessoa,{align: "center"},20,y);

            y = y + 20;


           pdf.myText("Data: "+tessste.data.data.data+"(Hora: "+tessste.data.data.hora_inicial+" até "+tessste.data.data.hora_final+")",{align: "center"},20,y);

           y = y + 20;
           
           pdf.myText( "Eng. "+tessste.data.data.nome_engenheiro,{align: "center"},20,y);

            y = y + 30;

            var img_mapa_width = 300;
            var img_mapa_height = 190;
            
            var posicao_central = pageWidthMidle - (img_mapa_width / 2)

            pdf.addImage(tessste.data.mapa, 'JPEG', posicao_central, y, img_mapa_width, img_mapa_height);
            y =  y + img_mapa_height + 30;

            pdf.setFontSize(11);

            var altura = 15;

            pdf.setFont(undefined, 'bold');
            pdf.text('• PLANTA:',x, y );
            y = y + altura;

            
            pdf.text('1. Cultivar/híbrido:',x, y );
            pdf.setFont(undefined, 'normal');
            pdf.text(tessste.data.data.nome_cultivar+'.:',x+75, y );


            y = y + altura;
            pdf.setFont(undefined, 'bold');
            pdf.text('2. Plantas nascidas: ',x, y );
            pdf.setFont(undefined, 'normal');
            pdf.text(tessste.data.data.plantas_nascidas+' plantas por metro linear.:',x+80, y );

            y = y + altura;
            pdf.setFont(undefined, 'bold');
            pdf.text('3. Estádio das plantas: ',x, y);
            pdf.setFont(undefined, 'normal');
            pdf.text(tessste.data.data.estadio_plantas,x+92, y);


            y = y + altura;
            pdf.setFont(undefined, 'bold');
            pdf.text('4. Porte médio (cm): ',x, y );
            pdf.setFont(undefined, 'normal');
            pdf.text(tessste.data.data.porte_medio+' centímetros:',x+83, y );

            y = y + altura;
            pdf.setFont(undefined, 'bold');
            pdf.text('5. Quantidade de nós produtivos (média): ',x, y );
            pdf.setFont(undefined, 'normal');
            pdf.text(tessste.data.data.qtd_media_nos_produtivos+' nós produtivos.:',x+165, y );
            y = y + altura;

            pdf.setFont(undefined, 'bold');
            pdf.text('6. Enraizamento (0 a 10): ',x, y );
            pdf.setFont(undefined, 'normal');
            pdf.text(tessste.data.data.enraizamento+'.',x+100, y );


            y = y + altura;
            pdf.setFont(undefined, 'bold');
            pdf.text('7. Nodulação (0 a 10): ',x, y );
            pdf.setFont(undefined, 'normal');
            pdf.text(+tessste.data.data.nodulacao+'.',x+89, y );


            y = y + altura;
            pdf.setFont(undefined, 'bold');
            pdf.text('8. Lavoura (0 a 10): ',x, y );
            pdf.setFont(undefined, 'normal');
            pdf.text(tessste.data.data.lavoura+'.',x+78, y );

            pdf.addPage();
            y = 0;
            y = y +50;

            var largura_imagem_plantacao = 110
            var altura_imagem_plantacao = 190

            for (let index = 0; index < tessste.data.imgsPlantacao.length; index++) {
              if(index == 0){
                pdf.addImage(tessste.data.imgsPlantacao[index], 'JPEG', 50, 50, largura_imagem_plantacao, altura_imagem_plantacao);
              }else if(index == 1){
                pdf.addImage(tessste.data.imgsPlantacao[index], 'JPEG', 170, 50, largura_imagem_plantacao, altura_imagem_plantacao);
              }else if(index == 2){
                pdf.addImage(tessste.data.imgsPlantacao[index], 'JPEG', 290, 50, largura_imagem_plantacao, altura_imagem_plantacao);
              }
            }

            y = y + altura_imagem_plantacao + 20;

            pdf.setFont(undefined, 'bold');
            pdf.text('• PRAGAS:',x, y );
            y = y + 20;

            pdf.text('1. Pontos avaliados',x, y );
            y = y + altura;
            pdf.text('2. Pragas x Infestação',x, y );
            y = y + altura;

            if(tessste.data.pragas.data.length == 0){
                pdf.text('Nenhuma praga encontrada',x, y );
            }

            
            for (var i = 0; i < tessste.data.pragas.data.length; i++) {

              var indice_infestacao = "";

              switch (tessste.data.pragas.data[i].indice_infestacao) {
                case "MA":
                  indice_infestacao = "Muito Alto";
                  break;
                case "A":
                  indice_infestacao = "Alto";
                  break;
                case "M":
                  indice_infestacao = "Medio";
                  break;
                case "B":
                  indice_infestacao = "Baixo";
                  break;
                case "MB":
                  indice_infestacao = "Muito Baixo";
                  break;
              }

              y = y + altura;
                pdf.text("• "+tessste.data.pragas.data[i].nome_praga+': '+indice_infestacao,x, y );
            }
            y = y + altura;

            pdf.text('3. Realizar controle? (sim ou não): '+tessste.data.data.controle_pragas+'.',x, y );
            y = y + altura;
            pdf.text('4. Sugestão de manejo:',x, y );

            y = y + altura;

            var inseticidas = [];
            for (let index_ince = 0; index_ince < tessste.data.inseticidas.data.length; index_ince++) {
              var inseticida = [];
              var nome = tessste.data.inseticidas.data[index_ince].nome
              var quantidade = tessste.data.inseticidas.data[index_ince].quantidade
              inseticida.push(nome)
              inseticida.push(quantidade)
              inseticidas.push(inseticida)
            }
            pdf.autoTable({
              head: [['Inseticida', 'Dosagem (kg/litro por alqueire)']],
              body:inseticidas,
              startY: y,
            })

            y = y + 100;

            var largura_imagem_praga = 110
            var altura_imagem_praga = 110
            pdf.setFont(undefined, 'normal');
            pdf.text('Imagens:',x, y );
            pdf.setFont(undefined, 'bold');

            y = y + 5;
            for (let index2 = 0; index2 < tessste.data.imgsPraga.length; index2++) {
              if(index2 == 0){
                pdf.addImage(tessste.data.imgsPraga[index2], 'JPEG', 50, y, largura_imagem_praga, altura_imagem_praga);
              }else if(index2 == 1){
                pdf.addImage(tessste.data.imgsPraga[index2], 'JPEG', 170, y, largura_imagem_praga, altura_imagem_praga);
              }else if(index2 == 2){
                pdf.addImage(tessste.data.imgsPraga[index2], 'JPEG', 290, y, largura_imagem_praga, altura_imagem_praga);
              }
            }


            pdf.addPage();
            
            y = 0;
            y = y +50;


            pdf.text('• DOENÇAS:',x, y );
            pdf.setFont(undefined, 'normal');

            y = y + altura;
            pdf.text('Baixeiros:',x, y );

            y = y + altura;

            if(tessste.data.doencas_baixeiro.data.length == 0){
              pdf.text('Não há.',x, y );
              y = y + altura;
            }

            for (var i2 = 0; i2 < tessste.data.doencas_baixeiro.data.length; i2++) {
              pdf.setFont(undefined, 'bold');
              pdf.text('Doença: ',x, y );
              pdf.setFont(undefined, 'normal');
              pdf.text(tessste.data.doencas_baixeiro.data[i2].nome_doenca,x +35, y);
              
              y = y+ altura;

              pdf.setFont(undefined, 'bold');
              pdf.text('Infestação: ',x, y );
              pdf.setFont(undefined, 'normal');
              pdf.text(tessste.data.doencas_baixeiro.data[i2].indice_infestacao,x +47, y);
              y = y + altura;
            }

            var largura_imagem_doenca_baixeiro = 110
            var altura_imagem_doenca_baixeiro = 110
            pdf.text('Imagens:',x, y );
            y = y + 5;
            for (let index3 = 0; index3 < tessste.data.imgsDoencaBaxeiro.length; index3++) {
              if(index3 == 0){
                pdf.addImage(tessste.data.imgsDoencaBaxeiro[index3], 'JPEG', 50, y, largura_imagem_doenca_baixeiro, altura_imagem_doenca_baixeiro);
              }else if(index3 == 1){
                pdf.addImage(tessste.data.imgsDoencaBaxeiro[index3], 'JPEG', 170, y, largura_imagem_doenca_baixeiro, altura_imagem_doenca_baixeiro);
              }else if(index3 == 2){
                pdf.addImage(tessste.data.imgsDoencaBaxeiro[index3], 'JPEG', 290, y, largura_imagem_doenca_baixeiro, altura_imagem_doenca_baixeiro);
              }
            }
            y = y + 130;


            y = y + altura;
            pdf.text('Folhas Novas:',x, y );

            y = y + altura;
            if(tessste.data.doencas_folhas_novas.data.length == 0){
              pdf.text('Não há:',x, y );
              y = y + altura;
            }
              
            for (var i3 = 0; i3 < tessste.data.doencas_folhas_novas.data.length; i3++) {

              var indice_infestacao_doenca = "";
              switch (tessste.data.doencas_folhas_novas.data[i3].indice_infestacao) {
                case "MA":
                  indice_infestacao_doenca = "Muito Alto";
                  break;
                case "A":
                  indice_infestacao_doenca = "Alto";
                  break;
                case "M":
                  indice_infestacao_doenca = "Medio";
                  break;
                case "B":
                  indice_infestacao_doenca = "Baixo";
                  break;
                case "MB":
                  indice_infestacao_doenca = "Muito Baixo";
                  break;
              }


              pdf.setFont(undefined, 'bold');
              pdf.text('Doença: ',x, y );
              pdf.setFont(undefined, 'normal');
              pdf.text(tessste.data.doencas_folhas_novas.data[i3].nome_doenca,x+35, y );
              
              y = y+ altura;

              pdf.setFont(undefined, 'bold');
              pdf.text('Infestação: ',x, y );
              pdf.setFont(undefined, 'normal');
              pdf.text(indice_infestacao_doenca,x+47, y );
              y = y + altura;
            }

            var fungicidas = [];
            for (let index_ince = 0; index_ince < tessste.data.fungicidas.data.length; index_ince++) {
              
              var fungicida = [];
              var nome_fungicida = tessste.data.fungicidas.data[index_ince].nome
              var quantidade_fungicida = tessste.data.fungicidas.data[index_ince].quantidade
              fungicida.push(nome_fungicida)
              fungicida.push(quantidade_fungicida)
              fungicidas.push(fungicida)
            }
            if(tessste.data.fungicidas.data.length > 0){
              pdf.autoTable({
                head: [['Fungicidas', 'Dosagem (kg/litro por alqueire)']],
                body:fungicidas,
                startY: y,
              })
            }



            pdf.save('info.pdf');
        }



        const state = reactive({
            isOpen: props.isOpened,
            isClosing: !props.isOpened,
            isLoading: false,
            relatorio_select: null
        })

        async function handleToggle () {
        state.isClosing = true

      //  await wait(250)
        state.isOpen = !state.isOpen
        state.isClosing = false
        
      }
      return {
        downloadPDF,
        state,
        handleToggle    ,
        encodeImage,
        createPdf
      }
    }
}
</script>