export default httpClient => ({
    get: async ({ id }) => {
        const response = await httpClient.post('/get-profile',{
        id
        })
        let errors = null

        if(!response.data){
        console.log('erroo')
        errors = {
            status: response.request.status,
            statusText: response.request.statusText
        }
        }
        return {
        data: response.data,
        errors
        }
    },
    update: async ({ id, nome, sobrenome,  telefone, telefone_2, endereco, endereco2, cep, government_gateway, utr_number,vat_number}) => {
        const response = await httpClient.post('/update-profile',{
            id,
          nome,
          sobrenome,
          telefone,
          telefone_2,
          endereco,
          endereco2,
          cep,
          government_gateway,
          utr_number,
          vat_number
        })
        let errors = null
    
        if(!response.data){
          console.log('erroo')
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
        }
        return {
          data: response.data,
          errors
        }
      }
})