const defaultPagination = {
  limit:5,
  offset: 0
}


export default httpClient => ({
  getAll: async ({type, limit, offset } = defaultPagination) => {
    const query = { limit, offset}
    if(type) {
      query.type = type
    }
    const response = await httpClient.get('/cultivares',{ 
    })
    
    return { data: response.data }

  },

  getById: async ({ id }) => {
    const response = await httpClient.get('/cultivar/'+id,{ 
    })
    return { data: response.data }

  },

  save: async ({ nome, descricao }) => {
    const response = await httpClient.post('/cultivar/adicionar',{
      nome,
      descricao
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  update: async ({ id, nome,descricao }) => {
    const response = await httpClient.post('/cultivar/alterar/'+id,{
      nome : nome,
      descricao : descricao
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  }
})