const defaultPagination = {
    limit:5,
    offset: 0
  }
  
  export default httpClient => ({
    getAll: async ({type, limit, offset } = defaultPagination) => {
      const query = { limit, offset}
      if(type) {
        query.type = type
      }
      const response = await httpClient.get('/engenheiros',{ 
      })
      
      return { data: response.data }
  
    },

    
  
    getById: async ({ id }) => {
      
      const response = await httpClient.get('/engenheiro/'+id,{ 
      })

      const logo = await httpClient.get('/engenheiro/logo/'+id,{responseType:'blob'})
      var base64logo = "";
      var readerLogo = new FileReader();
      readerLogo.readAsDataURL(logo.data); 
      readerLogo.onloadend = function() {
        base64logo = readerLogo.result;                
      }

      response.data.imagem2 = base64logo
      return { data: response.data }
  
    },
  
    save: async ( formData ) => {
      const response = await httpClient.post('/engenheiro/adicionar', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
      })
      if(!response.data || response.data.erro == true){
        return true;
      }
      return false;

    },
  
    update: async ( formData ) => {
      var id = formData.get('id');      
      const response = await httpClient.post('/engenheiro/alterar/' + id, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
      })
      let errors = null
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    delete: async ({ id }) => {
      const response = await httpClient.post('/engenheiro/deletar',{
        id
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    }
  
    
  })