const defaultPagination = {
  limit:5,
  offset: 0
}

export default httpClient => ({
  getAll: async ({type, limit, offset } = defaultPagination) => {
    const query = { limit, offset}
    if(type) {
      query.type = type
    }
    const response = await httpClient.get('/produtores',{ 
    })
    
    return { data: response.data }

  },

  getById: async ({ id }) => {
    const response = await httpClient.get('/produtor/'+id,{ 
    })
    return { data: response.data }

  },

  save: async ({ nome_pessoa, telefone_whats, telefone_fixo, nome_fazenda, endereco, complemento}) => {
    const response = await httpClient.post('/produtor/adicionar',{
      nome_pessoa,
      telefone_whats,
      telefone_fixo,
      nome_fazenda,
      endereco,
      complemento
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },

  update: async ({ id, nome_pessoa, telefone_whats, telefone_fixo, nome_fazenda, endereco, complemento}) => {
    const response = await httpClient.post('/produtor/alterar/'+id,{
      nome_pessoa : nome_pessoa,
      telefone_whats : telefone_whats,
      telefone_fixo : telefone_fixo,
      nome_fazenda : nome_fazenda,
      endereco : endereco,
      complemento : complemento
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  delete: async ({ id }) => {
    const response = await httpClient.post('/produtor/deletar',{
      id
    })
    let errors = null

    if(!response.data){
      console.log('erroo')
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  }

  
})