<template>
  <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>
    <div class="container-fluid mt--7">
    
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item"><router-link to="/relatorios-campo"  tag="primary">Relatorios Campo</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
              </ol>
            </nav>

          <form @submit.prevent="handleSubmit">  
            <div>
              <h2 class="mb-3">Novo Relatório Campo</h2>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Informações Principais
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Código</label>
                          <input type="text" class="form-control" v-model="state.codigo.value" readonly/>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Data</label>
                          <input type="text" class="form-control" v-model="state.data.value"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Hora Inicial</label>
                          <input type="text" class="form-control" v-model="state.hora_inicial.value" />
                          <span v-if="!!state.hora_inicial.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.hora_inicial.errorMessage }}
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Hora Final </label>
                          <input type="text" class="form-control" v-model="state.hora_final.value" />
                          <span v-if="!!state.hora_final.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.hora_final.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label>Engenheiro</label>
                            <input type="text" class="form-control" v-model="state.engenheiro_id"  disabled/>
                        </div>
                      </div>
                      <div class="col-lg-9">
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="state.engenheiro_nome" disabled style="margin-top:33px" />
                        </div>
                      </div>
                    </div>                
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Produtor</label>
                          <select class="form-control" v-model="state.produtor.value">
                            <select-produtores
                              v-for="produtor in state.produtores"
                              :key="produtor.id"
                              :produtor="produtor"
                            />
                          </select>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Mapa</label><br>
                          <input @change="newFileMapa" type="file" class="form-control">
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                          Desenvolvimento das Plantas
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Cultivar/híbrido</label>
                          <input type="text" class="form-control" v-model="state.nomeCultivar.value"  >

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Plantas Nascidas</label>
                          <input type="text" class="form-control" v-model="state.plantas_nascidas.value" />
                          <span v-if="!!state.plantas_nascidas.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.plantas_nascidas.errorMessage }}
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Estádio Plantas</label>
                          <input type="text" class="form-control" v-model="state.estadio_plantas.value" />
                          <span v-if="!!state.estadio_plantas.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.estadio_plantas.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>   
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Porte Médio</label>
                          <input type="text" class="form-control" v-model="state.porte_medio.value" />
                          <span v-if="!!state.porte_medio.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.porte_medio.errorMessage }}
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Quantidade de nós produtivos</label>
                          <input type="text" class="form-control" v-model="state.qtd_media_nos_produtivos.value" />
                          <span v-if="!!state.qtd_media_nos_produtivos.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.qtd_media_nos_produtivos.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Enraizamento</label>
                          <input type="text" class="form-control" v-model="state.enraizamento.value" />
                          <span v-if="!!state.enraizamento.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.enraizamento.errorMessage }}
                          </span>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Nodulação</label>
                          <input type="text" class="form-control" v-model="state.nodulacao.value" />
                          <span v-if="!!state.nodulacao.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.nodulacao.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div>  
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Lavoura</label>
                          <input type="text" class="form-control" v-model="state.lavoura.value" />
                          <span v-if="!!state.lavoura.errorMessage" class="block font-medium text-brand-danger">
                            {{ state.lavoura.errorMessage }}
                          </span>
                        </div>
                      </div>
                    </div> 
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Imagens da Lavoura</label>
                          <input type="file" id="files" name="files" multiple class="form-control" @change="newFilesPlantacao" > 
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col-sm-10">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''" >
                          Pragas
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0" id="div_pragas"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Quantidade Pragas</label>
                          <select @change="AlterarQtdPragas($event)" class="form-control">
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                          </select>
                          <relatorio-campo-pragas :qtd_pragas=state.qtd_pragas />
                        </div>
                      </div>
                    </div>
                    <QuantidadePragas @adicionarPraga="AdicionarPraga"  :qtdPragas="state.qtdPragas" :pragas="state.pragas" />
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Imagens Pragas</label>
                          <input type="file" id="files" name="files" multiple class="form-control" @change="newFilesPragas" > 
                        </div>
                      </div>
                    </div>     
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Realizar Controle</label>
                          <select @change="ControlePraga($event)" class="form-control">
                            <option value="NAO">NÃO</option>
                            <option value="SIM">SIM</option> 
                          </select>                          
                        </div>
                      </div>
                      <div class="col-lg-6" v-if="state.controlePraga == 'SIM'">
                        <div class="form-group">
                          <label>Quantidade</label>
                          <select @change="QtdInseticidas($event)" class="form-control" >
                            <option value="0">0</option>
                            <option value="1">1</option> 
                            <option value="2">2</option>
                            <option value="3">3</option> 
                            <option value="4">4</option>
                            <option value="5">5</option> 
                            <option value="6">6</option>
                            <option value="7">7</option> 
                            <option value="8">8</option> 
                            <option value="9">9</option> 
                            <option value="10">10</option> 
                          </select>                          
                        </div>
                      </div>
                    </div>
                    <QuantidadeInseticidas @adicionarInseticida="AdicionarInseticida" :qtdInseticidas="state.qtdInseticidas" :inseticidas="state.inseticidas" />
                  </div>
                </div>
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col-sm-10">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''" >
                          Doenças
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0" id="div_pragas"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Quantidade Doenças</label>
                          <select @change="AlterarQtdDoencas($event)" class="form-control">
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <QuantidadeDoencas @adicionarDoenca="AdicionarDoenca" :qtdDoencas="state.qtdDoencas" :doencas="state.doencas" />
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Imagens Doenças Baixeiro</label>
                          <input type="file" id="files" name="files" multiple class="form-control" @change="newFilesDoencasBaixeiro" > 
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Imagens Doenças Folhas Novas</label>
                          <input type="file" id="files" name="files" multiple class="form-control" @change="newFilesDoencasFolhasNovas" > 
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Realizar Controle</label>
                          <select @change="ControleDoenca($event)" class="form-control">
                            <option value="NAO">NÃO</option>
                            <option value="SIM">SIM</option> 
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6" v-if="state.controleDoenca == 'SIM'">
                        <div class="form-group">
                          <label>Quantidade</label>
                          <select @change="QtdFungicidas($event)" class="form-control" >
                            <option value="0">0</option>
                            <option value="1">1</option> 
                            <option value="2">2</option>
                            <option value="3">3</option> 
                            <option value="4">4</option>
                            <option value="5">5</option> 
                            <option value="6">6</option>
                            <option value="7">7</option> 
                            <option value="8">8</option> 
                            <option value="9">9</option> 
                            <option value="10">10</option> 
                          </select>                          
                        </div>
                      </div>
                    </div>
                    <QuantidadeFungicidas @adicionarFungicida="AdicionarFungicida" :qtdFungicidas="state.qtdFungicidas" :fungicidas="state.fungicidas" />

                  </div>
                </div>
                <!--
                <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
                  <div class="card-header border-0">
                    <div class="row align-items-center">
                      <div class="col-sm-10">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''" >
                          Quimicos
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card" :class="type === 'dark' ? 'bg-default' : ''">
                  <div
                        class="card-header border-0" id="div_pragas"
                        :class="type === 'dark' ? 'bg-transparent' : ''"
                      >
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Quantidade Químicos</label>
                          <select @change="AlterarQtdQuimicos($event)" class="form-control">
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                          </select>
                          <relatorio-campo-pragas :qtd_pragas=state.qtd_pragas />
                        </div>
                      </div>
                    </div>
                    <QuantidadeQuimicos @adicionarQuimico="AdicionarQuimico" :qtdQuimicos="state.qtdQuimicos" :quimicos="state.quimicos" />
                  </div>
                </div>
-->
            </div>
              </div>
            <button class="btn btn-primary my-4" id="submit-button" type="submit" :disabled="state.isSaving">Salvar<img src="@/assets/loading.gif" style="width:20px; margin-left:10px" v-if="state.isSaving"/></button>
            <button class="btn btn-warning my-4" id="submit-button" type="button" @click="cancel()">Cancelar</button>
          </form>
          </stats-card>
        </div>
        
      </div>
    </div>
</div>

</template>

<script>
import { reactive, onMounted } from 'vue'
import services from '../services'
import { useField } from "vee-validate";
import { validateEmptyAndLength3, validateEmpty } from '../utils/validators'
import useModal from '../hooks/useModal'
import { useRouter } from 'vue-router'
import SelectProdutores from '../components/ProdutoresSelect.vue';
import QuantidadePragas from "../components/QuantidadePragas.vue"
import QuantidadeDoencas from "../components/QuantidadeDoencas.vue"
//import QuantidadeQuimicos from "../components/QuantidadeQuimicos.vue"
import QuantidadeInseticidas from "../components/QuantidadeInseticidas.vue"
import QuantidadeFungicidas from "../components/QuantidadeFungicidas.vue"
import Cookie from 'js-cookie'

export default { 
  
  components: { SelectProdutores, QuantidadePragas,QuantidadeDoencas,QuantidadeInseticidas,QuantidadeFungicidas },
  data() {
        return {
          qtd:10
        }
    },
  methods:{

  },
  
  setup () {
    const router = useRouter()
    const modal = useModal()

    function handleCategory(){
      modal.open({
        component: 'ModalCategory'
      })
    }
  

    function cancel(){
      router.push({ name: 'RelatoriosCampo' })
    }

    const {
      value: codigoValue,
      errorMessage: codigoErrorMessage
    } = useField('codigo', validateEmptyAndLength3)

    const {
      value: dataValue,
      errorMessage: dataErrorMessage
    } = useField('data', validateEmptyAndLength3)

    const {
      value: horaInicialValue,
      errorMessage: horaInicialErrorMessage
    } = useField('hora_inicial', validateEmptyAndLength3)

    const {
      value: horaFinalValue,
      errorMessage: horaFinalErrorMessage
    } = useField('hora_final', validateEmptyAndLength3)

    const {
      value: produtorValue,
      errorMessage: produtorErrorMessage
    } = useField('produtor', validateEmpty)

    const {
      value: nomeCultivarValue,
      errorMessage: nomeCultivarErrorMessage
    } = useField('nome_cultivar', validateEmptyAndLength3)

    const {
      value: plantasNascidasValue,
      errorMessage: plantasNascidasErrorMessage
    } = useField('plantas_nascidas', validateEmptyAndLength3)

    const {
      value: estadioPlantasValue,
      errorMessage: estadioPlantasErrorMessage
    } = useField('estadio_plantas', validateEmptyAndLength3)

    const {
      value: porteMedioValue,
      errorMessage: porteMedioErrorMessage
    } = useField('porte_medio', validateEmptyAndLength3)

    const {
      value: qtdMediaProdutivosValue,
      errorMessage: qtdMediaProdutivosErrorMessage
    } = useField('qtd_media_nos_produtivos', validateEmptyAndLength3)

        const {
      value: enraizamentoValue,
      errorMessage: enraizamentoErrorMessage
    } = useField('enraizamento', validateEmptyAndLength3)

    const {
      value: nodulacaoValue,
      errorMessage: nodulacaoErrorMessage
    } = useField('nodulacao', validateEmptyAndLength3)

    const {
      value: lavouraValue,
      errorMessage: lavouraErrorMessage
    } = useField('lavoura', validateEmptyAndLength3)
 

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      isSaving: false,
      qtdPragas:0,
      qtdDoencas:0,
      qtdQuimicos:0,
      produtores: [],
      cultivares:[],
      pragas:[],
      doencas:[],
      quimicos:[],
      inseticidas:[],
      fungicidas:[],
      fileMapa:'',
      filesPlantacao: [],
      filesPragas:[],
      filesDoencasBaixeiro:[],
      filesDoencasFolhasNovas:[],    
      controlePraga: "NAO",
      qtdInseticidas:0,
      controleDoenca: "NAO",
      qtdFungicidas:0,
      engenheiro_id:0,
      engenheiro_nome: '',
      praga_1: {
        nome_praga:'',
        indice_infestacao:'',
        local:''
      },
      praga_2: {
        nome_praga:0,
        indice_infestacao:'',
        local:''
      },
      praga_3: {
        nome_praga:0,
        indice_infestacao:'',
        local:''
      },
      praga_4: {
        nome_praga:0,
        indice_infestacao:'',
        local:''
      },
      praga_5: {
        nome_praga:0,
        indice_infestacao:'',
        local:''
      },
      praga_6: {
        nome_praga:0,
        indice_infestacao:'',
        local:''
      },

      doenca_1: {
        nome_doenca:0,
        indice_infestacao:'',
        local:''
      },
      doenca_2: {
        nome_doenca:0,
        indice_infestacao:'',
        local:''
      },
      doenca_3: {
        nome_doenca:0,
        indice_infestacao:'',
        local:''
      },
      doenca_4: {
        nome_doenca:0,
        indice_infestacao:'',
        local:''
      },
      doenca_5: {
        nome_doenca:0,
        indice_infestacao:'',
        local:''
      },
      doenca_6: {
        nome_doenca:0,
        indice_infestacao:'',
        local:''
      },

      inseticida_1: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      inseticida_2: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      inseticida_3: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      inseticida_4: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      inseticida_5: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''     
     },      
      inseticida_6: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },

      fungicida_1: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      fungicida_2: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      fungicida_3: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      fungicida_4: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      fungicida_5: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''     
     },      
      fungicida_6: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },

      quimico_1: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      quimico_2: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      quimico_3: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      quimico_4: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      quimico_5: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''     
     },      
      quimico_6: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      quimico_f_1: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      quimico_f_2: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      quimico_f_3: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      quimico_f_4: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },
      quimico_f_5: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''     
     },      
      quimico_f_6: {
        quimico_id:0,
        quantidade:'',
        medida:'',
        espaco:''
      },

      codigo: {
        value: codigoValue,
        errorMessage: codigoErrorMessage
      },
      data: {
        value: dataValue,
        errorMessage: dataErrorMessage
      },
      hora_inicial: {
        value: horaInicialValue,
        errorMessage: horaInicialErrorMessage
      },
      hora_final: {
        value: horaFinalValue,
        errorMessage: horaFinalErrorMessage
      },
      produtor: {
        value: produtorValue,
        errorMessage: produtorErrorMessage
      },
      nomeCultivar: {
        value: nomeCultivarValue,
        errorMessage: nomeCultivarErrorMessage
      },
      plantas_nascidas: {
        value: plantasNascidasValue,
        errorMessage: plantasNascidasErrorMessage
      },
      estadio_plantas: {
        value: estadioPlantasValue,
        errorMessage: estadioPlantasErrorMessage
      },
      porte_medio: {
        value: porteMedioValue,
        errorMessage: porteMedioErrorMessage
      },
      qtd_media_nos_produtivos: {
        value: qtdMediaProdutivosValue,
        errorMessage: qtdMediaProdutivosErrorMessage
      },
      enraizamento: {
        value: enraizamentoValue,
        errorMessage:enraizamentoErrorMessage
      },
      nodulacao: {
        value: nodulacaoValue,
        errorMessage: nodulacaoErrorMessage
      },
      lavoura: {
        value: lavouraValue,
        errorMessage: lavouraErrorMessage
      }
    })

    onMounted(() => {
      fetchProdutores(),
      fetchInseticidas(),
      fetchFungicidas(),
      fetchQuimicos(),
      definirDataAtual()
    })

    function dataAtualFormatada(){
      state.engenheiro_id = Cookie.get('_engenheiro_id')
      state.engenheiro_nome = Cookie.get('_nome_engenheiro')
      
      var data = new Date(),
        dia  = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
      return diaF+"/"+mesF+"/"+anoF;
    }  


    function newFileMapa(event){
      console.log(event)
      state.fileMapa = event.target.files[0]
    }

    function definirDataAtual(){
      state.data.value = dataAtualFormatada()
    }



    function newFilesPlantacao(event){
      console.log(event)
      state.filesPlantacao = event.target.files
    }

    function newFilesPragas(event){
      console.log(event)
      state.filesPragas = event.target.files
    }

    function newFilesDoencasBaixeiro(event){
      console.log(event)
      state.filesDoencasBaixeiro = event.target.files
    }

    function newFilesDoencasFolhasNovas(event){
      console.log(event)
      state.filesDoencasFolhasNovas = event.target.files
    }
    
    function AlterarQtdPragas(event){
      state.qtdPragas = parseInt(event.target.value)
    //  console.log(state.qtdPragas)
    }
    function AlterarQtdDoencas(event){
      state.qtdDoencas = parseInt(event.target.value)
    //  console.log(state.qtdPragas)
    }
    function AlterarQtdQuimicos(event){
      state.qtdQuimicos = parseInt(event.target.value)
    //  console.log(state.qtdPragas)
    }

    function  AdicionarPraga(event,n_praga,campo){

      console.log("Evento: "+event.target.value+" n_praga: "+n_praga+" campo: "+campo);


      if(campo == "nome_praga"){
        switch (n_praga) {
          case 1:
            state.praga_1.nome_praga = event.target.value;
            break;
          case 2:
            state.praga_2.nome_praga = event.target.value;
            break;
          case 3:
            state.praga_3.nome_praga = event.target.value;
            break;
          case 4:
            state.praga_4.nome_praga = event.target.value;
            break;
          case 5:
            state.praga_5.nome_praga = event.target.value;
            break;
          case 6:
            state.praga_6.nome_praga = event.target.value;
            break;
        }
      }else{
        switch (n_praga) {
          case 1:
            state.praga_1.indice_infestacao = event.target.value;
            break;
          case 2:
            state.praga_2.indice_infestacao = event.target.value;
            break;
          case 3:
            state.praga_3.indice_infestacao = event.target.value;
            break;
          case 4:
            state.praga_4.indice_infestacao = event.target.value;
            break;
          case 5:
            state.praga_5.indice_infestacao = event.target.value;
            break;
          case 6:
            state.praga_6.indice_infestacao = event.target.value;
            break;
        }
      }
    }

    function  AdicionarDoenca(event,n_doenca,campo){
      if(campo == "nome_doenca"){
        switch (n_doenca) {
          case 1:
            state.doenca_1.nome_doenca = event.target.value;
            break;
          case 2:
            state.doenca_2.nome_doenca = event.target.value;
            break;
          case 3:
            state.doenca_3.nome_doenca = event.target.value;
            break;
          case 4:
            state.doenca_4.nome_doenca = event.target.value;
            break;
          case 5:
            state.doenca_5.nome_doenca = event.target.value;
            break;
          case 6:
            state.doenca_6.nome_doenca = event.target.value;
            break;
        }
      }else if(campo == "indice_infestacao"){
        switch (n_doenca) {
          case 1:
            state.doenca_1.indice_infestacao = event.target.value;
            break;
          case 2:
            state.doenca_2.indice_infestacao = event.target.value;
            break;
          case 3:
            state.doenca_3.indice_infestacao = event.target.value;
            break;
          case 4:
            state.doenca_4.indice_infestacao = event.target.value;
            break;
          case 5:
            state.doenca_5.indice_infestacao = event.target.value;
            break;
          case 6:
            state.doenca_6.indice_infestacao = event.target.value;
            break;
        }
      }else if(campo == "local"){
        switch (n_doenca) {
          case 1:
            state.doenca_1.local = event.target.value;
            break;
          case 2:
            state.doenca_2.local = event.target.value;
            break;
          case 3:
            state.doenca_3.local = event.target.value;
            break;
          case 4:
            state.doenca_4.local = event.target.value;
            break;
          case 5:
            state.doenca_5.local = event.target.value;
            break;
          case 6:
            state.doenca_6.local = event.target.value;
            break;
        }
      }
    }

    function  AdicionarInseticida(event,n_quimico,campo){
      if(campo == "quimico"){
        switch (n_quimico) {
          case 1:
            state.quimico_1.quimico_id = event.target.value;
            break;
          case 2:
            state.quimico_2.quimico_id = event.target.value;
            break;
          case 3:
            state.quimico_3.quimico_id = event.target.value;
            break;
          case 4:
            state.quimico_4.quimico_id = event.target.value;
            break;
          case 5:
            state.quimico_5.quimico_id = event.target.value;
            break;
          case 6:
            state.quimico_6.quimico_id = event.target.value;
            break;
        }
      }else if(campo == "quantidade"){
        switch (n_quimico) {
          case 1:
            state.quimico_1.quantidade = event.target.value;
            break;
          case 2:
            state.quimico_2.quantidade = event.target.value;
            break;
          case 3:
            state.quimico_3.quantidade = event.target.value;
            break;
          case 4:
            state.quimico_4.quantidade = event.target.value;
            break;
          case 5:
            state.quimico_5.quantidade = event.target.value;
            break;
          case 6:
            state.quimico_6.quantidade = event.target.value;
            break;
        }
      }  
      else if(campo == "medida"){
        switch (n_quimico) {
          case 1:
            state.quimico_1.medida = event.target.value;
            break;
          case 2:
            state.quimico_2.medida = event.target.value;
            break;
          case 3:
            state.quimico_3.medida = event.target.value;
            break;
          case 4:
            state.quimico_4.medida = event.target.value;
            break;
          case 5:
            state.quimico_5.medida = event.target.value;
            break;
          case 6:
            state.quimico_6.medida = event.target.value;
            break;
        }  
      }
      else if(campo == "espaco"){
        switch (n_quimico) {
          case 1:
            state.quimico_1.espaco = event.target.value;
            break;
          case 2:
            state.quimico_2.espaco = event.target.value;
            break;
          case 3:
            state.quimico_3.espaco = event.target.value;
            break;
          case 4:
            state.quimico_4.espaco = event.target.value;
            break;
          case 5:
            state.quimico_5.espaco = event.target.value;
            break;
          case 6:
            state.quimico_6.espaco = event.target.value;
            break;
        }  
      }

    }

      function  AdicionarFungicida(event,n_quimico,campo){

      if(campo == "fungicida"){
        console.log(campo)
        switch (n_quimico) {
          case 1:
            state.fungicida_1.quimico_id = event.target.value;
            break;
          case 2:
            state.fungicida_2.quimico_id = event.target.value;
            break;
          case 3:
            state.fungicida_3.quimico_id = event.target.value;
            break;
          case 4:
            state.fungicida_4.quimico_id = event.target.value;
            break;
          case 5:
            state.fungicida_5.quimico_id = event.target.value;
            break;
          case 6:
            state.fungicida_6.quimico_id = event.target.value;
            break;
        }
      }else if(campo == "quantidade"){
        switch (n_quimico) {
          case 1:
            state.fungicida_1.quantidade = event.target.value;
            break;
          case 2:
            state.fungicida_2.quantidade = event.target.value;
            break;
          case 3:
            state.fungicida_3.quantidade = event.target.value;
            break;
          case 4:
            state.fungicida_4.quantidade = event.target.value;
            break;
          case 5:
            state.fungicida_5.quantidade = event.target.value;
            break;
          case 6:
            state.fungicida_6.quantidade = event.target.value;
            break;
        }
      }  
      else if(campo == "medida"){
        switch (n_quimico) {
          case 1:
            state.fungicida_1.medida = event.target.value;
            break;
          case 2:
            state.fungicida_2.medida = event.target.value;
            break;
          case 3:
            state.fungicida_3.medida = event.target.value;
            break;
          case 4:
            state.fungicida_4.medida = event.target.value;
            break;
          case 5:
            state.fungicida_5.medida = event.target.value;
            break;
          case 6:
            state.fungicida_6.medida = event.target.value;
            break;
        }  
      }
      else if(campo == "espaco"){
        switch (n_quimico) {
          case 1:
            state.fungicida_1.espaco = event.target.value;
            break;
          case 2:
            state.fungicida_2.espaco = event.target.value;
            break;
          case 3:
            state.fungicida_3.espaco = event.target.value;
            break;
          case 4:
            state.fungicida_4.espaco = event.target.value;
            break;
          case 5:
            state.fungicida_5.espaco = event.target.value;
            break;
          case 6:
            state.fungicida_6.espaco = event.target.value;
            break;
        }  
      }

      console.log(state.quimico_1)
    }

    function  AdicionarQuimico(event,n_quimico,campo){
      if(campo == "quimico"){
        switch (n_quimico) {
          case 1:
            state.quimico_1.quimico_id = event.target.value;
            break;
          case 2:
            state.quimico_2.quimico_id = event.target.value;
            break;
          case 3:
            state.quimico_3.quimico_id = event.target.value;
            break;
          case 4:
            state.quimico_4.quimico_id = event.target.value;
            break;
          case 5:
            state.quimico_5.quimico_id = event.target.value;
            break;
          case 6:
            state.quimico_6.quimico_id = event.target.value;
            break;
        }
      }else if(campo == "quantidade"){
        switch (n_quimico) {
          case 1:
            state.quimico_1.quantidade = event.target.value;
            break;
          case 2:
            state.quimico_2.quantidade = event.target.value;
            break;
          case 3:
            state.quimico_3.quantidade = event.target.value;
            break;
          case 4:
            state.quimico_4.quantidade = event.target.value;
            break;
          case 5:
            state.quimico_5.quantidade = event.target.value;
            break;
          case 6:
            state.quimico_6.quantidade = event.target.value;
            break;
        }
      }  
      else if(campo == "medida"){
        switch (n_quimico) {
          case 1:
            state.quimico_1.medida = event.target.value;
            break;
          case 2:
            state.quimico_2.medida = event.target.value;
            break;
          case 3:
            state.quimico_3.medida = event.target.value;
            break;
          case 4:
            state.quimico_4.medida = event.target.value;
            break;
          case 5:
            state.quimico_5.medida = event.target.value;
            break;
          case 6:
            state.quimico_6.medida = event.target.value;
            break;
        }  
      }
      else if(campo == "espaco"){
        switch (n_quimico) {
          case 1:
            state.quimico_1.espaco = event.target.value;
            break;
          case 2:
            state.quimico_2.espaco = event.target.value;
            break;
          case 3:
            state.quimico_3.espaco = event.target.value;
            break;
          case 4:
            state.quimico_4.espaco = event.target.value;
            break;
          case 5:
            state.quimico_5.espaco = event.target.value;
            break;
          case 6:
            state.quimico_6.espaco = event.target.value;
            break;
        }  
      }
    }


    function QtdInseticidas(event){
      state.qtdInseticidas = parseInt(event.target.value);
    }

    function ControlePraga(event){
      state.controlePraga = event.target.value;
    }

    function QtdFungicidas(event){
      state.qtdFungicidas = parseInt(event.target.value);
    }

    function ControleDoenca(event){
      state.controleDoenca = event.target.value;
    }

    async function fetchProdutores () {
      try {
        state.isLoading = true
        const { data } = await services.produtor.getAll({
          type: state.currentClientType
        }
        )
        console.log(data)
        state.produtores = data
        state.isLoading = false
        
      } catch (error) { 
        handleErrors(error)
      }
    }

   /* async function fetchCultivares () {
      try {
        state.isLoading = true
        const { data } = await services.cultivar.getAll({
          type: state.currentClientType
        }
        )
        console.log(data)
        state.cultivares = data
        state.isLoading = false
        
      } catch (error) {
        handleErrors(error)
      }
    }*/

    async function fetchInseticidas () {
      try {
        state.isLoading = true
        const { data } = await services.quimico.getInseticidas({
          type: state.currentClientType
        }
        )
        console.log(data)
        state.inseticidas = data
        state.isLoading = false
        
      } catch (error) {
        handleErrors(error)
      }
    }

    async function fetchFungicidas () {
      try {
        state.isLoading = true
        const { data } = await services.quimico.getFungicidas({
          type: state.currentClientType
        }
        )
        console.log(data)
        state.fungicidas = data
        state.isLoading = false
        
      } catch (error) {
        handleErrors(error)
      }
    }

    async function fetchQuimicos () {
        try {
          state.isLoading = true
          const { data } = await services.quimico.getAll({
            type: state.currentClientType
          }
          )
          console.log(data)
          state.quimicos = data
          state.isLoading = false
          
        } catch (error) {
          handleErrors(error)
        }
      }

    function handleErrors (error) {
      console.log(error)
      state.hasError = !!error
    }

    async function handleSubmit() {
      try {
        state.isSaving = true
        state.isLoading = true
        if(state.codigo.value){
          const { errors } = await services.relatorio.update({
            id: state.codigo.value
        })
          if (!errors) {
         //   router.push({ name: 'relatorios' })
         //   state.isLoading = false
         //   return
          }
        }else{
          const formData = new FormData();
          formData.append("engenheiro_id",state.engenheiro_id);
          formData.append("data", state.data.value);
          formData.append("hora_inicial", state.hora_inicial.value);
          formData.append("hora_final", state.hora_final.value);
          formData.append("produtor_id", state.produtor.value);
          formData.append("img_mapa",state.fileMapa);
          formData.append("nome_cultivar", state.nomeCultivar.value);
          formData.append("plantas_nascidas", state.plantas_nascidas.value);
          formData.append("estadio_plantas", state.estadio_plantas.value);
          formData.append("porte_medio", state.porte_medio.value);
          formData.append("qtd_media_nos_produtivos", state.qtd_media_nos_produtivos.value);
          formData.append("enraizamento", state.enraizamento.value);
          formData.append("nodulacao", state.nodulacao.value);
          formData.append("lavoura", state.lavoura.value);

           var retorno = await services.relatorio.save(formData)
          if (retorno) {
         //   router.push({ name: 'relatorios' })
        //    state.isLoading = false
         //   return
          }

          //imagens Plantação
          for (var i4 = 0; i4 < state.filesPlantacao.length; i4++) {
            var formDataImgPlantacao = new FormData();
            formDataImgPlantacao.append("relatorio_id",retorno.data);
            formDataImgPlantacao.append("imagem",state.filesPlantacao[i4]);

            var retorno_img_plantacao = await services.relatorio.save_imgs_plantacao(formDataImgPlantacao)
            if (!retorno_img_plantacao) {
              ///  router.push({ name: 'relatorios' })
              //  state.isLoading = false
              return
            }
          }
          //imagens Pragas
          for (var i5 = 0; i5 < state.filesPragas.length; i5++) {
            var formDataImgPraga = new FormData();
            formDataImgPraga.append("relatorio_id",retorno.data);
            formDataImgPraga.append("imagem",state.filesPragas[i5]);

            var retorno_img_pragas = await services.relatorio.save_imgs_praga(formDataImgPraga)
            if (!retorno_img_pragas) {
              ///  router.push({ name: 'relatorios' })
              //  state.isLoading = false
              return
            }
          }

          //imagens Doenças Baixeiro
          for (var i6 = 0; i6 < state.filesDoencasBaixeiro.length; i6++) {
            var formDataImgDoencaBaixeiro = new FormData();
            formDataImgDoencaBaixeiro.append("relatorio_id",retorno.data);
            formDataImgDoencaBaixeiro.append("imagem",state.filesDoencasBaixeiro[i6]);

            var retorno_doencas_baixeiro= await services.relatorio.save_imgs_doenca_baixeiro(formDataImgDoencaBaixeiro)
            if (!retorno_doencas_baixeiro) {
              ///  router.push({ name: 'relatorios' })
              //  state.isLoading = false
              return
            }
          }


          //imagens Doenças Baixeiro
          for (var i7 = 0; i7 < state.filesDoencasFolhasNovas.length; i7++) {
            var formDataImgDoencaFolhasNovas = new FormData();
            formDataImgDoencaFolhasNovas.append("relatorio_id",retorno.data);
            formDataImgDoencaFolhasNovas.append("imagem",state.filesDoencasFolhasNovas[i7]);

            var retorno_doencas_folhas_novas = await services.relatorio.save_imgs_doenca_folha_nova(formDataImgDoencaFolhasNovas)
            if (!retorno_doencas_folhas_novas) {
              ///  router.push({ name: 'relatorios' })
              //  state.isLoading = false
              return
            }
          }

          //pragas
          for (var i = 1; i <= state.qtdPragas; i++) {
            switch (i) {
              case 1:
                console.log("retorno: "+state.codigo.value)
                var retorno_praga1 = await services.relatorio.save_pragas({
                  relatorio_id: retorno.data,
                  nome_praga: state.praga_1.nome_praga,
                  indice_infestacao: state.praga_1.indice_infestacao
                  })

                console.log(retorno_praga1);
                if (!retorno_praga1) {
                ///  router.push({ name: 'relatorios' })
                //  state.isLoading = false
                  return
                }
                break;
              case 2:
                var retorno_praga2 = await services.relatorio.save_pragas({
                  relatorio_id: retorno.data,
                  nome_praga: state.praga_2.nome_praga,
                  indice_infestacao: state.praga_2.indice_infestacao
                  })
                  if (!retorno_praga2) {
                 //   router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }
                break;
              case 3:
                var  retorno_praga3 = await services.relatorio.save_pragas({
                  relatorio_id: retorno.data,
                  nome_praga: state.praga_3.nome_praga,
                  indice_infestacao: state.praga_3.indice_infestacao
                  })  
                  
                  if (!retorno_praga3) {
                  //  router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }
                break;
              case 4:
                var retorno_praga4 = await services.relatorio.save_pragas({
                  relatorio_id: retorno.data,
                  nome_praga: state.praga_4.nome_praga,
                  indice_infestacao: state.praga_4.indice_infestacao
                  })                
                  if (!retorno_praga4) {
                  //  router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }                
                  break;
              case 5:
                var retorno_praga5 = await services.relatorio.save_pragas({
                  relatorio_id: retorno.data,
                  nome_praga: state.praga_5.nome_praga,
                  indice_infestacao: state.praga_5.indice_infestacao
                  })       
                  if (!retorno_praga5) {
                  //  router.push({ name: 'relatorios' })
                 //   state.isLoading = false
                    return
                  }                       
                  break;
              case 6:
                var retorno_praga6 = await services.relatorio.save_pragas({
                  relatorio_id: retorno.data,
                  nome_praga: state.praga_6.nome_praga,
                  indice_infestacao: state.praga_6.indice_infestacao
                  })         
                  if (!retorno_praga6) {
                  //  router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }               
                  break;
            }
          }


          for (var i2 = 1; i2 <= state.qtdDoencas; i2++) {
            switch (i2) {
              case 1:
                var retorno_doenca1 = await services.relatorio.save_doencas({
                  relatorio_id: retorno.data,
                  nome_doenca: state.doenca_1.nome_doenca,
                  indice_infestacao: state.doenca_1.indice_infestacao,
                  local: state.doenca_1.local
                  })

                if (!retorno_doenca1) {
                  return
                }
                break;
              case 2:
                var retorno_doenca2 = await services.relatorio.save_doencas({
                  relatorio_id: retorno.data,
                  nome_doenca: state.doenca_2.nome_doenca,
                  indice_infestacao: state.doenca_2.indice_infestacao,
                  local: state.doenca_2.local
                  })

                  if (!retorno_doenca2) {

                    return
                  }
                break;
              case 3:
                var  retorno_doenca3 = await services.relatorio.save_doencas({
                  relatorio_id: retorno.data,
                  nome_doenca: state.doenca_3.nome_doenca,
                  indice_infestacao: state.doenca_3.indice_infestacao,
                  local: state.doenca_3.local
                  })  
                  
                  if (!retorno_doenca3) {

                    return
                  }
                break;
              case 4:
                var retorno_doenca4 = await services.relatorio.save_doencas({
                  relatorio_id: retorno.data,
                  nome_doenca: state.doenca_4.nome_doenca,
                  indice_infestacao: state.doenca_4.indice_infestacao,
                  local: state.doenca_4.local
                  })                
                  if (!retorno_doenca4) {

                    return
                  }                
                  break;
              case 5:
                var retorno_doenca5 = await services.relatorio.save_doencas({
                  relatorio_id: retorno.data,
                  nome_doenca: state.doenca_5.nome_doenca,
                  indice_infestacao: state.doenca_5.indice_infestacao,
                  local: state.doenca_5.local
                  })       
                  if (!retorno_doenca5) {

                    return
                  }                       
                  break;
              case 6:
                var retorno_doenca6 = await services.relatorio.save_doencas({
                  relatorio_id: retorno.data,
                  nome_doenca: state.doenca_6.nome_doenca,
                  indice_infestacao: state.doenca_6.indice_infestacao,
                  local: state.doenca_6.local
                  })         
                  if (!retorno_doenca6) {

                    return
                  }               
                  break;
            }
          }

          for (var i3 = 1; i3 <= state.qtdInseticidas; i3++) {
            console.log('entrou no for de quimicos');
            switch (i3) {
              case 1:
                var retorno_inseticidas1 = await services.relatorio.save_inseticidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.quimico_1.quimico_id,
                  quantidade: state.quimico_1.quantidade,
                  medida: state.quimico_1.medida,
                  espaco: state.quimico_1.espaco
                  })

                if (!retorno_inseticidas1) {
                ///  router.push({ name: 'relatorios' })
                //  state.isLoading = false
                  return
                }
                break;
              case 2:
                var retorno_inseticidas2 = await services.relatorio.save_inseticidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.quimico_2.quimico_id,
                  quantidade: state.quimico_2.quantidade,
                  medida: state.quimico_2.medida,
                  espaco: state.quimico_2.espaco
                  })

                  if (!retorno_inseticidas2) {
                 //   router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }
                break;
              case 3:
                var  retorno_inseticidas3 = await services.relatorio.save_inseticidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.quimico_3.quimico_id,
                  quantidade: state.quimico_3.quantidade,
                  medida: state.quimico_3.medida,
                  espaco: state.quimico_3.espaco
                  })  
                  
                  if (!retorno_inseticidas3) {
                  //  router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }
                break;
              case 4:
                var retorno_inseticidas4 = await services.relatorio.save_inseticidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.quimico_4.quimico_id,
                  quantidade: state.quimico_4.quantidade,
                  medida: state.quimico_4.medida,
                  espaco: state.quimico_4.espaco
                  })                
                  if (!retorno_inseticidas4) {
                  //  router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }                
                  break;
              case 5:
                var retorno_inseticidas5 = await services.relatorio.save_inseticidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.quimico_5.quimico_id,
                  quantidade: state.quimico_5.quantidade,
                  medida: state.quimico_5.medida,
                  espaco: state.quimico_5.espaco
                  })       
                  if (!retorno_inseticidas5) {
                  //  router.push({ name: 'relatorios' })
                 //   state.isLoading = false
                    return
                  }                       
                  break;
              case 6:
                var retorno_inseticidas6 = await services.relatorio.save_inseticidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.quimico_6.quimico_id,
                  quantidade: state.quimico_6.quantidade,
                  medida: state.quimico_6.medida,
                  espaco: state.quimico_6.espaco
                  })         
                  if (!retorno_inseticidas6) {
                  //  router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }               
                  break;
            }
          }

          for (var i8 = 1; i8 <= state.qtdFungicidas; i8++) {
            console.log('entrou no for de fungicidas');
            switch (i8) {
              case 1:
                var retorno_fungicida1 = await services.relatorio.save_fungicidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.fungicida_1.quimico_id,
                  quantidade: state.fungicida_1.quantidade,
                  medida: state.fungicida_1.medida,
                  espaco: state.fungicida_1.espaco
                  })

                if (!retorno_fungicida1) {
                ///  router.push({ name: 'relatorios' })
                //  state.isLoading = false
                  return
                }
                break;
              case 2:
                var retorno_fungicida2 = await services.relatorio.save_fungicidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.fungicida_2.quimico_id,
                  quantidade: state.fungicida_2.quantidade,
                  medida: state.fungicida_2.medida,
                  espaco: state.fungicida_2.espaco
                  })

                  if (!retorno_fungicida2) {
                 //   router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }
                break;
              case 3:
                var  retorno_fungicida3 = await services.relatorio.save_fungicidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.fungicida_3.quimico_id,
                  quantidade: state.fungicida_3.quantidade,
                  medida: state.fungicida_3.medida,
                  espaco: state.fungicida_3.espaco
                  })  
                  
                  if (!retorno_fungicida3) {
                  //  router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }
                break;
              case 4:
                var retorno_fungicida4 = await services.relatorio.save_fungicidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.fungicida_4.quimico_id,
                  quantidade: state.fungicida_4.quantidade,
                  medida: state.fungicida_4.medida,
                  espaco: state.fungicida_4.espaco
                  })                
                  if (!retorno_fungicida4) {
                  //  router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }                
                  break;
              case 5:
                var retorno_fungicida5 = await services.relatorio.save_fungicidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.fungicida_5.quimico_id,
                  quantidade: state.fungicida_5.quantidade,
                  medida: state.fungicida_5.medida,
                  espaco: state.fungicida_5.espaco
                  })       
                  if (!retorno_fungicida5) {
                  //  router.push({ name: 'relatorios' })
                 //   state.isLoading = false
                    return
                  }                       
                  break;
              case 6:
                var retorno_fungicida6 = await services.relatorio.save_fungicidas({
                  relatorio_id: retorno.data,
                  quimico_id: state.fungicida_6.quimico_id,
                  quantidade: state.fungicida_6.quantidade,
                  medida: state.fungicida_6.medida,
                  espaco: state.fungicida_6.espaco
                  })         
                  if (!retorno_fungicida6) {
                  //  router.push({ name: 'relatorios' })
                  //  state.isLoading = false
                    return
                  }               
                  break;
            }
          }
          router.push({ name: 'RelatoriosCampo' })
        }

        state.isLoading = false
        state.isSaving = false
      } catch (error) {
        console.log(error)
        state.isSaving = false
        state.isLoading = false
        state.hasErrors = !!error
        alert('Erro ao salvar Relatório.')
      }
      
    }
  return {
    state,
    handleCategory,
    fetchProdutores,
    AlterarQtdPragas,
    AlterarQtdDoencas,
    AlterarQtdQuimicos,
    AdicionarDoenca,
    AdicionarInseticida,
    AdicionarFungicida,
    AdicionarQuimico,
    AdicionarPraga,
    handleSubmit,
    cancel,
    newFileMapa,
    newFilesPlantacao,
    newFilesPragas,
    newFilesDoencasBaixeiro,
    newFilesDoencasFolhasNovas,
    QtdInseticidas,
    ControlePraga,
    QtdFungicidas,
    ControleDoenca
  }
  }
}





</script>

<style scoped>
  textarea {
    resize: none;
  }
</style>