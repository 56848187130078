<template>
  <div>
    <div  v-for="n in qtdDoencas" :key="n">
      <h2>Doença {{n}}</h2>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label>Local da Doença</label>
            <select class="form-control" @change="$emit('adicionarDoenca',$event,n,'local')">
              <option value="">Selecione</option>
              <option value="BAXEIRO">Baxeiro</option>
              <option value="FOLHAS_NOVAS">Folhas Novas</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7">
            <div class="form-group">
              <label>Doença</label>
              <input type="text" class="form-control"  @blur="$emit('adicionarDoenca',$event,n,'nome_doenca')">
            </div>
        </div>
        <div class="col-lg-5">
          <div class="form-group">
            <label>Índice de Infestação</label>
            <select name="" id="" class="form-control" @change="$emit('adicionarDoenca',$event,n,'indice_infestacao')">
              <option value="">Selecione</option>
              <option value="MB">Muito Baixo</option>
              <option value="B">Baixo</option>
              <option value="M">Médio</option>
              <option value="A">Alto</option>
              <option value="MA">Muito Alto</option>
            </select>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  
  export default {
    name: "QuantidadeDoencas",
    emits:['adicionarDoenca'],
    props: {
      qtdDoencas: Number,
      doencas:Array
    }
  }
</script>
