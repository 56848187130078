<template>
    <tr @click="handleToggle">

         <td>
            {{ avaliacao.id }}
        </td>
        <td>
            {{ avaliacao.nome_pessoa }} / {{ avaliacao.nome_fazenda }}
        </td>
        <td>
            {{ avaliacao.data }}
        </td>
        <td class="text-right">
            <div>
                <PDFAvaliacao :avaliacao=avaliacao />
                <button class="btn btn-danger" @click="$emit('deletarAvaliacao',avaliacao.id)">Deletar</button>
            </div>
        </td>
    </tr>
</template>

<script>
import { reactive } from 'vue'
import PDFAvaliacao from '../PDFAvaliacao.vue'

export default {
    components:{
        PDFAvaliacao
    },
    props: {
        isOpened: { type: Boolean, default: false },
        avaliacao: { type: Object, required: true }
    },
    setup(_, { emit }) {
        const state = reactive({
        //    isOpen: props.isOpened,
        //    isClosing: !props.isOpened
        })

        async function handleToggle () {
        state.isClosing = true

      //  await wait(250)
        state.isOpen = !state.isOpen
        state.isClosing = false
        
      }
      return {
        state,
        handleToggle,
        emit 
      }
    },
    emits:['deletarRTC'],
}
</script>