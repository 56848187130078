export default httpClient => ({
    getAll: async () => {

      const response = await httpClient.get('/relatorios-campo')
      
      return { data: response.data }
  
    },

    getByID: async ({ id }) => {

      const response = await httpClient.get('/relatorio-campo/'+id)
      
      const mapa = await httpClient.get('/relatorio-campo/mapa/'+id,{responseType:'blob'})
      var base64data = "";
      var reader = new FileReader();
      reader.readAsDataURL(mapa.data); 
      reader.onloadend = function() {
        base64data = reader.result;                
      }

      const logo = await httpClient.get('/engenheiro/logo/1',{responseType:'blob'})
      var base64logo = "";
      var readerLogo = new FileReader();
      readerLogo.readAsDataURL(logo.data); 
      readerLogo.onloadend = function() {
        base64logo = readerLogo.result;                
      }

      //tras um array contendo os ids das imagens da plantação
      const imagens_plantacao = await httpClient.get('/relatorio-campo/imagens-plantacao/'+id)

      var array_imagens_plantacao = [];

      for (var i = 1; i <= imagens_plantacao.data.length; i++) {
        var imagem_plantacao = await httpClient.get('/relatorio-campo/imagem-plantacao/'+imagens_plantacao.data[i - 1].id,{responseType:'blob'})
        var base64imgPlantacao = "";
        reader = new FileReader();
        reader.readAsDataURL(imagem_plantacao.data); 
        reader.onloadend = function() {
          base64imgPlantacao = reader.result;   
          array_imagens_plantacao.push(base64imgPlantacao)
        }
      }

      var array_imagens_pragas = [];

      const imagens_praga = await httpClient.get('/relatorio-campo/imagens-praga/'+id)
      
      for (var i2 = 1; i2 <= imagens_praga.data.length; i2++) {
        var imagem_praga = await httpClient.get('/relatorio-campo/imagem-praga/'+imagens_praga.data[i2 - 1].id,{responseType:'blob'})
        var base64imgPraga = "";
        reader = new FileReader();
        reader.readAsDataURL(imagem_praga.data); 
        reader.onloadend = function() {
          base64imgPraga = reader.result;   
          array_imagens_pragas.push(base64imgPraga)
        }
      }

      var array_imagens_doencas_baxeiros = [];

      const imagens_doencas_baxeiros = await httpClient.get('/relatorio-campo/imagens-doenca-baxeiro/'+id)
      
      for (var i3 = 1; i3 <= imagens_doencas_baxeiros.data.length; i3++) {
        var imagem_doencas_baxeiros = await httpClient.get('/relatorio-campo/imagem-doenca/'+imagens_doencas_baxeiros.data[i3 - 1].id,{responseType:'blob'})
        var base64imgDoencaBaxeiros = "";
        reader = new FileReader();
        reader.readAsDataURL(imagem_doencas_baxeiros.data); 
        reader.onloadend = function() {
          base64imgDoencaBaxeiros = reader.result;   
          array_imagens_doencas_baxeiros.push(base64imgDoencaBaxeiros)
        }
      }

       console.log(array_imagens_doencas_baxeiros)


    //  const fileURL = window.URL.createObjectURL(new Blob([mapa.data]))




      const pragas = await httpClient.get('/relatorio-campo/pragas/'+id)

      const doencas_baixeiro = await httpClient.get('/relatorio-campo/doencas/'+id+'/BAXEIRO')
      const doencas_folhas_novas = await httpClient.get('/relatorio-campo/doencas/'+id+'/FOLHAS_NOVAS')

      const inseticidas = await httpClient.get('/relatorio-campo/inseticidas/'+id)
      const fungicidas = await httpClient.get('/relatorio-campo/fungicidas/'+id)

      response.mapa = base64data
      response.logo = base64logo
      
      response.imgsPlantacao = array_imagens_plantacao
      response.imgsPraga = array_imagens_pragas
      response.imgsDoencaBaxeiro = array_imagens_doencas_baxeiros
      response.pragas = pragas
      response.doencas_baixeiro = doencas_baixeiro
      response.doencas_folhas_novas = doencas_folhas_novas
      response.inseticidas = inseticidas
      response.fungicidas = fungicidas

      return { data: response }
    },
















    save: async ( formData ) => {
      const response = await httpClient.post('/relatorio-campo/adicionar',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
      }})

      
      console.log("Resposta direta: "+response.data)
      return {
        data: response.data
      }
    },

    save_imgs_plantacao: async ( formData ) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-imgs-plantacao',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
          
      }})
      
      console.log("Resposta direta: "+response.data)
      return {
        data: response.data
      }
    },

    save_imgs_praga: async ( formData ) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-imgs-praga',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
      }})
      console.log("Resposta direta: "+response.data)
      return {
        data: response.data
      }
    },
    
    save_imgs_doenca_baixeiro: async ( formData ) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-imgs-doenca-baixeiro',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
      }})
      console.log("Resposta direta: "+response.data)
      return {
        data: response.data
      }
    },

    save_imgs_doenca_folha_nova: async ( formData ) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-imgs-doenca-folha-nova',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
      }})
      console.log("Resposta direta: "+response.data)
      return {
        data: response.data
      }
    },

    save_pragas: async ({ relatorio_id, nome_praga, indice_infestacao}) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-praga',{
        relatorio_id,
        nome_praga,
        indice_infestacao
      })
      let errors = null
  
      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_doencas: async ({ relatorio_id, nome_doenca, indice_infestacao, local}) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-doenca',{
        relatorio_id,
        nome_doenca,
        indice_infestacao,
        local
      })
      let errors = null
  
      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_inseticidas: async ({ relatorio_id, quimico_id, quantidade, medida, espaco}) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-inseticida',{
        relatorio_id,
        quimico_id,
        quantidade,
        medida,
        espaco
      })
      let errors = null
  
      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_fungicidas: async ({ relatorio_id, quimico_id, quantidade, medida, espaco}) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-fungicida',{
        relatorio_id,
        quimico_id,
        quantidade,
        medida,
        espaco
      })
      let errors = null
  
      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
 

    update: async ({ id, nome, ingrediente_ativo_1, ingrediente_ativo_2, ingrediente_ativo_3 }) => {
      const response = await httpClient.post('/relatorio-campo/alterar/'+id,{
        nome : nome,
        ingrediente_ativo_1,
        ingrediente_ativo_2,
        ingrediente_ativo_3
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    delete: async ({ id }) => {
      const response = await httpClient.post('/relatorio-campo/deletar',{
        id
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    }
})