<template>
    <tr @click="handleToggle">

         <td>
            {{ relatorio.id }}
        </td>
        <td>
            {{ relatorio.nome_produtor }} / {{ relatorio.nome_fazenda }}
        </td>
        <td>
            {{ relatorio.data }}
        </td>
        <td >
            <div class="text-right" >
                <PDFRelatorioPlantio :relatorio=relatorio />
                <button class="btn btn-danger" @click="$emit('deletarRelatorio',relatorio.id)">Deletar</button>
            </div>
        </td>
    </tr>
</template>

<script>
import { reactive } from 'vue'
import PDFRelatorioPlantio from '../PDFRelatorioPlantio.vue'
export default {
    components:{
    },
    props: {
        isOpened: { type: Boolean, default: false },
        relatorio: { type: Object, required: true }
    },
    setup(_, { emit }) {
        const state = reactive({
        //    isOpen: props.isOpened,
        //    isClosing: !props.isOpened
        })

        async function handleToggle () {
        state.isClosing = true

      //  await wait(250)
        state.isOpen = !state.isOpen
        state.isClosing = false
        
      }
      return {
        state,
        handleToggle,
        PDFRelatorioPlantio,
        emit 
      }
    },
    emits:['deletarRelatorio'],
}
</script>