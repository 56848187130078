<template>
  <div>
    <div  v-for="n in qtdInseticidas" :key="n">
      <h2>Inseticida {{n}}</h2>
      <div class="row">
        <div class="col-lg-4">
            <div class="form-group">
              <label>Quimico</label>
              <select class="form-control" @change="$emit('adicionarInseticida',$event,n,'quimico')">
                <option value="">Escolha</option>
                <select-inseticidas
                v-for="inseticida in inseticidas"
                :key="inseticida.id"
                :inseticida="inseticida"
                />
              </select>
            </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Quantidade</label> 
            <input type="text" class="form-control" @blur="$emit('adicionarInseticida',$event,n,'quantidade')">
          </div>
        </div>
        <div class="col-lg-2">
          <div class="form-group">
            <label>Medida</label>
            <select name="" id="" class="form-control" @change="$emit('adicionarInseticida',$event,n,'medida')">
              <option value="">Escolha</option>
              <option value="KG">Quilos</option>
              <option value="L">Litros</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Por</label>
            <select name="" id="" class="form-control" @change="$emit('adicionarInseticida',$event,n,'espaco')">
              <option value="">Escolha</option>
              <option value="ALQUEIRE">Alqueire</option>
              <option value="HECTARE">Hectare</option>
            </select>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import SelectInseticidas from "./InseticidaSelect.vue"


  export default {
    name: "QuantidadeInseticidas",
    components: { SelectInseticidas },
    emits:['adicionarInseticida'],
    props: {
      qtdInseticidas: Number,
      inseticidas: Array
    }
  }
</script>
