<template>
    <tr @click="handleToggle">
        <td>
            {{ relatorio.id }}
        </td>
        <th scope="row">
            <div class="media align-items-center">
                <div class="media-body">
                <span class="name mb-0 text-sm">{{ relatorio.nome_pessoa }}</span>
                </div>
            </div>
            </th>
        <td>
            {{ relatorio.nome_fazenda }}
        </td>
        <td>{{ relatorio.data }}</td>


        <td >
          <div class="text-right">
            <PDFRelatorio :relatorio=relatorio />
            <button class="btn btn-danger" @click="$emit('deletarRelatorio',relatorio.id)">Deletar</button>
          </div>
        </td>
    </tr>
</template>

<script>
import { reactive } from 'vue'
import PDFRelatorio from '../PDFRelatorio.vue'


export default {
    components:{
      PDFRelatorio
    },
    props: {
        isOpened: { type: Boolean, default: false },
        relatorio: { type: Object, required: true }
    },
    setup(props) {

      const state = reactive({
        isOpen: props.isOpened,
        isClosing: !props.isOpened
      })

      async function handleToggle () {
        state.isClosing = true

      //  await wait(250)
        state.isOpen = !state.isOpen
        state.isClosing = false
        
      }
      return {
        state,
        handleToggle    
      }
  }  
}
</script>
