export default httpClient => ({

  register: async ({ name, email, password }) => {
    const response = await httpClient.post('/auth/register', {
      name,
      email,
      password
    })
    let errors = null

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText
      }
    }
    return {
      data: response.data,
      errors
    }
  },
  login: async ({ email, password }) => {
    const response = await httpClient.post('/login', {
      email,
      password
    }).catch(() => {
      var errors= "Usuário/Senha incorretos"

      return {
        errors
      }
    })
    console.log(response.errors)
    var errors = response.errors
    return {
      data: response.data,
      errors
    }
  }
})
