<template>
      <button class="btn btn-primary" @click="downloadPDF"><div style="float:left">Download PDF </div><img src="@/assets/loading.gif" style="width:20px; margin-left:10px" v-if="state.isLoading"/></button>
</template>

<script>
import jsPDF from 'jspdf'
import {  reactive  } from 'vue'
import services from '../services'
import 'jspdf-autotable'

export default {
    name: 'pfd',
    props: {
      avaliacao: Object
    },
    setup(props) {



    function createPdf(){
        var doc =  jsPDF()
        doc.save("output.pdf")
    }



      async function downloadPDF(){
        try{
          state.isLoading = true
          const response = await services.avaliacao_milho.getById({
            id:props.avaliacao.id
          });
          console.log(response)

          //Folhas
          var folhas_linha_1_1 = new Array(18)
          var folhas_linha_1_2 = new Array();

          var folhas_linha_2_1 = new Array(18)
          var folhas_linha_2_2 = new Array();

          var folhas_linha_3_1 = new Array(18)
          var folhas_linha_3_2 = new Array();

          //Alturas

          var altura_linha_1_1 = new Array(18)
          var altura_linha_1_2 = new Array();

          var altura_linha_2_1 = new Array(18)
          var altura_linha_2_2 = new Array();

          var altura_linha_3_1 = new Array(18)
          var altura_linha_3_2 = new Array();

          //Pragas

          var praga_linha_1_1 = new Array(18)
          var praga_linha_1_2 = new Array();

          var praga_linha_2_1 = new Array(18)
          var praga_linha_2_2 = new Array();

          var praga_linha_3_1 = new Array(18)
          var praga_linha_3_2 = new Array();

          //Doencas

          var doenca_linha_1_1 = new Array(18)
          var doenca_linha_1_2 = new Array();

          var doenca_linha_2_1 = new Array(18)
          var doenca_linha_2_2 = new Array();

          var doenca_linha_3_1 = new Array(18)
          var doenca_linha_3_2 = new Array();

          //Folhas Linha 1
          for (let index = 0; index < folhas_linha_1_1.length; index++) {
            var folha_obj_1_1 = new Object;
            folha_obj_1_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.folhas_linha_1.length; index2++) {
              if(response.data.folhas_linha_1[index2].n_planta == index + 1){
                folha_obj_1_1.n_folhas = response.data.folhas_linha_1[index2].n_folhas;
              }
            }
            folhas_linha_1_1[index] = folha_obj_1_1;
          }

          for (let index = 0; index < 18; index++) {
            var folha_obj_1_2 = new Object;
            folha_obj_1_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.folhas_linha_1.length; index2++) {
              if(response.data.folhas_linha_1[index2].n_planta == index + 1){
                folha_obj_1_2.n_folhas = response.data.folhas_linha_1[index2].n_folhas;
              }
            }
            folhas_linha_1_2[index] = folha_obj_1_2;
          }

          //Folhas Linha 2
          for (let index = 0; index < folhas_linha_2_1.length; index++) {
            var folha_obj_2_1 = new Object;
            folha_obj_2_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.folhas_linha_2.length; index2++) {
              if(response.data.folhas_linha_2[index2].n_planta == index + 1){
                folha_obj_2_1.n_folhas = response.data.folhas_linha_2[index2].n_folhas;
              }
            }
            folhas_linha_2_1[index] = folha_obj_2_1;
          }

          for (let index = 0; index < 18; index++) {
            var folha_obj_2_2 = new Object;
            folha_obj_2_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.folhas_linha_2.length; index2++) {
              if(response.data.folhas_linha_2[index2].n_planta == index + 1){
                folha_obj_2_2.n_folhas = response.data.folhas_linha_2[index2].distancia;
              }
            }
            folhas_linha_2_2[index] = folha_obj_2_2;
          }

          //Folhas Linha 3
          for (let index = 0; index < folhas_linha_3_1.length; index++) {
            var folha_obj_3_1 = new Object;
            folha_obj_3_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.folhas_linha_3.length; index2++) {
              if(response.data.folhas_linha_3[index2].n_planta == index + 1){
                folha_obj_3_1.n_folhas = response.data.folhas_linha_3[index2].n_folhas;
              }
            }
            folhas_linha_3_1[index] = folha_obj_2_1;
          }

          for (let index = 0; index < 18; index++) {
            var folha_obj_3_2 = new Object;
            folha_obj_3_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.folhas_linha_3.length; index2++) {
              if(response.data.folhas_linha_3[index2].n_planta == index + 1){
                folha_obj_3_2.n_folhas = response.data.folhas_linha_3[index2].n_folhas;
              }
            }
            folhas_linha_3_2[index] = folha_obj_3_2;
          }

          //Altura Linha 1
          for (let index = 0; index < altura_linha_1_1.length; index++) {
            var altura_obj_1_1 = new Object;
            altura_obj_1_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.altura_linha_1.length; index2++) {
              if(response.data.altura_linha_1[index2].n_planta == index + 1){
                altura_obj_1_1.altura = response.data.altura_linha_1[index2].altura;
              }
            }
            altura_linha_1_1[index] = altura_obj_1_1;
          }

          for (let index = 0; index < 18; index++) {
            var altura_obj_1_2 = new Object;
            altura_obj_1_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.altura_linha_1.length; index2++) {
              if(response.data.altura_linha_1[index2].n_planta == index + 1){
                altura_obj_1_2.altura = response.data.altura_linha_1[index2].altura;
              }
            }
            altura_linha_1_2[index] = altura_obj_1_2;
          }


          //Altura Linha 2
          for (let index = 0; index < altura_linha_2_1.length; index++) {
            var altura_obj_2_1 = new Object;
            altura_obj_2_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.altura_linha_2.length; index2++) {
              if(response.data.altura_linha_2[index2].n_planta == index + 1){
                altura_obj_2_1.altura = response.data.altura_linha_2[index2].altura;
              }
            }
            altura_linha_2_1[index] = altura_obj_2_1;
          }

          for (let index = 0; index < 18; index++) {
            var altura_obj_2_2 = new Object;
            altura_obj_2_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.altura_linha_2.length; index2++) {
              if(response.data.altura_linha_2[index2].n_planta == index + 1){
                altura_obj_2_2.altura = response.data.altura_linha_2[index2].altura;
              }
            }
            altura_linha_2_2[index] = altura_obj_2_2;
          }

          //Altura Linha 3
          for (let index = 0; index < altura_linha_3_1.length; index++) {
            var altura_obj_3_1 = new Object;
            altura_obj_3_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.altura_linha_3.length; index2++) {
              if(response.data.altura_linha_3[index2].n_planta == index + 1){
                altura_obj_3_1.altura = response.data.altura_linha_3[index2].altura;
              }
            }
            altura_linha_3_1[index] = altura_obj_3_1;
          }

          for (let index = 0; index < 18; index++) {
            var altura_obj_3_2 = new Object;
            altura_obj_3_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.altura_linha_3.length; index2++) {
              if(response.data.altura_linha_3[index2].n_planta == index + 1){
                altura_obj_3_2.altura = response.data.altura_linha_3[index2].altura;
              }
            }
            altura_linha_3_2[index] = altura_obj_3_2;
          }

          //Praga Linha 1
          for (let index = 0; index < praga_linha_1_1.length; index++) {
            var praga_obj_1_1 = new Object;
            praga_obj_1_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.praga_linha_1.length; index2++) {
              if(response.data.praga_linha_1[index2].n_planta == index + 1){
                praga_obj_1_1.praga = response.data.praga_linha_1[index2].praga;
              }
            }
            praga_linha_1_1[index] = praga_obj_1_1;
          }

          for (let index = 0; index < 18; index++) {
            var praga_obj_1_2 = new Object;
            praga_obj_1_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.praga_linha_2.length; index2++) {
              if(response.data.praga_linha_1[index2].n_planta == index + 1){
                praga_obj_1_2.praga = response.data.praga_linha_1[index2].praga;
              }
            }
            praga_linha_1_2[index] = praga_obj_1_2;
          }

          //Praga Linha 2
          for (let index = 0; index < praga_linha_2_1.length; index++) {
            var praga_obj_2_1 = new Object;
            praga_obj_2_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.praga_linha_2.length; index2++) {
              if(response.data.praga_linha_2[index2].n_planta == index + 1){
                praga_obj_2_1.praga = response.data.praga_linha_2[index2].praga;
              }
            }
            praga_linha_2_1[index] = praga_obj_2_1;
          }

          for (let index = 0; index < 18; index++) {
            var praga_obj_2_2 = new Object;
            praga_obj_2_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.praga_linha_2.length; index2++) {
              if(response.data.praga_linha_2[index2].n_planta == index + 1){
                praga_obj_2_2.praga = response.data.praga_linha_2[index2].praga;
              }
            }
            praga_linha_2_2[index] = praga_obj_2_2;
          }

          //Praga Linha 3
          for (let index = 0; index < praga_linha_3_1.length; index++) {
            var praga_obj_3_1 = new Object;
            praga_obj_3_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.praga_linha_3.length; index2++) {
              if(response.data.praga_linha_3[index2].n_planta == index + 1){
                praga_obj_3_1.praga = response.data.praga_linha_3[index2].praga;
              }
            }
            praga_linha_3_1[index] = praga_obj_3_1;
          }

          for (let index = 0; index < 18; index++) {
            var praga_obj_3_2 = new Object;
            praga_obj_3_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.praga_linha_3.length; index2++) {
              if(response.data.praga_linha_3[index2].n_planta == index + 1){
                praga_obj_3_2.praga = response.data.praga_linha_3[index2].praga;
              }
            }
            praga_linha_3_2[index] = praga_obj_3_2;
          }

          //Doença Linha 1
          for (let index = 0; index < doenca_linha_1_1.length; index++) {
            var doenca_obj_1_1 = new Object;
            doenca_obj_1_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.doenca_linha_1.length; index2++) {
              if(response.data.doenca_linha_1[index2].n_planta == index + 1){
                doenca_obj_1_1.doenca = response.data.doenca_linha_1[index2].doenca;
              }
            }
            doenca_linha_1_1[index] = doenca_obj_1_1;
          }

          for (let index = 0; index < 18; index++) {
            var doenca_obj_1_2 = new Object;
            doenca_obj_1_2.doenca = index + 1;
            for (let index2 = 0; index2 < response.data.doenca_linha_2.length; index2++) {
              if(response.data.doenca_linha_1[index2].doenca == index + 1){
                doenca_obj_1_2.doenca = response.data.doenca_linha_1[index2].doenca;
              }
            }
            doenca_linha_1_2[index] = doenca_obj_1_2;
          }

          //Doença Linha 2
          for (let index = 0; index < doenca_linha_2_1.length; index++) {
            var doenca_obj_2_1 = new Object;
            doenca_obj_2_1.doenca = index + 1;
            for (let index2 = 0; index2 < response.data.doenca_linha_2.length; index2++) {
              if(response.data.doenca_linha_2[index2].doenca == index + 1){
                doenca_obj_2_1.doenca = response.data.doenca_linha_2[index2].doenca;
              }
            }
            doenca_linha_2_1[index] = doenca_obj_1_1;
          }

          for (let index = 0; index < 18; index++) {
            var doenca_obj_2_2 = new Object;
            doenca_obj_2_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.doenca_linha_2.length; index2++) {
              if(response.data.doenca_linha_2[index2].n_planta == index + 1){
                doenca_obj_2_2.doenca = response.data.doenca_linha_2[index2].doenca;
              }
            }
            doenca_linha_2_2[index] = doenca_obj_2_2;
          }


          //Doença Linha 3
          for (let index = 0; index < doenca_linha_3_1.length; index++) {
            var doenca_obj_3_1 = new Object;
            doenca_obj_3_1.doenca = index + 1;
            for (let index2 = 0; index2 < response.data.doenca_linha_3.length; index2++) {
              if(response.data.doenca_linha_3[index2].doenca == index + 1){
                doenca_obj_3_1.doenca = response.data.doenca_linha_3[index2].doenca;
              }
            }
            doenca_linha_3_1[index] = doenca_obj_3_1;
          }

          for (let index = 0; index < 18; index++) {
            var doenca_obj_3_2 = new Object;
            doenca_obj_3_2.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.doenca_linha_3.length; index2++) {
              if(response.data.doenca_linha_3[index2].n_planta == index + 1){
                doenca_obj_3_2.doenca = response.data.doenca_linha_3[index2].doenca;
              }
            }
            doenca_linha_3_2[index] = doenca_obj_3_2;
          }

          gerarPDF(response, folhas_linha_1_1, folhas_linha_1_2,folhas_linha_2_1, folhas_linha_2_2,folhas_linha_3_1, folhas_linha_3_2, 
          altura_linha_1_1, altura_linha_1_2,altura_linha_2_1, altura_linha_2_2,altura_linha_3_1, altura_linha_3_2,
          praga_linha_1_1, praga_linha_1_2,praga_linha_2_1, praga_linha_2_2,praga_linha_3_1, praga_linha_3_2,
          doenca_linha_1_1, doenca_linha_1_2,doenca_linha_2_1, doenca_linha_2_2,doenca_linha_3_1, doenca_linha_3_2);
          state.isLoading = false;
        }catch(error){
          console.log(error)
        }
        state.isLoading = false;
      }
      
      function gerarPDF(response, folhas_linha_1_1, folhas_linha_1_2,folhas_linha_2_1, folhas_linha_2_2,folhas_linha_3_1, folhas_linha_3_2, 
      altura_linha_1_1, altura_linha_1_2,altura_linha_2_1, altura_linha_2_2,altura_linha_3_1, altura_linha_3_2, 
      praga_linha_1_1, praga_linha_1_2,praga_linha_2_1, praga_linha_2_2,praga_linha_3_1, praga_linha_3_2,
      doenca_linha_1_1, doenca_linha_1_2,doenca_linha_2_1, doenca_linha_2_2,doenca_linha_3_1, doenca_linha_3_2){
        (function(API){
          console.log(response)
            API.myText = function(txt, options, x, y) {
                options = options ||{};
                /* Use the options align property to specify desired text alignment
                * Param x will be ignored if desired text alignment is 'center'.
                * Usage of options can easily extend the function to apply different text 
                * styles and sizes 
                */
                if( options.align == "center" ){
                    // Get current font size
                    var fontSize = this.internal.getFontSize();

                    // Get page width
                    var pageWidth = this.internal.pageSize.width;

                    // Get the actual text's width
                    /* You multiply the unit width of your string by your font size and divide
                    * by the internal scale factor. The division is necessary
                    * for the case where you use units other than 'pt' in the constructor
                    * of jsPDF.
                    */ 
                    var txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;

                    // Calculate text's x coordinate
                    x = ( pageWidth - txtWidth ) / 2;
                }

                // Draw text at x,y
                this.text(txt,x,y);
            }
        })(jsPDF.API);

          //Folhas Linha 1
          var array_folhas_linha_1_1 = new Array();
          array_folhas_linha_1_1.push('Nº Folhas');
          console.log(folhas_linha_1_1.length);
          for (let index = 0; index < folhas_linha_1_1.length; index++) {
            if(folhas_linha_1_1[index].n_folhas != undefined){
              array_folhas_linha_1_1.push(folhas_linha_1_1[index].n_folhas)
            }else{
              array_folhas_linha_1_1.push("")
            }
          }
 
          var array_folhas_linha_1_2 = new Array();
          array_folhas_linha_1_2.push('Nº Folhas');
          console.log(folhas_linha_1_2.length);
          for (let index = 0; index < folhas_linha_1_2.length; index++) {
            if(folhas_linha_1_2[index].n_folhas != undefined){
              array_folhas_linha_1_2.push(folhas_linha_1_2[index].n_folhas)
            }else{
              array_folhas_linha_1_2.push("")
            }
          }
          //Folhas Linha 2
          var array_folhas_linha_2_1 = new Array();
          array_folhas_linha_2_1.push('Nº Folhas');
          console.log(folhas_linha_2_1.length);
          for (let index = 0; index < folhas_linha_2_1.length; index++) {
            if(folhas_linha_2_1[index].folhas != undefined){
              array_folhas_linha_2_1.push(folhas_linha_2_1[index].n_folhas)
            }else{
              array_folhas_linha_2_1.push("")
            }
          }

          var array_folhas_linha_2_2 = new Array();
          array_folhas_linha_2_2.push('Nº Folhas');
          console.log(folhas_linha_2_2.length);
          for (let index = 0; index < folhas_linha_2_2.length; index++) {
            if(folhas_linha_2_2[index].folhas != undefined){
              array_folhas_linha_2_2.push(folhas_linha_2_2[index].n_folhas)
            }else{
              array_folhas_linha_2_2.push("")
            }
          }

          //Folhas Linha 3
          var array_folhas_linha_3_1 = new Array();
          array_folhas_linha_3_1.push('Nº Folhas');
          console.log(folhas_linha_3_1.length);
          for (let index = 0; index < folhas_linha_3_1.length; index++) {
            if(folhas_linha_3_1[index].folhas != undefined){
              array_folhas_linha_3_1.push(folhas_linha_3_1[index].n_folhas)
            }else{
              array_folhas_linha_3_1.push("")
            }
          }

          var array_folhas_linha_3_2 = new Array();
          array_folhas_linha_3_2.push('Nº Folhas');
          console.log(folhas_linha_3_2.length);
          for (let index = 0; index < folhas_linha_3_2.length; index++) {
            if(folhas_linha_3_2[index].folhas != undefined){
              array_folhas_linha_3_2.push(folhas_linha_3_2[index].n_folhas)
            }else{
              array_folhas_linha_3_2.push("")
            }
          }

          //Altura Linha 1
          var array_altura_linha_1_1 = new Array();
          array_altura_linha_1_1.push('Nº Folhas');
          console.log(altura_linha_1_1.length);
          for (let index = 0; index < altura_linha_1_1.length; index++) {
            if(altura_linha_1_1[index].altura != undefined){
              array_altura_linha_1_1.push(altura_linha_1_1[index].altura)
            }else{
              array_altura_linha_1_1.push("")
            }
          }

          var array_altura_linha_1_2 = new Array();
          array_altura_linha_1_2.push('Nº Folhas');
          console.log(altura_linha_1_2.length);
          for (let index = 0; index < altura_linha_1_2.length; index++) {
            if(altura_linha_1_2[index].altura != undefined){
              array_altura_linha_1_2.push(altura_linha_1_2[index].altura)
            }else{
              array_altura_linha_1_2.push("")
            }
          }

          //Altura Linha 2
          var array_altura_linha_2_1 = new Array();
          array_altura_linha_2_1.push('Nº Folhas');
          console.log(altura_linha_2_1.length);
          for (let index = 0; index < altura_linha_2_1.length; index++) {
            if(altura_linha_2_1[index].altura != undefined){
              array_altura_linha_2_1.push(altura_linha_2_1[index].altura)
            }else{
              array_altura_linha_2_1.push("")
            }
          }

          var array_altura_linha_2_2 = new Array();
          array_altura_linha_2_2.push('Nº Folhas');
          console.log(altura_linha_2_2.length);
          for (let index = 0; index < altura_linha_2_2.length; index++) {
            if(altura_linha_2_2[index].altura != undefined){
              array_altura_linha_2_2.push(altura_linha_2_2[index].altura)
            }else{
              array_altura_linha_2_2.push("")
            }
          }

          //Altura Linha 3
          var array_altura_linha_3_1 = new Array();
          array_altura_linha_3_1.push('Nº Folhas');
          console.log(altura_linha_3_1.length);
          for (let index = 0; index < altura_linha_3_1.length; index++) {
            if(altura_linha_3_1[index].altura != undefined){
              array_altura_linha_3_1.push(altura_linha_3_1[index].altura)
            }else{
              array_altura_linha_3_1.push("")
            }
          }

          var array_altura_linha_3_2 = new Array();
          array_altura_linha_3_2.push('Nº Folhas');
          console.log(altura_linha_3_2.length);
          for (let index = 0; index < altura_linha_3_2.length; index++) {
            if(altura_linha_3_2[index].altura != undefined){
              array_altura_linha_3_2.push(altura_linha_3_2[index].altura)
            }else{
              array_altura_linha_3_2.push("")
            }
          }

          //Praga Linha 1
          var array_praga_linha_1_1 = new Array();
          array_praga_linha_1_1.push('Nº Folhas');
          console.log(praga_linha_1_1.length);
          for (let index = 0; index < praga_linha_1_1.length; index++) {
            if(praga_linha_1_1[index].praga != undefined){
              array_praga_linha_1_1.push(altura_linha_1_1[index].praga)
            }else{
              array_praga_linha_1_1.push("")
            }
          }

          var array_praga_linha_1_2 = new Array();
          array_praga_linha_1_2.push('Nº Folhas');
          console.log(praga_linha_1_2.length);
          for (let index = 0; index < praga_linha_1_2.length; index++) {
            if(praga_linha_1_2[index].praga != undefined){
              array_praga_linha_1_2.push(praga_linha_1_2[index].praga)
            }else{
              array_praga_linha_1_2.push("")
            }
          }


          //Praga Linha 2
          var array_praga_linha_2_1 = new Array();
          array_praga_linha_2_1.push('Nº Folhas');
          console.log(praga_linha_2_1.length);
          for (let index = 0; index < praga_linha_2_1.length; index++) {
            if(praga_linha_2_1[index].praga != undefined){
              array_praga_linha_2_1.push(altura_linha_2_1[index].praga)
            }else{
              array_praga_linha_2_1.push("")
            }
          }

          var array_praga_linha_2_2 = new Array();
          array_praga_linha_2_2.push('Nº Folhas');
          console.log(praga_linha_2_2.length);
          for (let index = 0; index < praga_linha_2_2.length; index++) {
            if(praga_linha_2_2[index].praga != undefined){
              array_praga_linha_2_2.push(praga_linha_2_2[index].praga)
            }else{
              array_praga_linha_2_2.push("")
            }
          }

          //Praga Linha 3
          var array_praga_linha_3_1 = new Array();
          array_praga_linha_3_1.push('Nº Folhas');
          console.log(praga_linha_3_1.length);
          for (let index = 0; index < praga_linha_3_1.length; index++) {
            if(praga_linha_3_1[index].praga != undefined){
              array_praga_linha_3_1.push(altura_linha_3_1[index].praga)
            }else{
              array_praga_linha_3_1.push("")
            }
          }

          var array_praga_linha_3_2 = new Array();
          array_praga_linha_3_2.push('Nº Folhas');
          console.log(praga_linha_3_2.length);
          for (let index = 0; index < praga_linha_3_2.length; index++) {
            if(praga_linha_3_2[index].praga != undefined){
              array_praga_linha_3_2.push(praga_linha_3_2[index].praga)
            }else{
              array_praga_linha_3_2.push("")
            }
          }

          //Doença Linha 1
          var array_doenca_linha_1_1 = new Array();
          array_doenca_linha_1_1.push('Nº Folhas');
          console.log(doenca_linha_1_1.length);
          for (let index = 0; index < doenca_linha_1_1.length; index++) {
            if(doenca_linha_1_1[index].doenca != undefined){
              array_doenca_linha_1_1.push(altura_linha_1_1[index].doenca)
            }else{
              array_doenca_linha_1_1.push("")
            }
          }

          var array_doenca_linha_1_2 = new Array();
          array_doenca_linha_1_2.push('Nº Folhas');
          console.log(doenca_linha_1_2.length);
          for (let index = 0; index < doenca_linha_1_2.length; index++) {
            if(doenca_linha_1_2[index].doenca != undefined){
              array_doenca_linha_1_2.push(doenca_linha_1_2[index].doenca)
            }else{
              array_doenca_linha_1_2.push("")
            }
          }

          //Doença Linha 2
          var array_doenca_linha_2_1 = new Array();
          array_doenca_linha_2_1.push('Nº Folhas');
          console.log(doenca_linha_2_1.length);
          for (let index = 0; index < doenca_linha_2_1.length; index++) {
            if(doenca_linha_2_1[index].doenca != undefined){
              array_doenca_linha_2_1.push(altura_linha_2_1[index].doenca)
            }else{
              array_doenca_linha_2_1.push("")
            }
          }

          var array_doenca_linha_2_2 = new Array();
          array_doenca_linha_2_2.push('Nº Folhas');
          console.log(doenca_linha_2_2.length);
          for (let index = 0; index < doenca_linha_2_2.length; index++) {
            if(doenca_linha_2_2[index].doenca != undefined){
              array_doenca_linha_2_2.push(doenca_linha_2_2[index].doenca)
            }else{
              array_doenca_linha_2_2.push("")
            }
          }

          //Doença Linha 3
          var array_doenca_linha_3_1 = new Array();
          array_doenca_linha_3_1.push('Nº Folhas');
          console.log(doenca_linha_3_1.length);
          for (let index = 0; index < doenca_linha_3_1.length; index++) {
            if(doenca_linha_3_1[index].doenca != undefined){
              array_doenca_linha_3_1.push(altura_linha_3_1[index].doenca)
            }else{
              array_doenca_linha_3_1.push("")
            }
          }

          var array_doenca_linha_3_2 = new Array();
          array_doenca_linha_3_2.push('Nº Folhas');
          console.log(doenca_linha_3_2.length);
          for (let index = 0; index < doenca_linha_3_2.length; index++) {
            if(doenca_linha_3_2[index].doenca != undefined){
              array_doenca_linha_3_2.push(doenca_linha_3_2[index].doenca)
            }else{
              array_doenca_linha_3_2.push("")
            }
          }

        //  console.log(tessste);
          var pdf = jsPDF('p', 'px', 'a4');   


          pdf.setFontSize(10);
          pdf.myText("Avaliação Milho",{align: "center"},20,10);
          pdf.setFontSize(9);
          pdf.myText("Número de Folhas",{align: "center"},20,18);

          pdf.myText("Linha 1",{align: "left"},20,22);
          var y;
          y = 25
          pdf.autoTable({
            body:[
              ['Nº Planta','01','02','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_folhas_linha_1_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_folhas_linha_1_2

            ],
            styles: {fontSize: 7,cellPadding: 1} ,
            startY: y,
          })
          y = y+47;
          pdf.myText("Linha 2",{align: "left"},20,y);
          y = y+5
          pdf.autoTable({
            body:[
              ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_folhas_linha_2_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_folhas_linha_2_2
            ],
            styles: {fontSize: 7,cellPadding: 1} ,
            startY: y,
          })

          y = y+46;
          pdf.myText("Linha 3",{align: "left"},20,123);
          y = y+5;
          pdf.autoTable({
            body:[
              ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_folhas_linha_3_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_folhas_linha_3_2
            ],
            styles: {fontSize: 7,cellPadding: 1} ,
            startY: y,
          })
          y = y+46;

          pdf.myText("Altura das Plantas",{align: "center"},20,y);
          y = y+5
          pdf.myText("Linha 1",{align: "left"},20,y);
          y = y+5
          pdf.autoTable({
            body:[
              ['Nº Planta','01','02','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_altura_linha_1_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_altura_linha_1_2

            ],
            styles: {fontSize: 7,cellPadding: 1} ,
            startY: y,
          })


          y = y+47;
          pdf.myText("Linha 2",{align: "left"},20,y);
          y = y+5
          pdf.autoTable({
            body:[
              ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_altura_linha_2_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_altura_linha_2_2
            ],
            styles: {fontSize: 7,cellPadding: 1} ,
            startY: y,
          })

          y = y+46;
          pdf.myText("Linha 3",{align: "left"},20,y);
          y = y+5;
          pdf.autoTable({
            body:[
              ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_altura_linha_3_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_altura_linha_3_2
            ],
            styles: {fontSize: 7,cellPadding: 1} ,
            startY: y,
          })


          y = y+46;

          pdf.myText("Pragas",{align: "center"},20,y);
          y = y+5
          pdf.myText("Linha 1",{align: "left"},20,y);
          y = y+5
          pdf.autoTable({
            body:[
              ['Nº Planta','01','02','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_praga_linha_1_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_praga_linha_1_2

            ],
            styles: {fontSize: 7,cellPadding: 1} ,
            startY: y,
          })


          y = y+47;
          pdf.myText("Linha 2",{align: "left"},20,y);
          y = y+5
          pdf.autoTable({
            body:[
              ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_praga_linha_2_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_praga_linha_2_2
            ],
            styles: {fontSize: 7,cellPadding: 1} ,
            startY: y,
          })

          y = y+46;
          pdf.myText("Linha 3",{align: "left"},20,y);
          y = y+5;
          pdf.autoTable({
            body:[
              ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_praga_linha_3_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_praga_linha_3_2
            ],
            styles: {fontSize: 7,cellPadding: 1} ,
            startY: y,
          })


          y = y+46;

          pdf.myText("Doenças",{align: "center"},20,y);
          y = y+5
          pdf.myText("Linha 1",{align: "left"},20,y);
          y = y+5
          pdf.autoTable({
            body:[
              ['Nº Planta','01','02','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_doenca_linha_1_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_doenca_linha_1_2

            ],
            styles: {fontSize: 7,cellPadding: 2} ,
            startY: y,
          })


          y = y+47;
          pdf.myText("Linha 2",{align: "left"},20,y);
          y = y+5
          pdf.autoTable({
            body:[
              ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_doenca_linha_2_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_doenca_linha_2_2
            ],
            styles: {fontSize: 7,cellPadding: 2} ,
            startY: y,
          })

          y = y+46;
          pdf.myText("Linha 3",{align: "left"},20,y);
          y = y+5;
          pdf.autoTable({
            body:[
              ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              array_doenca_linha_3_1,
              ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
              array_doenca_linha_3_2
            ],
            styles: {fontSize: 7,cellPadding: 2} ,
            startY: y,
          })


            pdf.save('info.pdf');
        }



        const state = reactive({
            isLoading: false,
        })


      return {
        downloadPDF,
        createPdf,
        state
      }
    },
    data(){
        return{
        }
    },methods:{

    }
}
</script>