<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Relatórios</li>
              </ol>
            </nav>

            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                  Relatório de Plantio
                </h3>
              </div>
              <div class="col text-right"> 
                <router-link to="/adicionar-relatorio-plantio"  tag="primary">
                  <base-button type="primary" id="btn_new_product">Novo Relatório</base-button>
                </router-link>
              </div>
            </div>

            <div class="table-responsive">
              <relatorios-plantio-card-loading v-if="state.isLoading"/>
              <table class="table tablesorter table align-items-center table-flush" v-else>
                <thead class="thead-light">
                    <th>Cod</th>
                    <th>Produtor</th>
                    <th>Data</th>
                    <th></th>
                </thead>
                <tbody class="list">
                  <p v-if="state.hasError">
                    Aconteceu um erro ao carregar os relatórios
                  </p>
                  <p v-if="!state.relatorios.length && !state.isLoading">
                    Nenhum relatório cadastrado
                  </p>
                  <relatorios-plantio-card
                    @deletarRelatorio="deletarRelatorio"
                    v-else
                    v-for="relatorio in state.relatorios"
                    :key="relatorio.id"
                    :relatorio="relatorio"
                    />
                  </tbody>
                  </table>      
                </div>
                <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''">
      <base-pagination total="30"></base-pagination>
          </div>
      </stats-card>
        </div>
        
      </div>
    </div>
  </div>

</template>
<script>
  import { onMounted, reactive, onErrorCaptured  } from 'vue'
  import RelatoriosPlantioCard from '../components/RelatoriosPlantioCard'
  import services from '../services'
  import RelatoriosPlantioCardLoading from '../components/RelatoriosPlantioCard/Loading'
  import Loading from '../components/Loading'
  export default {
    components: {
      RelatoriosPlantioCard,
      RelatoriosPlantioCardLoading
    },
    setup () {
      const state = reactive({
        isLoading: false,
        isLoading2:false,
        relatorios: [],
        hasError: false
      })
      onErrorCaptured(handleErrors)

      onMounted(() => {
        fetchRelatorios()
      })

/*
    function AlterarPlantasFolha(event,linha_nasc,n_planta){
      console.log(state.plantas_folhas_linha_1)

        switch (linha_nasc) {
          case 1:
            state.plantas_folhas_linha_1[n_planta -1].n_folhas = event.target.value
          break;
          case 2:
            state.plantas_folhas_linha_2[n_planta -1].n_folhas = event.target.value
          break;
          case 3:
            state.plantas_folhas_linha_3[n_planta -1].n_folhas = event.target.value
          break;
          default:
            break;
        }
    }
*/

      function handleErrors (error) {
        console.log(error)
        state.hasError = !!error
      }

      async function deletarRelatorio(id){

        console.log("aqui, id: "+id);
        if(!window.confirm("Deseja deletar este relatório?")){
          return; 
        }
  
        try{
          state.isLoading = true;
          var erro_deletar = await services.relatorio_plantio.delete({
            id
          })
          console.log(erro_deletar);  
          fetchRelatorios();

          }catch (error) {
          handleErrors(error)
        }
      }

      async function fetchRelatorios () {
        try {
          state.isLoading = true
          const { data } = await services.relatorio_plantio.getAll({
            type: state.currentClientType
          }
          )
          console.log(data)
          state.relatorios = data
          state.isLoading = false

          
          
        } catch (error) {
          handleErrors(error)
        }
      }
      return {
        state,
        deletarRelatorio,
        Loading
      }
    }
  };
  </script>

  <style>
    #btn_new_product{
      margin-bottom: 10px;
    }
  </style>