<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">

        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Faça o Login!</small>
          </div>
          <form @submit.prevent="handleSubmit" role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Login"
              addon-left-icon="ni ni-email-83"
              v-model="state.email.value"
            >
            </base-input>
            <span
              v-if="!!state.email.errorMessage"
              class="block font-medium text-brand-danger">
              {{ state.email.errorMessage }}
            </span>


            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Senha"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="state.password.value"
            >
            </base-input>

            <span
              v-if="!!state.password.errorMessage"
              class="block font-medium text-brand-danger"
              >
              {{ state.password.errorMessage }}
            </span>

            <div v-if="!!state.errorLogin" class="alert alert-warning alert-dismissible fade show" role="alert">
                <span class="alert-text"><strong>{{state.errorLogin}}</strong></span>
            </div>


            <div class="text-center">
              <button type="submit" class="btn btn-primary">Entrar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import services from '../services'
import { useField } from "vee-validate"
import { useRouter } from 'vue-router'
import Cookie from 'js-cookie'
export default {
  setup() {
    const router = useRouter()
    const{
      value: emailValue,
      errorMessage: emailErrorMessage
    } = useField('email');

    const{
      value: passwordValue,
      errorMessage: passwordErrorMessage
    } = useField('password');

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      email: {
        value: emailValue,
        errorMessage: emailErrorMessage
      },
      password: {
        value: passwordValue,
        errorMessage: passwordErrorMessage
      }
    })

    async function handleSubmit () {
      
      try{
        state.isLoading = true
        const { data, errors } = await services.auth.login({
          email: state.email.value,
          password: state.password.value
        })
        console.log(data)
        if(!errors) {
          Cookie.set('_plantacao_token',data.access_token,{ expires: 1 })
          Cookie.set('_engenheiro_id',data.engenheiro_id,{ expires: 1 })
          Cookie.set('_nome_engenheiro',data.engenheiro_nome,{ expires: 1 })
       //   window.localStorage.setItem('nome_pessoa', data.nome_pessoa)
         /* window.localStorage.setItem('token', data.jwt)
          window.localStorage.setItem('empresa_id', data.empresa_id)
          window.localStorage.setItem('nome_pessoa', data.nome_pessoa)
          window.localStorage.setItem('colaborador_id', data.funcionario_id)
          window.localStorage.setItem('nome_empresa', data.nome_empresa)*/
          router.push({ name: 'dashboard' })
        }else{
          console.log
          state.errorLogin = errors
        }

      } catch (error) {
        console.log("OI")
        state.isLoading = false
        state.hasErrors = !!error
      }
    }

    return {
      state,
      handleSubmit
    }
  }
}

</script>
<style></style>
