<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Engenheiros</li>
              </ol>
            </nav>

            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                  Engenheiros
                </h3>
              </div>
              <div class="col text-right">
                <router-link to="/adicionar-engenheiro"  tag="primary">
                  <base-button type="primary" id="btn_new_product">Novo Engenheiro</base-button>
                </router-link>
              </div>
            </div>


            <div class="table-responsive">
              <engenheiros-card-loading v-if="state.isLoading"/>
              <table class="table tablesorter table align-items-center table-flush" v-else>
                <thead class="thead-light">
                    <th>Cod</th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Telefone</th>
                    <th></th>
                </thead>
                <tbody class="list">
                  <p v-if="state.hasError">
                    Aconteceu um erro ao carregar as doenças
                  </p>
                  <p v-if="!state.engenheiros.length && !state.isLoading">
                    Nenhuma doença cadastrada
                  </p>
                  <engenheiros-card
                    @deletarEngenheiro="deletarEngenheiro"
                    v-else
                    v-for="engenheiro in state.engenheiros"
                    :key="engenheiro.id"
                    :engenheiro="engenheiro"
                    />
                  </tbody>
                  </table>      
                </div>
                <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''">
      <base-pagination total="30"></base-pagination>
          </div>
      </stats-card>
        </div>
        
      </div>
    </div>
  </div>

</template>
<script>
  import { onMounted, reactive, onErrorCaptured  } from 'vue'
  import EngenheirosCard from '../components/EngenheirosCard'
  import services from '../services'
  import EngenheirosCardLoading from '../components/EngenheirosCard/Loading'

  export default {
    components: {
      EngenheirosCard,
      EngenheirosCardLoading
    },
    setup () {
      const state = reactive({
        isLoading: false,
        engenheiros: [],
        hasError: false
      })
      onErrorCaptured(handleErrors)

      onMounted(() => {
        fetchEngenheiros()
      })



      function handleErrors (error) {
        console.log(error)
        state.hasError = !!error
      }

      async function deletarEngenheiro(id){

        console.log("aqui, id: "+id);
        if(!window.confirm("Deseja apagar este engenheiro?")){
          return; 
        }
  
        try{
          state.isLoading = true;
          var erro_deletar = await services.engenheiro.delete({
            id
          })
          console.log(erro_deletar);  
          fetchEngenheiros();

          }catch (error) {
          handleErrors(error)
        }
      }

      async function fetchEngenheiros () {
        try {
          state.isLoading = true
          const { data } = await services.engenheiro.getAll({
          }
          )
          console.log(data)
          state.engenheiros = data
          state.isLoading = false
          
        } catch (error) {
          handleErrors(error)
        }
      }
      return {
        state,
        deletarEngenheiro
      }
    }
  };
  </script>

  <style>
    #btn_new_product{
      margin-bottom: 10px;
    }
  </style>