<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/relatorios-germinacao" tag="primary"
                    >Relatórios Germinação</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Adicionar
                </li>
              </ol>
            </nav>

            <form @submit.prevent="handleSubmit">
              <div>
                <h2 class="mb-3">Novo Relatório de Germinação</h2>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div
                    class="card shadow mb-3"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div class="card-header border-0">
                      <div class="row align-items-center">
                        <div class="col">
                          <h3
                            class="mb-0"
                            :class="type === 'dark' ? 'text-white' : ''"
                          >
                            Dados do Relatório
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div
                      class="card-header border-0"
                      :class="type === 'dark' ? 'bg-transparent' : ''"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Código</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.codigo.value"
                              readonly
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label>Produtor</label>
                            <select
                              class="form-control"
                              v-model="state.produtor.value"
                            >
                              <select-produtores
                                v-for="produtor in state.produtores"
                                :key="produtor.id"
                                :produtor="produtor"
                              />
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Data </label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.data.value"
                            />
                            <span
                              v-if="!!state.data.errorMessage"
                              class="block font-medium text-brand-danger"
                            >
                              {{ state.data.errorMessage }}
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>DPP </label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.dpp.value"
                            />
                            <span
                              v-if="!!state.dpp.errorMessage"
                              class="block font-medium text-brand-danger"
                            >
                              {{ state.dpp.errorMessage }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-8">
                          <div class="form-group">
                            <label>Hibrido </label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.hibrido.value"
                            />
                            <span
                              v-if="!!state.hibrido.errorMessage"
                              class="block font-medium text-brand-danger"
                            >
                              {{ state.hibrido.errorMessage }}
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label>Distância Linha </label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="state.distancia_linha.value"
                            />
                            <span
                              v-if="!!state.distancia_linha.errorMessage"
                              class="block font-medium text-brand-danger"
                            >
                              {{ state.distancia_linha.errorMessage }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label>Descrição </label>
                            <textarea
                              class="form-control"
                              v-model="state.observacao.value"
                              rows="4"
                            ></textarea>
                            <span
                              v-if="!!state.observacao.errorMessage"
                              class="block font-medium text-brand-danger"
                            >
                              {{ state.observacao.errorMessage }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div
                    class="card shadow mb-3"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div class="card-header border-0">
                      <div class="row align-items-center">
                        <div class="col">
                          <h3
                            class="mb-0"
                            :class="type === 'dark' ? 'text-white' : ''"
                          >
                            Plantas Nascidas
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div
                      class="card-header border-0"
                      :class="type === 'dark' ? 'bg-transparent' : ''"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Quantidade de Linha</label>
                            <select
                              @change="AlterarQtdLinhaPlantaNasc($event)"
                              class="form-control"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <QuantidadeLinhasPlantasNasc
                        @alterarPlantasNasc="AlterarPlantasNasc"
                        @alterarQtdPlantasNasc="AlterarQtdPlantasNasc"
                        :qtdLinhasPlantasNasc="
                          parseInt(state.qtd_linha_plantas_nasc)
                        "
                      />
                    </div>
                  </div>

                  <div
                    class="card shadow mb-3"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div class="card-header border-0">
                      <div class="row align-items-center">
                        <div class="col">
                          <h3
                            class="mb-0"
                            :class="type === 'dark' ? 'text-white' : ''"
                          >
                            Distância entre Plantas
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card"
                    :class="type === 'dark' ? 'bg-default' : ''"
                  >
                    <div
                      class="card-header border-0"
                      :class="type === 'dark' ? 'bg-transparent' : ''"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Distância entre Plantas</label>
                            <select
                              @change="AlterarQtdLinhaPlantasDistancia($event)"
                              class="form-control"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <QuantidadeLinhasDistanciaPlantas
                        @alterarQtdPlantasDistancia="AlterarQtdPlantasDistancia"
                        @alterarDistanciaPlanta="AlterarDistanciaPlanta"
                        :qtdLinhasDistanciaPlantas="
                          parseInt(state.qtd_linha_distancia_plantas)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary my-4"
                id="submit-button"
                :disabled="state.isSaving"
                type="submit"
              >
                Salvar<img
                  src="@/assets/loading.gif"
                  style="width: 20px; margin-left: 10px"
                  v-if="state.isSaving"
                />
              </button>
              <button
                class="btn btn-warning my-4"
                id="submit-button"
                type="button"
                @click="cancel()"
              >
                Cancelar
              </button>
            </form>
          </stats-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import services from "../services";
import QuantidadeLinhasPlantasNasc from "../components/QuantidadeLinhasPlantasNasc.vue";
import QuantidadeLinhasDistanciaPlantas from "../components/QuantidadeLinhasDistanciaPlantas.vue";
import SelectProdutores from "../components/ProdutoresSelect.vue";
import { useField } from "vee-validate";
import { validateEmptyAndLength3, validateEmpty } from "../utils/validators";
import { useRouter } from "vue-router";
import Cookie from "js-cookie";

export default {
  components: {
    QuantidadeLinhasPlantasNasc,
    QuantidadeLinhasDistanciaPlantas,
  },
  setup(_, { emit }) {
    var engenheiro_id;
    const router = useRouter();

    var id_praga = router.currentRoute._value.params.id;
    if (id_praga === undefined) {
      id_praga = 0;
    }

    function cancel() {
      router.push({ name: "RelatoriosGerminacao" });
    }
    const { value: codigoValue, errorMessage: codigoErrorMessage } = useField(
      "codigo",
      validateEmptyAndLength3
    );

    const { value: hibridoValue, errorMessage: hibridoErrorMessage } = useField(
      "hibrido",
      validateEmptyAndLength3
    );

    const { value: dataValue, errorMessage: dataErrorMessage } = useField(
      "data",
      validateEmptyAndLength3
    );

    const { value: DPPValue, errorMessage: DPPErrorMessage } = useField(
      "dpp",
      validateEmptyAndLength3
    );

    const {
      value: observacaoValue,
      errorMessage: observacaoErrorMessage,
    } = useField("observacao", validateEmptyAndLength3);

    const {
      value: distanciaLinhaValue,
      errorMessage: distanciaLinhaErrorMessage,
    } = useField("distancia_linha", validateEmptyAndLength3);
    const {
      value: produtorValue,
      errorMessage: produtorErrorMessage,
    } = useField("produtor", validateEmpty);

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      isSaving: false,
      qtd_linha_plantas_nasc: 0,
      qtd_linha_distancia_plantas: 0,
      produtores: [],
      plantas_distancia_linha_1: [],
      plantas_distancia_linha_2: [],
      plantas_distancia_linha_3: [],
      plantas_nasc_linha_1: [],
      plantas_nasc_linha_2: [],
      plantas_nasc_linha_3: [],

      codigo: {
        value: codigoValue,
        errorMessage: codigoErrorMessage,
      },
      hibrido: {
        value: hibridoValue,
        errorMessage: hibridoErrorMessage,
      },
      data: {
        value: dataValue,
        errorMessage: dataErrorMessage,
      },
      dpp: {
        value: DPPValue,
        errorMessage: DPPErrorMessage,
      },
      observacao: {
        value: observacaoValue,
        errorMessage: observacaoErrorMessage,
      },
      distancia_linha: {
        value: distanciaLinhaValue,
        errorMessage: distanciaLinhaErrorMessage,
      },
      produtor: {
        value: produtorValue,
        errorMessage: produtorErrorMessage,
      },
    });

    function handleErrors(error) {
      console.log(error);
      state.hasError = !!error;
    }

    function dataAtualFormatada() {
      engenheiro_id = Cookie.get("_engenheiro_id");
      var data = new Date(),
        dia = data.getDate().toString(),
        diaF = dia.length == 1 ? "0" + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = mes.length == 1 ? "0" + mes : mes,
        anoF = data.getFullYear();
      return diaF + "/" + mesF + "/" + anoF;
    }

    async function fetchProdutores() {
      try {
        state.isLoading = true;
        const { data } = await services.produtor.getAll({
          type: state.currentClientType,
        });
        console.log(data);
        state.produtores = data;
        state.isLoading = false;
      } catch (error) {
        handleErrors(error);
      }
    }

    function AlterarQtdLinhaPlantaNasc(event) {
      state.qtd_linha_plantas_nasc = parseInt(event.target.value);
    }

    function AlterarQtdLinhaPlantasDistancia(event) {
      state.qtd_linha_distancia_plantas = parseInt(event.target.value);
    }

    function AlterarQtdPlantasNasc(event, linha_nasc) {
      for (var i = 0; i < parseInt(event.target.value); i++) {
        var planta = new Object();
        planta.n_planta = i + 1;
        planta.nasceu = "N";

        switch (linha_nasc) {
          case 1:
            state.plantas_nasc_linha_1.push(planta);
            break;
          case 2:
            state.plantas_nasc_linha_2.push(planta);
            break;
          case 3:
            state.plantas_nasc_linha_3.push(planta);
            break;
          default:
            break;
        }
      }
    }

    function AlterarPlantasNasc(event, linha_nasc, n_planta) {
      switch (linha_nasc) {
        case 1:
          if (event.target.value == "on") {
            state.plantas_nasc_linha_1[n_planta - 1].nasceu = "S";
          } else {
            state.plantas_nasc_linha_1[n_planta - 1].nasceu = "N";
          }
          break;
        case 2:
          if (event.target.value == "on") {
            state.plantas_nasc_linha_1[n_planta - 1].nasceu = "S";
          } else {
            state.plantas_nasc_linha_1[n_planta - 1].nasceu = "N";
          }
          break;
        case 3:
          if (event.target.value == "on") {
            state.plantas_nasc_linha_1[n_planta - 1].nasc = "S";
          } else {
            state.plantas_nasc_linha_1[n_planta - 1].nasc = "N";
          }
          break;
        default:
          break;
      }
    }

    function AlterarQtdPlantasDistancia(event, linha_distancia) {
      for (var i = 0; i < parseInt(event.target.value); i++) {
        var planta = new Object();
        planta.n_planta = i + 1;
        planta.distancia = 0;

        switch (linha_distancia) {
          case 1:
            state.plantas_distancia_linha_1.push(planta);
            break;
          case 2:
            state.plantas_distancia_linha_2.push(planta);
            break;
          case 3:
            state.plantas_distancia_linha_3.push(planta);
            break;
          default:
            break;
        }
      }
    }

    function AlterarDistanciaPlanta(event, linha_distancia, n_planta) {
      switch (linha_distancia) {
        case 1:
          state.plantas_distancia_linha_1[n_planta - 1].distancia =
            event.target.value;
          break;
        case 2:
          state.plantas_distancia_linha_2[n_planta - 1].distancia =
            event.target.value;
          break;
        case 3:
          state.plantas_distancia_linha_3[n_planta - 1].distancia =
            event.target.value;
          break;
        default:
          console.log(n_planta);
          console.log(event);
          break;
      }
    }

    function definirDataAtual() {
      state.data.value = dataAtualFormatada();
    }
    onMounted(() => {
      definirDataAtual(), fetchProdutores();
    });

    async function handleSubmit() {
      try {
        state.isSaving = true;
        var retorno = await services.relatorio_germinacao.save({
          engenheiro_id: engenheiro_id,
          hibrido: state.hibrido.value,
          data: state.data.value,
          DPP: state.dpp.value,
          observacao: state.observacao.value,
          distancia_linha: state.distancia_linha.value,
          produtor_id: state.produtor.value,
        });

        var array_nascimentos = {};

        if (state.plantas_nasc_linha_1.length > 0) {
          array_nascimentos.linha_1 = state.plantas_nasc_linha_1;
        }

        if (state.plantas_nasc_linha_2.length > 0) {
          array_nascimentos.linha_2 = state.plantas_nasc_linha_2;
        }

        if (state.plantas_nasc_linha_3.length > 0) {
          array_nascimentos.linha_3 = state.plantas_nasc_linha_3;
        }

        console.log(array_nascimentos);

        //Enviando Plantas Nascidas
        var retorno_plantas_nascidas = await services.relatorio_germinacao.save_plantas_nascidas(
          {
            nascimentos: array_nascimentos,
            relatorio_id: retorno.data,
          }
        );
        if (!retorno_plantas_nascidas) {
          console.log(retorno_plantas_nascidas);
          return;
        }

        /* ----------------------------------------- */
        //Distâncias
        var array_distancia = {};

        if (state.plantas_distancia_linha_1.length > 0) {
          array_distancia.linha_1 = state.plantas_distancia_linha_1;
        }

        if (state.plantas_distancia_linha_2.length > 0) {
          array_distancia.linha_2 = state.plantas_distancia_linha_2;
        }

        if (state.plantas_distancia_linha_3.length > 0) {
          array_distancia.linha_3 = state.plantas_distancia_linha_3;
        }

        //Enviando Plantas Distancia
        var retorno_plantas_distancia = await services.relatorio_germinacao.save_plantas_distancia(
          {
            distancias: array_distancia,
            relatorio_id: retorno.data,
          }
        );
        if (!retorno_plantas_distancia) {
          console.log(retorno_plantas_distancia);
          return;
        }

        router.push({ name: "RelatoriosGerminacao" });

        state.isLoading = false;
        state.isSaving = false;
      } catch (error) {
        console.log(error);
        state.isLoading = false;
        state.hasErrors = !!error;
        state.isSaving = false;
        alert("Erro ao salvar Relatório.");
        //  toast.error('Ocorreu um erro ao criar a conta')
      }
      state.isSaving = false;
    }
    return {
      state,
      AlterarQtdLinhaPlantaNasc,
      AlterarQtdLinhaPlantasDistancia,
      AlterarQtdPlantasNasc,
      AlterarPlantasNasc,
      AlterarQtdPlantasDistancia,
      AlterarDistanciaPlanta,
      handleSubmit,
      emit,
      cancel,
      SelectProdutores,
    };
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>