<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Avaliações</li>
              </ol>
            </nav>

            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                  Avaliações
                </h3>
              </div>
              <div class="col text-right">
                <router-link to="/adicionar-avaliacao-milho"  tag="primary">
                  <base-button type="primary" id="btn_new_product">Nova Avaliação</base-button>
                </router-link>
              </div>
            </div>


            <div class="table-responsive">
              <AvaliacoesCardLoading v-if="state.isLoading"/>
              <table class="table tablesorter table align-items-center table-flush" v-else>
                <thead class="thead-light">
                    <th>Cod</th>
                    <th>Nome / Produtor</th>
                    <th>Data</th>
                    <th></th>
                </thead>
                <tbody class="list">
                  <p v-if="state.hasError">
                    Aconteceu um erro ao carregar as Avaliações
                  </p>
                  <p v-if="!state.avaliacoes.length && !state.isLoading">
                    Nenhuma Avaliação cadastrada
                  </p>
                  <AvaliacoesCard
                    @deletarAvaliacao="deletarAvaliacao"
                    v-else
                    v-for="avaliacao in state.avaliacoes"
                    :key="avaliacao.id"
                    :avaliacao="avaliacao"
                    />
                  </tbody>
                  </table>      
                </div>
                <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''">
      <base-pagination total="30"></base-pagination>
          </div>
      </stats-card>
        </div>
        
      </div>
    </div>
  </div>

</template>
<script>
  import { onMounted, reactive, onErrorCaptured  } from 'vue'
  import AvaliacoesCard from '../components/AvaliacoesCard'
  import AvaliacoesCardLoading from '../components/AvaliacoesCard/Loading'
  import services from '../services'

  export default {
    components: {
      AvaliacoesCard,
      AvaliacoesCardLoading
    },
    setup () {
      const state = reactive({
        isLoading: false,
        avaliacoes: [],
        hasError: false
      })
      onErrorCaptured(handleErrors)

      onMounted(() => {
        fetchAvaliacoes()
      })

      async function deletarAvaliacao(id){

        if(!window.confirm("Deseja apagar esta avaliação?")){
          return; 
        }
  
        try{
          state.isLoading = true;
          var erro_deletar = await services.avaliacao_milho.delete({
            id
          })
          console.log(erro_deletar);  
          fetchAvaliacoes();

          }catch (error) {
          handleErrors(error)
        }
      }

      function handleErrors (error) {
        console.log(error)
        state.hasError = !!error
      }

      async function fetchAvaliacoes () {
        try {
          state.isLoading = true
          const { data } = await services.avaliacao_milho.getAll({
          }
          )
          state.avaliacoes = data
          state.isLoading = false
          
        } catch (error) {
          handleErrors(error)
        }
      }
      return {
        state,
        deletarAvaliacao
      }
    }
  };
  </script>

  <style>
    #btn_new_product{
      margin-bottom: 10px;
    }
  </style>