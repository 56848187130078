export default httpClient => ({
    getAll: async () => {

      const response = await httpClient.get('/avaliacoes-milho')
      
      return { data: response.data }
  
    },
    getById: async ({ id }) => {
      const response = await httpClient.get('/avaliacao-milho/'+id,{ 
      })

      const folhas_linha_1 = await httpClient.get('/avaliacao-milho/folhas-linha/'+response.data.id+'/1')
      const folhas_linha_2 = await httpClient.get('/avaliacao-milho/folhas-linha/'+response.data.id+'/2')
      const folhas_linha_3 = await httpClient.get('/avaliacao-milho/folhas-linha/'+response.data.id+'/3')

      response.folhas_linha_1 = folhas_linha_1.data;
      response.folhas_linha_2 = folhas_linha_2.data;
      response.folhas_linha_3 = folhas_linha_3.data;

      const altura_linha_1 = await httpClient.get('/avaliacao-milho/altura-linha/'+response.data.id+'/1')
      const altura_linha_2 = await httpClient.get('/avaliacao-milho/altura-linha/'+response.data.id+'/2')
      const altura_linha_3 = await httpClient.get('/avaliacao-milho/altura-linha/'+response.data.id+'/3')

      response.altura_linha_1 = altura_linha_1.data;
      response.altura_linha_2 = altura_linha_2.data;
      response.altura_linha_3 = altura_linha_3.data;

      const praga_linha_1 = await httpClient.get('/avaliacao-milho/praga-linha/'+response.data.id+'/1')
      const praga_linha_2 = await httpClient.get('/avaliacao-milho/praga-linha/'+response.data.id+'/2')
      const praga_linha_3 = await httpClient.get('/avaliacao-milho/praga-linha/'+response.data.id+'/3')

      response.praga_linha_1 = praga_linha_1.data;
      response.praga_linha_2 = praga_linha_2.data;
      response.praga_linha_3 = praga_linha_3.data;

      const doenca_linha_1 = await httpClient.get('/avaliacao-milho/doenca-linha/'+response.data.id+'/1')
      const doenca_linha_2 = await httpClient.get('/avaliacao-milho/doenca-linha/'+response.data.id+'/2')
      const doenca_linha_3 = await httpClient.get('/avaliacao-milho/doenca-linha/'+response.data.id+'/3')

      response.doenca_linha_1 = doenca_linha_1.data;
      response.doenca_linha_2 = doenca_linha_2.data;
      response.doenca_linha_3 = doenca_linha_3.data;

      return { data: response }

    },

    save: async ({ engenheiro_id, data, produtor_id, comentario_plantas_folhas, comentario_plantas_altura, comentario_plantas_avaliacao_doencas, 
      comentario_plantas_ataque_pragas }) => {
      const response = await httpClient.post('/avaliacao-milho/adicionar', {
        engenheiro_id,
        data,
        produtor_id,
        comentario_plantas_folhas,
        comentario_plantas_altura,
        comentario_plantas_avaliacao_doencas,
        comentario_plantas_ataque_pragas,
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    
    save_plantas_folhas: async ({ avaliacao_id, folhas }) => {
      const response = await httpClient.post('/avaliacao-milho/adicionar-folhas-nascidas',{
        avaliacao_id,
        folhas
      })
      let errors = null

      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_plantas_altura: async ({ avaliacao_id, alturas }) => {
      const response = await httpClient.post('/avaliacao-milho/adicionar-altura',{
        avaliacao_id,
        alturas
      })
      let errors = null

      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_plantas_pragas: async ({ avaliacao_id, pragas }) => {
      const response = await httpClient.post('/avaliacao-milho/adicionar-pragas',{
        avaliacao_id,
        pragas
      })
      let errors = null

      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_plantas_doenca: async ({ avaliacao_id, doencas }) => {
      const response = await httpClient.post('/avaliacao-milho/adicionar-doenca',{
        avaliacao_id,
        doencas
      })
      let errors = null

      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
    delete: async ({ id }) => {
      const response = await httpClient.post('/avaliacao-milho/deletar',{
        id
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    }
})