<template>
  <div>
    <div  v-for="n in qtdPragas" :key="n">
      <h2>Praga {{n}}</h2>
      <div class="row">
        <div class="col-lg-7">
            <div class="form-group">
              <label>Praga</label>
              <input type="text" class="form-control"  @blur="$emit('adicionarPraga',$event,n,'nome_praga')">
            </div>
        </div>
        <div class="col-lg-5">
          <div class="form-group">
            <label>Índice de Infestação</label>
            <select name="" id="" class="form-control" @change="$emit('adicionarPraga',$event,n,'indice_infestacao')">
              <option value="">Selecione</option>
              <option value="MB">Muito Baixo</option>
              <option value="B">Baixo</option>
              <option value="M">Médio</option>
              <option value="A">Alto</option>
              <option value="MA">Muito Alto</option>
            </select>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>

  export default {
    name: "QuantidadePragas",
    emits:['adicionarPraga'],
    props: {
      qtdPragas: Number,
      pragas: Array
    }
  }
</script>
