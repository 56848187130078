<template>
  <button class="btn btn-primary" @click="downloadPDF"><div style="float:left">Download PDF </div><img src="@/assets/loading.gif" style="width:20px; margin-left:10px" v-if="state.isLoading"/></button>
</template>

<script>
import jsPDF from 'jspdf'
import {  reactive  } from 'vue'
import services from '../services'
import 'jspdf-autotable'

export default {
    name: 'pfd',
    props: {
      relatorio: Object
    },
    setup(props) {



    function createPdf(){
        var doc =  jsPDF()
        doc.save("output.pdf")
    }



      async function downloadPDF(){
        try{
          state.isLoading = true;
          const response = await services.relatorio_germinacao.getById({
            id:props.relatorio.id
          });
          //console.log(response.data.distancia_linha_1);
          var distancia_linha_1_1 = new Array(18)
          var distancia_linha_1_2 = new Array();

          var distancia_linha_2_1 = new Array(18)
          var distancia_linha_2_2 = new Array();

          for (let index = 0; index < distancia_linha_1_1.length; index++) {
            var distancia_obj_1_1 = new Object;
            distancia_obj_1_1.n_planta = index + 1;
            for (let index2 = 0; index2 < response.data.distancia_linha_1.length; index2++) {
              if(response.data.distancia_linha_1[index2].n_planta == index + 1){
                distancia_obj_1_1.distancia = response.data.distancia_linha_1[index2].distancia;
              }
            }
            distancia_linha_1_1[index] = distancia_obj_1_1;
          }
        
          for (let index_2 = 18; index_2 < 36; index_2++) {
            var distancia_obj_1_2 = new Object;
            distancia_obj_1_2.n_planta = index_2 + 1;
            for (let index_1_2 = 0; index_1_2 < response.data.distancia_linha_1.length; index_1_2++) {
              if(response.data.distancia_linha_1[index_1_2].n_planta == index_2 + 1){
                distancia_obj_1_2.distancia = response.data.distancia_linha_1[index_1_2].distancia;
                console.log(response.data.distancia_linha_1[index_1_2].distancia);
              }
            }
            distancia_linha_1_2[index_2] = distancia_obj_1_2;
          }

          for (let index3 = 0; index3 < distancia_linha_2_1.length; index3++) {
            var distancia_obj_2_1 = new Object;
            distancia_obj_2_1.n_planta = index3 + 1;
            for (let index2_1 = 0; index2_1 < response.data.distancia_linha_2.length; index2_1++) {
              if(response.data.distancia_linha_2[index2_1].n_planta == index3 + 1){
                distancia_obj_2_1.distancia = response.data.distancia_linha_2[index2_1].distancia;
              }
            }
            distancia_linha_2_1[index3] = distancia_obj_2_1;
          }
          
          for (let index4 = 18; index4 < 36; index4++) {
            var distancia_obj_2_2 = new Object;
            distancia_obj_2_2.n_planta = index4 + 1;
            for (let index_2_2 = 0; index_2_2 < response.data.distancia_linha_2.length; index_2_2++) {
              if(response.data.distancia_linha_2[index_2_2].n_planta == index4 + 1){
                console.log("proximo")
                console.log(response.data.distancia_linha_2[index_2_2].distancia)
                distancia_obj_2_2.distancia = response.data.distancia_linha_2[index_2_2].distancia;
              }
            }
            distancia_linha_2_2[index4] = distancia_obj_2_2;
          }
          gerarPDF(response,distancia_linha_1_1,distancia_linha_1_2,distancia_linha_2_1,distancia_linha_2_2);
          state.isLoading = false;
        }catch(error){
          alert('Erro ao gerar relatório')
        }
      }
      function gerarPDF(dados,distancia_linha_1,distancia_linha_1_2,distancia_linha_2_1,distancia_linha_2_2){
        (function(API){
          console.log(dados)
            API.myText = function(txt, options, x, y) {
                options = options ||{};
                /* Use the options align property to specify desired text alignment
                * Param x will be ignored if desired text alignment is 'center'.
                * Usage of options can easily extend the function to apply different text 
                * styles and sizes 
                */
                if( options.align == "center" ){
                    // Get current font size
                    var fontSize = this.internal.getFontSize();

                    // Get page width
                    var pageWidth = this.internal.pageSize.width;

                    // Get the actual text's width
                    /* You multiply the unit width of your string by your font size and divide
                    * by the internal scale factor. The division is necessary
                    * for the case where you use units other than 'pt' in the constructor
                    * of jsPDF.
                    */ 
                    var txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;

                    // Calculate text's x coordinate
                    x = ( pageWidth - txtWidth ) / 2;
                }

                // Draw text at x,y
                this.text(txt,x,y);
            }
        })(jsPDF.API);

          var array_distancia_linha_1_1 = new Array();
          array_distancia_linha_1_1.push('Nº Folhas');
          console.log(distancia_linha_1.length);


          for (let index = 0; index < 18; index++) {
            if(distancia_linha_1[index].distancia != undefined){
              array_distancia_linha_1_1.push(distancia_linha_1[index].distancia)
            }else{
              array_distancia_linha_1_1.push("")
            }
          }

          var array_distancia_linha_1_2 = new Array();
          array_distancia_linha_1_2.push('Nº Folhas');
          console.log(distancia_linha_1_2.length);
          for (let index2 = 18; index2 < 36; index2++) {
          //  const element = distancia_linha_1[index];
            if(distancia_linha_1_2[index2].distancia != undefined){
              array_distancia_linha_1_2.push(distancia_linha_1_2[index2].distancia)
            }else{
              array_distancia_linha_1_2.push("")
            }
          }

          var array_distancia_linha_2_1 = new Array();
          array_distancia_linha_2_1.push('Nº Folhas');
          
          for (let index3 = 0; index3 < 18; index3++) {
          //  const element = distancia_linha_1[index];
            if(distancia_linha_2_1[index3].distancia != undefined){
              array_distancia_linha_2_1.push(distancia_linha_2_1[index3].distancia)
            }else{
              array_distancia_linha_2_1.push("")
            }
          }

          var array_distancia_linha_2_2 = new Array();
          array_distancia_linha_2_2.push('Nº Folhas');
          console.log(distancia_linha_2_2.length);
          for (let index2 = 18; index2 < 36; index2++) {
          //  const element = distancia_linha_1[index];
            if(distancia_linha_2_2[index2].distancia != undefined){
              array_distancia_linha_2_2.push(distancia_linha_2_2[index2].distancia)
            }else{
              array_distancia_linha_2_2.push("")
            }
          }



            var pdf = jsPDF('p', 'px', 'a4');  
          //  const pageWidthMidle = pdf.internal.pageSize.getWidth() / 2;
        //    var posicao_central_logo = pageWidthMidle - (img_logo_width / 2)

        //    pdf.addImage('Avaliação de Milho', 'JPEG', posicao_central_logo, y, img_logo_width, img_logo_height);
        pdf.setFontSize(10);
            pdf.myText("Relatório de Germinação",{align: "center"},20,20);
            pdf.myText("Distâncias",{align: "center"},40,30);
            pdf.setFontSize(9);
            pdf.myText("Número de Folhas",{align: "center"},20,40);
            pdf.myText("Linha 1",{align: "left"},20,52);
            pdf.autoTable({
              body:[
                ['Nº Planta','01','02','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                array_distancia_linha_1_1,
                ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
                array_distancia_linha_1_2

              ],
              styles: {fontSize: 7,cellPadding: 2} ,
              startY: 60,
            })

            pdf.myText("Linha 2",{align: "left"},20,110);
            pdf.autoTable({
              body:[
                ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                array_distancia_linha_2_1,
                ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
                array_distancia_linha_2_2
              ],
              styles: {fontSize: 7,cellPadding: 2} ,
              startY: 122,
            })
/*
            pdf.myText("Linha 3",{align: "left"},20,125);
            pdf.autoTable({
              body:[
                ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                ['Nº Folhas','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
                ['Nº Folhas','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              ],
              styles: {fontSize: 7,cellPadding: 2} ,
              startY: 130,
            })


          pdf.autoTable({
              body:[
                ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                ['Nº Folhas','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
                ['Nº Folhas','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],

              ],
              styles: {fontSize: 7,cellPadding: 2} ,
              startY: 25,
            })

            pdf.myText("Linha 1",{align: "left"},20,175);
            pdf.autoTable({
              body:[
                ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                ['Nº Folhas','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
                ['Nº Folhas','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              ],
              styles: {fontSize: 7,cellPadding: 2} ,
              startY: 182,
            })

            pdf.myText("Linha 2",{align: "left"},20,228);
            pdf.autoTable({
              body:[
                ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                ['Nº Folhas','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
                ['Nº Folhas','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              ],
              styles: {fontSize: 7,cellPadding: 2} ,
              startY: 235,
            })


            pdf.myText("Linha 3",{align: "left"},20,288);
            pdf.autoTable({
              body:[
                ['Nº Planta','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                ['Nº Folhas','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
                ['Nº Planta','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'],
                ['Nº Folhas','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18'],
              ],
              styles: {fontSize: 7,cellPadding: 2} ,
              startY: 235,
            })*/


       //     pdf.text(' plantas por metro linear.:',80, 90);
    //       pdf.myText("Avaliação Milho – ",400,200);
      //     pdf.myText("Eng. Leonardo Gomes Longo",500,5000);

            pdf.save('info.pdf');
        }
        const state = reactive({
          isLoading : false
        })


      return {
        downloadPDF,
        createPdf,
        state
      }
    },
    data(){
        return{
        }
    },methods:{

    }
}
</script>