export default httpClient => ({
    getAll: async () => {

      const response = await httpClient.get('/relatorio-plantio')
      
      return { data: response.data }
  
    },


    save: async ({ engenheiro_id, tipo_lavoura, ano_safra, produtor_id, espacamento, formula_adubacao_plantio, data_adubacao_plantio, qtd_adubacao_plantio,
      formula_adubacao_cobertura_1, data_adubacao_cobertura_1, qtd_adubacao_cobertura_1,formula_adubacao_cobertura_2, data_adubacao_cobertura_2, qtd_adubacao_cobertura_2, observacao } ) => {
      const response = await httpClient.post('/relatorio-plantio/adicionar', {
        engenheiro_id,
        tipo_lavoura,
        ano_safra,
        produtor_id,
        espacamento,
        formula_adubacao_plantio,
        data_adubacao_plantio,
        qtd_adubacao_plantio,
        formula_adubacao_cobertura_1,
        data_adubacao_cobertura_1,
        qtd_adubacao_cobertura_1,
        formula_adubacao_cobertura_2,
        data_adubacao_cobertura_2,
        qtd_adubacao_cobertura_2,
        observacao
      })
      let errors = null
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_produto: async ({ relatorio_plantio_id, produto,doses}) => {
      const response = await httpClient.post('/relatorio-plantio/adicionar-produto',{
        relatorio_plantio_id,
        produto,
        doses

      })
      let errors = null
  
      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_hibrido: async ({ relatorio_plantio_id, nome_hibrido, qtd_stes, sistema, area_plantio,peneira, veloc_plantio, data_plantio,
      plantadeira, disco_usado, hibrido}) => {
      const response = await httpClient.post('/relatorio-plantio/adicionar-hibrido',{
        relatorio_plantio_id,
        nome_hibrido,
        qtd_stes,
        sistema,
        area_plantio,
        peneira,
        veloc_plantio,
        data_plantio,
        plantadeira,
        disco_usado,
        hibrido
      })
      let errors = null
  
      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },


    getByID: async ({ id }) => {

      const response = await httpClient.get('/relatorio-plantio/'+id)
      
      const produtos = await httpClient.get('/relatorio-plantio/produtos/'+id)

      const hibridos = await httpClient.get('/relatorio-plantio/hibridos/'+id)

      response.data.produtos = produtos
      response.data.hibridos = hibridos

      return { data: response.data }

    },
    delete: async ({ id }) => {
      const response = await httpClient.post('/relatorio-plantio/deletar',{
        id
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },









    save_doencas: async ({ relatorio_id, nome_doenca, indice_infestacao, local}) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-doenca',{
        relatorio_id,
        nome_doenca,
        indice_infestacao,
        local
      })
      let errors = null
  
      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_inseticidas: async ({ relatorio_id, quimico_id, quantidade, medida, espaco}) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-inseticida',{
        relatorio_id,
        quimico_id,
        quantidade,
        medida,
        espaco
      })
      let errors = null
  
      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },

    save_fungicidas: async ({ relatorio_id, quimico_id, quantidade, medida, espaco}) => {
      const response = await httpClient.post('/relatorio-campo/adicionar-fungicida',{
        relatorio_id,
        quimico_id,
        quantidade,
        medida,
        espaco
      })
      let errors = null
  
      if(!response.data){
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    },
 

    update: async ({ id, nome, ingrediente_ativo_1, ingrediente_ativo_2, ingrediente_ativo_3 }) => {
      const response = await httpClient.post('/relatorio-campo/alterar/'+id,{
        nome : nome,
        ingrediente_ativo_1,
        ingrediente_ativo_2,
        ingrediente_ativo_3
      })
      let errors = null
  
      if(!response.data){
        console.log('erroo')
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
      }
      return {
        data: response.data,
        errors
      }
    }
})